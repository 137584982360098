import React from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import { Link } from 'react-router-dom'
import Bowser from 'bowser'
import {
  BanKharColoredImage,
  I4CColoredImage,
  FuriaColoredImage,
  OphosColoredImage,
  OctorionColoredImage,
  DigmenthaColoredImage,
  BanKharWorldImage,
  I4CWorldImage,
  FuriaWorldImage,
  OphosWorldImage,
  OctorionWorldImage,
  DigmenthaWorldImage,
  TinyArrowIcon,
  OctorionHeadImage,
  I4CHeadImage,
  DigmenthaHeadImage,
  OphosHeadImage,
  FuriaHeadImage,
  BanKharHeadImage,
} from '../assets'

export const TeamSlider = props => {
  const { slides, page } = props
  const parser = Bowser.getParser(window.navigator.userAgent)
  const isDesktop = parser.is('desktop')

  const params = {
    slidesPerView: 6,
    spaceBetween: 10,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      clickable: true,
    },
    breakpoints: {
      1280: {
        slidesPerView: 5,
        spaceBetween: 10,
        centeredSlides: true,
      },
      960: {
        slidesPerView: 4,
        spaceBetween: 10,
        centeredSlides: true,
      },
      820: {
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: true,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 10,
        centeredSlides: true,
      },
    },
  }

  return (
    <Swiper {...params}>
      {slides.map(item => (
        <LinkStyles to={item.path} key={item.path}>
          {isDesktop && (
            <HoveredContent page={page}>
              <TextContent>
                <h2>{item.title}</h2>
                <p>{item.content}</p>
                <span>
                  {item.link}
                  <TinyArrow />
                </span>
              </TextContent>
              <SvgMask>
                <OctorionHead display-if={item.hero === 'Octorion'} />
                <I4CHead display-if={item.hero === 'I4C'} />
                <DigmenthaHead display-if={item.hero === 'Digmentha'} />
                <OphosHead display-if={item.hero === 'Ophos'} />
                <FuriaHead display-if={item.hero === 'Furia'} />
                <BanKharHead display-if={item.hero === 'BanKhar'} />
              </SvgMask>
            </HoveredContent>
          )}
          <TeamSliderItem page={page}>
            <h2>{item.title}</h2>
            <BanKharColored display-if={item.hero === 'BanKhar'} />
            <BanKharWorld
              display-if={item.hero === 'BanKhar' && page === '/work'}
            />
            <I4CColored display-if={item.hero === 'I4C'} />
            <I4CWorld display-if={item.hero === 'I4C' && page === '/work'} />
            <FuriaColored display-if={item.hero === 'Furia'} />
            <FuriaWorld
              display-if={item.hero === 'Furia' && page === '/work'}
            />
            <OphosColored display-if={item.hero === 'Ophos'} />
            <OphosWorld
              display-if={item.hero === 'Ophos' && page === '/work'}
            />
            <OctorionColored display-if={item.hero === 'Octorion'} />
            <OctorionWorld
              display-if={item.hero === 'Octorion' && page === '/work'}
            />
            <DigmenthaColored display-if={item.hero === 'Digmentha'} />
            <DigmenthaWorld
              display-if={item.hero === 'Digmentha' && page === '/work'}
            />
          </TeamSliderItem>
        </LinkStyles>
      ))}
    </Swiper>
  )
}

const LinkStyles = styled(Link)(props => ({
  position: 'relative',
  ':hover div': {
    visibility: 'visible',
    opacity: 1,
  },
}))

const TeamSliderItem = styled.div(props => ({
  width: '100%',
  height: '410px',
  position: 'relative',
  backgroundColor: props.page === '/work' ? '' : props.theme.colorMineShaft,
  border: props.page === '/work' ? '2px solid #D9DADC' : '2px solid #1A1C1D',
  overflow: 'hidden',
  cursor: 'pointer',
  boxSizing: 'border-box',
  h2: {
    padding: '2rem 1rem 0',
    color:
      props.page === '/work'
        ? props.theme.colorMineShaft
        : props.theme.colorWhite,
  },
  '@media (max-height: 700px)': {
    height: '230px',
    h2: {
      padding: '1rem 1rem 0',
      fontSize: '1.25rem',
    },
  },
}))

const HoveredContent = styled.div(props => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: '0',
  bottom: '0',
  width: '100%',
  height: '410px',
  backgroundColor: '#E9E9ED',
  visibility: 'hidden',
  transition: 'visibility 0.3s, opacity 0.2s ease-in-out',
  opacity: 0,
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  boxShadow:
    props.page === '/work'
      ? 'inset 0px 0px 0px 2px #D9DADC'
      : 'inset 0px 0px 0px 2px #1A1C1D',
}))

const TextContent = styled.div(props => ({
  padding: '2rem 1rem 0',
  'h2, p': {
    color: props.theme.colorMineShaft,
  },
  p: {
    fontSize: '.9rem',
    margin: 0,
    paddingTop: '1rem',
    paddingBottom: '.5rem',
  },
  span: {
    position: 'relative',
    color: props.theme.colorFireEngineRed,
    fontSize: '.9rem',
    fontWeight: '600',
  },
}))

const TinyArrow = styled(TinyArrowIcon)(props => ({
  position: 'absolute',
  width: '60%',
  top: '4px',
  right: '-60px',
}))

const SvgMask = styled.div(props => ({
  position: 'absolute',
  bottom: '2.5px',
  width: '100%',
  height: '140px',
  overflow: 'hidden',
  padding: 0,
  svg: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '-40px',
  },
}))

const OctorionHead = styled(OctorionHeadImage)(props => ({
  height: '90%',
}))

const I4CHead = styled(I4CHeadImage)(props => ({
  height: '100%',
}))

const DigmenthaHead = styled(DigmenthaHeadImage)(props => ({
  height: '100%',
}))

const OphosHead = styled(OphosHeadImage)(props => ({
  height: '100%',
}))

const FuriaHead = styled(FuriaHeadImage)(props => ({
  height: '90%',
}))

const BanKharHead = styled(BanKharHeadImage)(props => ({
  height: '100%',
}))

const BanKharColored = styled(BanKharColoredImage)(props => ({
  position: 'absolute',
  bottom: '-170px',
  left: '-130px',
  '@media (max-height: 700px)': {
    width: '150px',
    bottom: '-225px',
    left: '-70px',
  },
}))

const I4CColored = styled(I4CColoredImage)(props => ({
  position: 'absolute',
  bottom: '-255px',
  left: '-80px',
  '@media (max-height: 700px)': {
    width: '60%',
    bottom: '-265px',
    left: '-39px',
  },
}))

const FuriaColored = styled(FuriaColoredImage)(props => ({
  position: 'absolute',
  bottom: '-35px',
  left: '-85px',
  '@media (max-height: 700px)': {
    width: '60%',
    bottom: '-70px',
    left: '-53px',
  },
}))

const OphosColored = styled(OphosColoredImage)(props => ({
  position: 'absolute',
  bottom: '-105px',
  left: '-120px',
  '@media (max-height: 700px)': {
    width: '85%',
    bottom: '-135px',
    left: '-70px',
  },
}))

const OctorionColored = styled(OctorionColoredImage)(props => ({
  position: 'absolute',
  bottom: '-125px',
  left: '-225px',
  '@media (max-height: 700px)': {
    width: '180%',
    bottom: '-180px',
    left: '-155px',
  },
}))

const DigmenthaColored = styled(DigmenthaColoredImage)(props => ({
  position: 'absolute',
  bottom: '-195px',
  left: '-70px',
  '@media (max-height: 700px)': {
    width: '65%',
    bottom: '-205px',
    left: '-55px',
  },
}))

const BanKharWorld = styled(BanKharWorldImage)(props => ({
  position: 'absolute',
  top: '0',
  zIndex: '-1',
}))

const I4CWorld = styled(I4CWorldImage)(props => ({
  position: 'absolute',
  top: '0',
  zIndex: '-1',
}))

const FuriaWorld = styled(FuriaWorldImage)(props => ({
  position: 'absolute',
  top: '0',
  zIndex: '-1',
}))

const OphosWorld = styled(OphosWorldImage)(props => ({
  position: 'absolute',
  top: '0',
  zIndex: '-1',
}))

const OctorionWorld = styled(OctorionWorldImage)(props => ({
  position: 'absolute',
  top: '0',
  zIndex: '-1',
}))

const DigmenthaWorld = styled(DigmenthaWorldImage)(props => ({
  position: 'absolute',
  top: '13px',
  zIndex: '-1',
}))
