import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { SEO } from '../../components'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Page404Heading,
  I4C404Image,
} from '../../assets'

export const NotFound = props => {
  const { history } = props
  return (
    <>
      <SEO page="error" />
      <Container>
        <LeftSideWrapper>
          <ErrorImageLarge />
        </LeftSideWrapper>
        <RightSideWrapper>
          <Heading>
            The page you are looking for is broken or never existed!
          </Heading>
          <ErrorImageSmall />
          <ButtonsWrapper>
            <Button>
              <BackButtonLink onClick={() => history.goBack()}>
                Back
              </BackButtonLink>
            </Button>
            <Button>
              <Link to="/home">
                <HomeButtonLink>Home</HomeButtonLink>
              </Link>
            </Button>
          </ButtonsWrapper>
        </RightSideWrapper>
      </Container>
    </>
  )
}

const Container = styled.div(props => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  alignContent: 'center',
  position: 'relative',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: props.theme.colorSolitude,
  '@media (max-width: 1280px)': {
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '1rem',
  },
}))

const LeftSideWrapper = styled.div(props => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  maxWidth: '500px',
  width: '100%',
  position: 'relative',
}))

const RightSideWrapper = styled.div(props => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
}))

const ErrorImageLarge = styled.div(props => ({
  position: 'absolute',
  height: '400px',
  top: '-150px',
  left: '-50px',
  width: '100%',
  backgroundImage: `url(${I4C404Image})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  '@media (max-width: 1280px)': {
    display: 'none',
  },
}))

const ErrorImageSmall = styled.div(props => ({
  display: 'none',
  '@media (max-width: 1280px)': {
    display: 'block',
    position: 'relative',
    height: '300px',
    width: '100%',
    backgroundImage: `url(${I4C404Image})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    margin: '4rem 0',
  },
  '@media (max-width: 600px)': {
    height: '200px',
    margin: '3rem 0',
  },
}))

const Heading = styled.h2(props => ({
  position: 'relative',
  maxWidth: '400px',
  width: '100%',
  zIndex: '1',
  '@media (max-width: 600px)': {
    position: 'unset',
    textAlign: 'center',
    fontSize: '1.5rem',
    marginTop: '4rem',
    marginBottom: 0,
  },
  '@media (min-width: 1280px)': {
    marginBottom: '7rem',
  },
  '&::before': {
    display: 'block',
    content: "' '",
    position: 'absolute',
    height: '350px',
    width: '500px',
    top: '-250px',
    left: '-60px',
    backgroundImage: `url(${Page404Heading})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    zIndex: '-1',
    '@media (max-width: 600px)': {
      width: '30%',
      height: '20%',
      top: '30px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundPosition: 'center bottom',
    },
  },
}))

const ButtonsWrapper = styled.div(props => ({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-start',
  '@media (max-width: 600px)': {
    justifyContent: 'space-evenly',
  },
}))

const Button = styled.div(props => ({
  padding: '0 4rem',
  a: {
    textDecoration: 'none',
  },
  '&:first-child': {
    '@media (max-width: 600px)': {
      padding: '0 .5rem 0 3rem',
    },
  },
  '&:last-child': {
    marginLeft: '2rem',
    '@media (max-width: 600px)': {
      marginLeft: '0',
      padding: '0 2.2rem',
    },
  },
}))

const BackButtonLink = styled.h2(props => ({
  display: 'block',
  width: '65px',
  position: 'relative',
  zIndex: 1,
  color: props.theme.colorBlack,
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    fontSize: '2rem',
  },
  '&::after': {
    display: 'block',
    content: "' '",
    position: 'absolute',
    height: '35px',
    width: '179px',
    top: '3px',
    left: '-60px',
    backgroundImage: `url(${ArrowLeftIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    zIndex: '-1',
    '@media (max-width: 600px)': {
      height: '25px',
      top: '7px',
      left: '-55px',
    },
  },
}))

const HomeButtonLink = styled.h2(props => ({
  display: 'block',
  width: '65px',
  position: 'relative',
  zIndex: 1,
  color: props.theme.colorBlack,
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    fontSize: '2rem',
  },
  '&::after': {
    display: 'block',
    content: "' '",
    position: 'absolute',
    height: '35px',
    width: '179px',
    top: '2px',
    left: '-60px',
    backgroundImage: `url(${ArrowRightIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    zIndex: '-1',
    '@media (max-width: 600px)': {
      height: '25px',
      top: '7px',
      left: '-26px',
    },
  },
}))
