import React from 'react'
import {
  ClientInfo,
  ComputerImage,
  ContentWrapper,
  Header,
  Image,
  Link,
  Section,
  SubHeader,
  Text
} from '../CommonComponents'
import productImage from '../../../resources/projects/Design/Muinas/muinas.png'
import logo from '../../../resources/projects/Design/Muinas/muinsuskaitseamet_logo.svg'
import styled from 'styled-components'
import { nationalHeritageBoardContent } from '../../../resources/data'

const Logo = styled.img(props => ({
  marginBottom: '1.5rem',
  position: 'relative',
  zIndex: '10'
}))

const Title = styled.h1(props => ({
  fontFamily: 'open_sanslight',
  fontSize: '2rem',
  position: 'relative',
  zIndex: '10'
}))

export const NationalHeritageBoard = () => {
  return (
    <div>
      <Header>{nationalHeritageBoardContent.header}</Header>
      <Section backgroundColor={'#ecf1e8'}>
        <ClientInfo>
          <Logo src={logo} alt={'logo'}/>
          <Title>Kultuurimälestiste</Title>
          <Title>riiklik register</Title>
        </ClientInfo>
        <ComputerImage src={productImage} alt={'Product image'}/>
      </Section>
      <ContentWrapper>
        {nationalHeritageBoardContent.sections.map(section =>
          <React.Fragment key={section.header}>
            {section.hasOwnProperty('header') && <SubHeader>{section.header}</SubHeader>}
            {section.hasOwnProperty('text') && <Text>{section.text}</Text>}
            {section.hasOwnProperty('image') && <Image src={section.image} alt={section.image} isShadow/>}
            {section.hasOwnProperty('link') && <Link href={section.link.href}>{section.link.text}</Link>}
          </React.Fragment>
        )}
      </ContentWrapper>
    </div>
  )
}
