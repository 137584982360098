export const theme = {
  colorWhite: '#FFFFFF',
  colorBlack: '#000000',
  colorFireEngineRed: '#BB2025',
  colorBlackPearl: '#1A1C1D',
  colorMineShaft: '#373D40',
  colorNightRider: '#333333',
  colorQuartz: '#E0E0E2',
  colorNobel: '#979797',
  colorAthensGray: '#f7f7fa',
  colorSolitude: '#EAEBF0',
  colorLavender: '#E9E9ED',
  colorNero: '#1D1C1C',
  colorHawkesBlue: '#D9DADC',
  colorGhostWhite: '#F4F4F8',
  colorLinkWater: '#D4D5DA',
  colorGainsboro: '#D8D8D8',
  colorFindMotorsBlue: '#00B9B7',
  gradientDarkRed:
    'linear-gradient(180deg, #940000 3%, #D12729 33%, #DF6667 82%)',
  gradientWhiteSmoke: 'radial-gradient(49% 177%, #F8F8F8 77%, #D4D1D7 100%)',
  gradientWhiteLilac: 'radial-gradient(50% 175%, #FFFFFF 77%, #EAE7EB 100%)',
}
