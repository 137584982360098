import React from 'react'
import styled from 'styled-components'
import { AssembleImage } from '../../assets'
import { Slide } from '../../components'
import InfoText from '../../components/InfoText'

const Assemble = props => {
  const { title, description } = props

  return (
    <Slide>
      <Container>
        <InfoText
          textColor={'white'}
          title={title}
          firstDescription={description}
        />
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  position: 'relative',
  backgroundColor: props.theme.colorMineShaft,
  backgroundImage: `url(${AssembleImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center bottom',
  backgroundSize: '75%',
  zIndex: 0,
  overflow: 'hidden',
  paddingTop: '3rem',
  height: 'calc(100% - 3rem)',
  '@media (min-width: 960px)': {
    paddingTop: 0,
    height: '100%',
    backgroundSize: '50%'
  }
}))

export default Assemble
