import React from 'react'
import { ContentWrapper, HeaderInWrapper, Image, Link, SubHeader, Text } from '../CommonComponents'
import { microsoftDynamicsContent } from '../../../resources/data'

export const MicrosoftDynamics = () => {
  return (
    <ContentWrapper>
      <HeaderInWrapper>{microsoftDynamicsContent.header}</HeaderInWrapper>
      {microsoftDynamicsContent.sections.map(section =>
        <React.Fragment key={section.header}>
          {section.hasOwnProperty('header') && <SubHeader>{section.header}</SubHeader>}
          <Text>{section.text}</Text>
          {section.hasOwnProperty('image') && <Image src={section.image} alt={section.image} isShadow/>}
          {section.hasOwnProperty('link') && <Link href={section.link.href}>{section.link.text}</Link>}
        </React.Fragment>
      )}
    </ContentWrapper>
  )
}
