import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ArrowRightIcon } from '../assets'

export const WorkButton = props => {
  return (
    <Link to="/work">
      <WorkButtonLink>
        <span>Work</span>
      </WorkButtonLink>
    </Link>
  )
}

const WorkButtonLink = styled.div(props => ({
  width: '185px',
  height: '39px',
  display: 'flex',
  justifyContent: 'center',
  '@media (max-width: 600px)': {
    width: '140px',
    height: '39px',
  },
  span: {
    display: 'block',
    width: '65px',
    position: 'relative',
    fontFamily: 'bebas_neuebold',
    fontSize: '2.25rem',
    color: props.theme.colorWhite,
    zIndex: 10,
    '&::after': {
      display: 'block',
      content: "' '",
      position: 'absolute',
      height: '35px',
      width: '179px',
      top: '2px',
      left: '-60px',
      backgroundImage: `url(${ArrowRightIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      zIndex: '-1',
      '@media (max-width: 600px)': {
        height: '25px',
        top: '7px',
        left: '-26px',
      },
    },
  },
}))
