import React, {useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { RivieraImage, RivieraBackground, RivieraMusic, VolumeOn, VolumeOff } from '../../assets';

export const Riviera = () => {
    const StyledBackground = styled.div(props => ({
        backgroundImage: `url(${RivieraBackground})`,
    }))
    let StyledRivieraImage = styled.div(props => ({
        height: '100vh',
        backgroundPosition: 'center',
        backgroundImage: `url(${RivieraImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
    }))
    const StyledButton = styled.button(props => ({
        border: 'none',
        background: 'black',
        borderRadius: '100%',
        padding: '0.5rem',
        position: 'absolute',
        bottom: '2rem',
        right: '2rem',
    }))

    const afterFirstRender = useRef(false);
    const [sound, setSound] = useState(false);

    const handleToggle = () => {
        setSound((current) => !current);
    };

    useEffect(() => {
        const audio = document.getElementById('summer-vibes');
        if (!afterFirstRender.current) {
            afterFirstRender.current = true;
            return;
        }
        if (!sound) {
            audio.pause();
            console.log("Pause");
        }
        if (sound) {
            audio.play();
            console.log("Play");
        }
    }, [sound]);

  return (
    <StyledBackground>
        <StyledRivieraImage />
        <audio id="summer-vibes" autoPlay={true} loop={true}>
            <source src={RivieraMusic} type="audio/mpeg" />
        </audio>
        <StyledButton id="toggle-sound" onClick={handleToggle}>
            {sound ? (<VolumeOn style={{fill: 'white',}} />
            ) : (
                <VolumeOff style={{fill: 'white',}} />
            )}
        </StyledButton>
    </StyledBackground>
  );
};
