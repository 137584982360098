import React from 'react'
import styled from 'styled-components'
import {
  BanKharWorkIntroBg,
  BanKharPageImage,
  BanKharSoftwareImage,
  BanKharDataImage,
  BanKharAnalysisImage,
  BanKharReportingImage,
  InbankIcon,
  LuminorIcon,
  BanKharMapImage,
} from '../../assets'
import { Intro, Section, ContactMenu } from '../../layout/WorkDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const WorkBanking = props => {
  const { data } = useAppContext()

  const assets = [
    {
      image: BanKharReportingImage,
      secondIconsLine: [{ icon: LuminorIcon }, { icon: InbankIcon }],
    },
    {
      image: BanKharSoftwareImage,
      polygon: 'map',
      secondIconsLine: [{ icon: LuminorIcon }, { icon: InbankIcon }],
    },
    {
      image: BanKharDataImage,
      secondIconsLine: [{ icon: LuminorIcon }, { icon: InbankIcon }],
    },
    {
      image: BanKharAnalysisImage,
      secondIconsLine: [{ icon: LuminorIcon }, { icon: InbankIcon }],
    },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '136px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorMineShaft,
    boxShadow: '0 -3px 18px 0 rgba(0,0,0,0.31)',
    zIndex: 2,
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="workBanking" />
      <Intro
        background={BanKharWorkIntroBg}
        image={BanKharPageImage}
        page={props.location.pathname}
        title={data.workPageBankingContent.title}
        titleMobile={data.workPageBankingContent.titleMobile}
        points={data.workPageBankingContent.points}
      >
        <BottomRectangle />
      </Intro>
      <Section
        content={
          getMergedContent(data.workPageBankingContent.sections, assets)[0]
        }
        page={props.location.pathname}
        map={BanKharMapImage}
      />
      <Section
        content={
          getMergedContent(data.workPageBankingContent.sections, assets)[1]
        }
        page={props.location.pathname}
        map={BanKharMapImage}
      />
      <Section
        content={
          getMergedContent(data.workPageBankingContent.sections, assets)[2]
        }
        page={props.location.pathname}
        map={BanKharMapImage}
      />
      <Section
        content={
          getMergedContent(data.workPageBankingContent.sections, assets)[3]
        }
        page={props.location.pathname}
        map={BanKharMapImage}
      />
      <ContactMenu
        title={data.workPageBankingContent.footer.title}
        description={data.workPageBankingContent.footer.description}
        contacts={data.workPageBankingContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
