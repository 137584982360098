import React from 'react'
import styled from 'styled-components'


const InfoText = props => {
  const { textColor, title, firstDescription, secondDescription } = props

  return (
    <TextContainer textColor={textColor}>
      <h1>{title}</h1>
      <p>{firstDescription}</p>
      {secondDescription && <p>{secondDescription}</p>}
    </TextContainer>
  )
}

const TextContainer = styled.div(props => ({
  width: '85%',
  margin: 'auto',
  position: 'relative',
  zIndex: 10,
  color: props.textColor === 'white' ? props.theme.colorWhite : props.theme.colorBlackPearl,
  h1: {
    padding: '1rem 0 0',
    '@media (max-width: 1280px)': {
      fontSize: '3rem'
    },
    '@media (max-width: 600px)': {
      lineHeight: 1,
      fontSize: '2.8rem'
    }
  },
  p: {
    maxWidth: '80%'
  },
  '@media (min-width: 600px)': {
    paddingTop: '4rem',
    width: '75%'
  }
}))

export default InfoText
