import React from 'react'
import styled from 'styled-components'
import { FunAtatWalker, FunGroundBackground, FunSkyBackground, FunDigimentha, FunI4C, FunOphos } from '../../assets'
import { Slide } from '../../components'
import InfoText from '../../components/InfoText'

const SecondScreen = props => {
  const { title, firstDescription, secondDescription } = props

  return (
    <Slide>
      <Container>
        <InfoText
          textColor={'black'}
          title={title}
          firstDescription={firstDescription}
          secondDescription={secondDescription}
        />
        <ImageWrapper>
          <GroundBackground/>
          <AtatWalker/>
          <Ophos/>
          <I4C/>
          <Digimentha/>
        </ImageWrapper>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  position: 'relative',
  backgroundColor: props.theme.colorMineShaft,
  backgroundImage: `url(${FunSkyBackground})`,
  backgroundSize: 'cover',
  height: 'calc(100% - 3rem)',
  paddingTop: '3rem',
  '@media (min-width: 960px)': {
    height: '100%',
    paddingTop: 0
  },
  display: 'flex',
  flexDirection: 'column'
}))

const ImageWrapper = styled.div(props => ({
  position: 'relative',
  height: 'inherit'
}))

const GroundBackground = styled(FunGroundBackground)(props => ({
  position: 'absolute',
  bottom: 0,
  zIndex: 1,
  '@media (max-width: 1280px)': {
  },
  '@media (max-width: 600px)': {
  }
}))

const AtatWalker = styled(FunAtatWalker)(props => ({
  position: 'absolute',
  bottom: '11rem',
  right: '30rem',
  zIndex: 1,
  '@media (max-width: 1475px)': {
    right: '10rem',
    bottom: '5rem'
  },
  '@media (max-width: 800px)': {
    display: 'none'
  }
}))

const Digimentha = styled(FunDigimentha)(props => ({
  position: 'absolute',
  left: '30rem',
  bottom: '3rem',
  zIndex: 1,
  '@media (max-width: 1475px)': {
    bottom: '1rem',
    left: '16rem',
    height: '200px'
  },
  '@media (max-width: 800px)': {
    bottom: '1rem',
    left: '0rem',
  }
}))

const I4C = styled(FunI4C)(props => ({
  position: 'absolute',
  right: '40rem',
  bottom: '10rem',
  zIndex: 1,
  '@media (max-width: 1475px)': {
    bottom: '4rem',
    right: '20rem'
  },
  '@media (max-width: 800px)': {
    display: 'none'
  },
}))

const Ophos = styled(FunOphos)(props => ({
  position: 'absolute',
  bottom: '4rem',
  left: '22rem',
  zIndex: 1,
  '@media (max-width: 1475px)': {
    bottom: '2rem',
    left: '10rem',
    height: '150px'
  },
  '@media (max-width: 800px)': {
    bottom: '2rem',
    left: '7rem',
  }
}))


export default SecondScreen
