import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../context'
import { CloseIcon } from '../assets'
import { withRouter } from 'react-router-dom'
import ProjectMenu from './ProjectMenu'

const ProjectsOverlay = props => {
  const { state, actions } = useAppContext()

  const [activeTab, setActiveTab] = useState(state.overlayContent.tabs[0])
  const ProjectContent = activeTab.ProjectContent

  const isWide = window.matchMedia('(min-width: 960px)').matches

  const ref = React.createRef()
  const navigateTo = (tab) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    ref.current.scrollTop = '1rem'
  })

  return (
    <Overlay>
      <ProjectMenu
        tabs={state.overlayContent.tabs}
        title={state.overlayContent.title}
        activeTab={activeTab}
        navigateTo={tab => navigateTo(tab)}
        isMobile={!isWide}
        closeOverlay={() => actions.setOverlayOpen(!state.isOverlayOpen)}
      />
      <TabPanel ref={ref}>
        {isWide && <CloseIconBtn onClick={() => actions.setOverlayOpen(!state.isOverlayOpen)}/>}
        <ProjectContent/>
      </TabPanel>
    </Overlay>
  )
}

const Overlay = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  width: '100vw',
  height: '100vh',
  backgroundColor: props.theme.colorLavender,
  zIndex: 100,
  overflow: 'hidden',
  boxShadow: '0px 0px 9px 3px rgba(0,0,0,0.11)',
  '@media(min-width: 960px)': {
    padding: '1rem',
    flexDirection: 'row'
  }
}))

const TabPanel = styled.div(props => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  position: 'relative',
  backgroundColor: props.theme.colorAthensGray,
  overflow: 'auto',
  maxHeight: '100%',
  '@media(min-width: 960px)': {
    borderRadius: '5px',
    flex: '80% 0 0'
  },

  '::-webkit-scrollbar': {
    width: '10px'
  },
  '::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '5px'
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555'
  }
}))

const CloseIconBtn = styled(CloseIcon)(props => ({
  display: 'block',
  position: 'absolute',
  right: '30px',
  cursor: 'pointer',
  width: '2.5rem',
  zIndex: 100,
  '@media(min-width: 960px)': {
    width: '4rem',
    top: '4rem',
    right: '3rem',
    position: 'fixed'
  }
}))

export default withRouter(ProjectsOverlay)
