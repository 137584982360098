import React from 'react'
import styled from 'styled-components'

export const ParagraphList = props => {
  const { content } = props

  return (
    <ParagraphListWrapper>
      {content.paragraphList.map(item => (
        <ParagraphListItem key={item.text}>
          <RedRect />
          <p>{item.text}</p>
        </ParagraphListItem>
      ))}
    </ParagraphListWrapper>
  )
}

const ParagraphListWrapper = styled.div(props => ({
  marginBottom: '2rem',
}))

const ParagraphListItem = styled.div(props => ({
  position: 'relative',
  margin: 0,
  p: {
    position: 'relative',
    margin: '0 0 1rem 1rem !important',
    paddingLeft: '.5rem',
  },
}))

const RedRect = styled.div(props => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 0,
  width: '7px',
  height: '7px',
  backgroundColor: props.theme.colorFireEngineRed,
}))
