import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ArrowLeftIcon } from '../assets'

export const TeamButton = props => {
  return (
    <Link to="/team">
      <TeamButtonLink>
        <span>Team</span>
      </TeamButtonLink>
    </Link>
  )
}

const TeamButtonLink = styled.div(props => ({
  width: '185px',
  height: '39px',
  display: 'flex',
  justifyContent: 'center',
  '@media (max-width: 600px)': {
    width: '140px',
    height: '39px',
  },
  span: {
    display: 'block',
    width: '65px',
    position: 'relative',
    fontFamily: 'bebas_neuebold',
    fontSize: '2.25rem',
    color: props.theme.colorWhite,
    zIndex: 10,
    '&::after': {
      display: 'block',
      content: "' '",
      position: 'absolute',
      height: '35px',
      width: '179px',
      top: '3px',
      left: '-60px',
      backgroundImage: `url(${ArrowLeftIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      zIndex: '-1',
      '@media (max-width: 600px)': {
        height: '25px',
        top: '7px',
        left: '-40px',
      },
    },
  },
}))
