import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    background: '#EAEBF0',
    opacity: 1,
    visibility: 'visible',
    transition: 'opacity 1s ease-in',
  },
  fadeOut: {
    opacity: 0,
  },
  hidden: {
    visibility: 'hidden',
    display: 'none',
  },
  progress: {
    margin: theme.spacing(2),
    color: '#BB2025',
  },
}))

export const Loader = props => {
  const classes = useStyles()
  const [visible, setVisible] = React.useState(true)
  const [hidden, setHidden] = React.useState(false)

  React.useEffect(() => {
    let timerId = setTimeout(() => {
      setVisible(visible => !visible)
    }, 1000)
    return () => {
      setVisible(true)
      setHidden(false)
      clearTimeout(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname])

  return (
    <div
      onTransitionEnd={() => setHidden(true)}
      className={`
        ${classes.container} 
        ${!visible ? classes.fadeOut : ''}
        ${hidden ? classes.hidden : ''}`}
    >
      <CircularProgress className={classes.progress} />
    </div>
  )
}
