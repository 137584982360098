import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ArrowRightIcon, WorkSquid } from '../assets'
import { homePageContent } from '../resources/data'

export const WorkPanel = props => {
  const isWide = window.matchMedia('(min-width: 960px)').matches

  return (
    <Wrapper to="/work">
      <TextWrapper>
        {isWide && <Info>{homePageContent.menu.workText}</Info>}
          <ArrowTextWrapper>
            <Title>Work</Title>
            <Arrow/>
          </ArrowTextWrapper>
        {isWide && <Image/>}
      </TextWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: center;
    background-color: ${props => props.theme.colorMineShaft};`

const TextWrapper = styled.div`
    color: ${props => props.theme.colorWhite};
    width: 75%;
    height: 75%;
    margin: auto;
    position: relative;
    @media (max-width: 960px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }`

const Info = styled.h2`
    position: absolute;
    margin-top: 10rem;
    font-size: 2rem;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    @media (min-width: 960px) {
        ${Wrapper}:hover & {
            opacity: 1;
        }
    }
    @media (min-width: 1170px) {
        margin-top: 12rem;
     }`

const ArrowTextWrapper = styled.div`
    position: relative;
      @media (min-width: 960px) {
        margin-top: 20rem;
      }
    }`

const Title = styled.h1`
    font-family: bebas_neuebold;
    font-size: 3rem;
    margin-left: calc(50% - 3.5rem);
    position: relative;
    z-index: 10;
    transition: margin 1s ease-in-out;
    @media (min-width: 960px) {
        font-size: 3.5rem;
    
        ${Wrapper}:hover & {
            margin-left: 0;
        }
     }
}`

const Arrow = styled.img.attrs({
  src: ArrowRightIcon
})`
    top: 14px;
    width: 140px;
    position: absolute;
    margin-left: calc( 50% - 80px );
    transition: margin 1s ease-in-out;
    @media (min-width: 960px) {
        width: 185px;
        
        ${Wrapper}:hover & {
            margin-left: calc(100% - 244px);
        }
    }`

const Image = styled.img.attrs({
  src: WorkSquid
})`
    margin-top: 10rem;
    display: block;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    display: none;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    @media (min-width: 960px) {
        display: block;
        ${Wrapper}:hover & {
            opacity: 1;
        }
    }`
