import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Loader, Navigation, ProjectsOverlay, ScrollDown } from './components';
import { DataContextProvider } from './context';
import { data } from './resources/data.js';
import { theme } from './theme';
import {
  Home,
  NotFound,
  Riviera,
  Team,
  TeamBanking,
  TeamDesign,
  TeamDevelopment,
  TeamDWH,
  TeamERP,
  TeamITOperations,
  Work,
  WorkBanking,
  WorkDesign,
  WorkDevelopment,
  WorkDWH,
  WorkERP,
  WorkITOperations,
} from './pages';
import { CSSTransition } from 'react-transition-group';
import ReactGA from 'react-ga';

function App() {
  const [activeIdx, setActiveIdx] = React.useState(0);
  const [slidesLength, setSlidesLength] = React.useState(0);
  const [isOverlayOpen, setOverlayOpen] = React.useState(false);
  const [overlayContent, setOverlayContent] = React.useState({});

  const context = {
    data,
    state: { slidesLength, activeIdx, isOverlayOpen, overlayContent },
    actions: {
      setActiveIdx,
      setSlidesLength,
      setOverlayOpen,
      setOverlayContent,
    },
  };

  ReactGA.initialize('UA-208850482-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <ThemeProvider theme={theme}>
      <DataContextProvider value={context}>
        <Router>
          <Switch>
            <Route exact path='/' render={() => <Redirect to='/home' />} />
            <Route path='/home' render={(props) => <Home {...props} />} />
            <Route path='/summerdays/Riviera' render={(props) => <Riviera {...props} />} />
            <Route
              exact
              strict={false}
              path='/team'
              render={(props) => <Team {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/team/design'
              render={(props) => <TeamDesign {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/team/banking'
              render={(props) => <TeamBanking {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/team/it-operations'
              render={(props) => <TeamITOperations {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/team/data-warehouse'
              render={(props) => <TeamDWH {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/team/erp'
              render={(props) => <TeamERP {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/team/software-development'
              render={(props) => <TeamDevelopment {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/work'
              render={(props) => <Work {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/work/software-development'
              render={(props) => <WorkDevelopment {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/work/design'
              render={(props) => <WorkDesign {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/work/it-operations'
              render={(props) => <WorkITOperations {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/work/banking'
              render={(props) => <WorkBanking {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/work/data-warehouse'
              render={(props) => <WorkDWH {...props} />}
            />
            <Route
              exact
              strict={false}
              path='/work/erp'
              render={(props) => <WorkERP {...props} />}
            />
            {/*<Route*/}
            {/*    exact*/}
            {/*    strict={false}*/}
            {/*    path='/summerdays/Riviera'*/}
            {/*    render={Riviera}*/}
            {/*/>*/}
            <Route path='/404' render={NotFound} />
            <Route path='/*'>
              <Redirect to='/404' />
            </Route>

          </Switch>
          <Route
            display-if={activeIdx === 0}
            path='/*'
            component={ScrollDown}
          />
          <CSSTransition
            classNames={'fade'}
            in={isOverlayOpen}
            timeout={500}
            unmountOnExit
          >
            {(state) => <ProjectsOverlay className={`fade-${state}`} />}
          </CSSTransition>

          <Route path='/*' component={Navigation} />
          <Route path='/*' component={Loader} />
          {/*<Route*/}
          {/*    render={({ location }) =>*/}
          {/*        //This array includes pages on which user will*/}
          {/*        // not be redirected*/}
          {/*        ["/summerdays/Riviera"].includes(*/}
          {/*            location.pathname*/}
          {/*        ) ? null : (*/}
          {/*            <Route path='/*' component={Navigation} />*/}
          {/*        )*/}
          {/*    }*/}
          {/*/>*/}
        </Router>
      </DataContextProvider>
    </ThemeProvider>
  );
}

export default App;
