import React from 'react'
import styled from 'styled-components'
import { getTextColor, getMergedContent } from '../utils'

export const IconsList = props => {
  const { content, page, iconsSet, subHeading } = props

  return (
    <>
      {content[iconsSet] && (
        <>
          <IconsWrapper>
            <SubHeading page={page}>{content[subHeading]}</SubHeading>
            {content.languages && (
              <LargeIcons>
                {getMergedContent(content[iconsSet], content.languages).map(
                  item => (
                    <LargeIcon
                      key={item.name}
                      getTextColor={getTextColor}
                      page={page}
                    >
                      <img src={item.icon} alt={item.name} />
                      <h4>{item.name}</h4>
                    </LargeIcon>
                  ),
                )}
              </LargeIcons>
            )}
            {!content.languages && (
              <SmallIcons>
                {content[iconsSet].map(item => (
                  <SmallIcon
                    key={item.icon}
                    page={page}
                    type={content[subHeading]}
                  >
                    <img src={item.icon} alt={item.icon} />
                  </SmallIcon>
                ))}
              </SmallIcons>
            )}
          </IconsWrapper>
        </>
      )}
    </>
  )
}

const IconsWrapper = styled.div(props => ({
  marginBottom: '2rem',
  '@media (max-width: 1280px)': {
    marginBottom: '.5rem',
  },
}))

const SubHeading = styled.h3(props => ({
  color:
    props.page === '/work/design' || props.page === '/work/erp'
      ? props.theme.colorMineShift
      : props.theme.colorFireEngineRed,
  paddingBottom: '2rem',
  '@media (max-width: 1280px)': {
    padding: '.8rem 0',
    fontSize: '1.25rem',
  },
  '@media (max-width: 600px)': {
    padding: '0 0 .5rem',
    fontSize: '1rem',
  },
}))

const LargeIcons = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexFlow: 'row wrap',
  padding: '1rem 0',
  magrin: '0 auto',
  '@media (max-width: 600px)': {
    justifyContent: 'center',
  },
}))

const LargeIcon = styled.div(props => ({
  display: 'flex',
  width: '120px',
  flexDirection: 'column',
  justifyContent: 'center',
  '@media (max-width: 600px)': {
    width: '90px',
    paddingBottom: '1.2rem',
  },
  img: {
    height: '50px',
    paddingBottom: '1rem',
    '@media (max-width: 600px)': {
      height: '30px',
    },
  },
  h4: {
    display: 'inline-flex',
    width: '120px',
    justifyContent: 'center',
    color: props.getTextColor(props.page, props.theme),
    '@media (max-width: 600px)': {
      width: '90px',
      fontSize: '.8rem',
    },
  },
}))

const SmallIcons = styled.div(props => ({
  display: 'flex',
  justifyContent: 'stretch',
  flexFlow: 'row wrap',
  paddingBottom: '.9rem',
  magrin: '0 auto',
  '@media (max-width: 600px)': {
    paddingBottom: 0,
  },
}))

const SmallIcon = styled.div(props => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '.8rem 1.4rem',
  img: {
    width:
      props.type === 'Happy customers' || props.type === 'Happy partners'
        ? '100px'
        : props.type === 'Our competence'
        ? '90px'
        : '60px',
    height: '30px',
  },
  '@media (max-width: 600px)': {
    width: '45px',
    padding: '.3rem 0',
    img: {
      width: '30px',
      height: '30px',
    },
  },
}))
