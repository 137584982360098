import React from 'react'
import styled from 'styled-components'
import { getContainerBgColor, getTextColor } from '../../utils'
import { Slide } from '../../components'

const Intro = props => {
  const { title, background, image, page, children } = props

  const Container = styled.div(props => ({
    height: '100%',
    position: 'relative',
    backgroundImage: `url(${background})`,
    backgroundPosition:
      props.page === '/team/design'
        ? ''
        : props.page === '/team/erp'
        ? 'right 0px bottom -100px'
        : 'center bottom',
    backgroundSize: page === '/team/software-development' ? 'contain' : 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: props.getBgColor(props.page, props.theme),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }))

  const HeroDesign = styled(image)(props => ({
    position: 'relative',
    zIndex: 3,
    '@media (max-width: 960px)': {
      width: '50%',
      height: '50%',
    },
  }))

  const HeroBanking = styled(image)(props => ({
    position: 'absolute',
    bottom: '70px',
    zIndex: 3,
    '@media (max-width: 1280px)': {
      width: '50%',
      height: '50%',
      bottom: '50px',
    },
    '@media (max-width: 960px)': {
      width: '35%',
      height: '35%',
    },
  }))

  const HeroITOperations = styled(image)(props => ({
    position: 'absolute',
    bottom: '60px',
    zIndex: 3,
    '@media (max-width: 1280px)': {
      width: '60%',
      height: '60%',
    },
    '@media (max-width: 960px)': {
      width: '50%',
      height: '50%',
    },
  }))

  const HeroDWH = styled(image)(props => ({
    position: 'absolute',
    bottom: '20px',
    zIndex: 3,
    '@media (max-width: 1280px)': {
      width: '90%',
      height: '60%',
      bottom: 0,
    },
    '@media (max-width: 960px)': {
      width: '70%',
      height: '30%',
      bottom: '20px',
    },
  }))

  const HeroERP = styled(image)(props => ({
    position: 'absolute',
    bottom: '50px',
    zIndex: 3,
    '@media (max-width: 1280px)': {
      width: '90%',
      height: '60%',
      bottom: 0,
    },
    '@media (max-width: 960px)': {
      width: '70%',
      height: '30%',
      bottom: '30px',
    },
  }))

  const HeroDevelopment = styled(image)(props => ({
    position: 'absolute',
    bottom: '90px',
    zIndex: 3,
    '@media (max-width: 1280px)': {
      width: '90%',
      height: '60%',
      bottom: '80px',
    },
    '@media (max-width: 960px)': {
      width: '70%',
      height: '30%',
    },
    '@media (max-width: 600px)': {
      width: '70%',
      height: '30%',
      bottom: '60px',
    },
  }))

  return (
    <Slide>
      <Container
        background={background}
        page={page}
        getBgColor={getContainerBgColor}
      >
        <HeadingWrapper page={page} getTextColor={getTextColor}>
          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        </HeadingWrapper>
        <HeroDesign image={image} display-if={page === '/team/design'} />
        <HeroBanking image={image} display-if={page === '/team/banking'} />
        <HeroITOperations
          image={image}
          display-if={page === '/team/it-operations'}
        />
        <HeroDWH image={image} display-if={page === '/team/data-warehouse'} />
        <HeroERP image={image} display-if={page === '/team/erp'} />
        <HeroDevelopment
          image={image}
          display-if={page === '/team/software-development'}
        />
        {children}
      </Container>
    </Slide>
  )
}

const HeadingWrapper = styled.div(props => ({
  maxWidth: '1140px',
  width: '100%',
  margin: '0 auto',
  paddingTop: '6rem',
  zIndex: 4,
  '@media (max-width: 1280px)': {
    padding: '3.5rem 3rem 0',
  },
  '@media (max-width: 600px)': {
    padding: '2rem 0 0',
    lineHeight: 1,
  },
  h1: {
    maxWidth: '790px',
    marginBottom: '3rem',
    color: props.getTextColor(props.page, props.theme),
    padding: '1rem',
    span: {
      color: props.theme.colorFireEngineRed,
    },
    '@media (max-width: 1280px)': {
      fontSize: '3rem',
    },
    '@media (max-width: 600px)': {
      padding: '2rem 2rem',
      lineHeight: 1,
      fontSize: '2rem',
    },
  },
}))

export default Intro
