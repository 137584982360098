import React from 'react'
import styled from 'styled-components'
import { ContactForm, Slide } from '../../components'
import { TeamPanel } from '../../components/TeamPanel'

const ContactMenu = props => {
    const { title, description, contacts } = props

    return (
        <Slide>
            <Container>
                <TeamPanel/>
                <RightSideWrapper>
                    <ContactForm
                        title={title}
                        description={description}
                        contacts={contacts}
                    />
                </RightSideWrapper>
            </Container>
        </Slide>
    )
}

const Container = styled.div(props => ({
    display: 'flex',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column-reverse',
    '@media (min-width: 960px)': {
        flexDirection: 'row'
    }
}))

const RightSideWrapper = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexBasis: 0,
    backgroundColor: props.theme.colorMineShaft,
    padding: '1rem',
    '@media (max-width: 960px)': {
        flexBasis: 'auto'
    }
}))

export default ContactMenu
