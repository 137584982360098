import React from 'react'
import styled from 'styled-components'
import { ArrowRightIcon } from '../assets'

export const ShowButton = props => {
  return (
    <ShowButtonLink>
      <span>Show me more</span>
    </ShowButtonLink>
  )
}

const ShowButtonLink = styled.button(props => ({
  width: '185px',
  height: '39px',
  outline: 0,
  border: 0,
  backgroundColor: 'unset',
  padding: 0,
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    width: '140px',
    height: '39px',
  },
  span: {
    display: 'block',
    width: '100%',
    position: 'relative',
    fontFamily: 'bebas_neuebold',
    fontSize: '1.25rem',
    color: props.theme.colorMineShaft,
    paddingLeft: '.2rem',
    textAlign: 'left',
    zIndex: 10,
    '@media (max-width: 600px)': {
      fontSize: '1rem',
      paddingLeft: '.1rem',
    },
    '&::after': {
      display: 'block',
      content: "' '",
      position: 'absolute',
      height: '35px',
      width: '179px',
      top: '8px',
      left: 0,
      backgroundImage: `url(${ArrowRightIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      zIndex: '-1',
      '@media (max-width: 600px)': {
        height: '25px',
      },
    },
  },
}))
