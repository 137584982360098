import styled from 'styled-components'

export const ContentWrapper = styled.div(props => ({
  color: props.theme.colorMineShaft,
  padding: '2rem 1rem',

  '@media(min-width: 960px)': {
    padding: '3rem 6rem 3rem 3rem'
  }
}))

export const Header = styled.h1(props => ({
  paddingTop: '2rem',
  paddingBottom: '2rem',
  paddingLeft: '2rem',
  maxWidth: '90%',
  fontSize: '2rem',

  '@media(min-width: 960px)': {
    fontSize: '3rem',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    paddingLeft: '3rem',
    maxWidth: '80%'
  }
}))

export const HeaderInWrapper = styled.h1(props => ({
  padding: '0 3rem 2rem 0',
  fontSize: '2rem',

  '@media(min-width: 960px)': {
    fontSize: '3rem'
  }
}))

export const SubHeader = styled.h2(props => ({
  fontSize: '1.5rem',
  marginTop: '2rem',
  marginBottom: '1rem'
}))

export const Text = styled.p(props => ({
  display: props.isInline && 'inline'
}))

export const Image = styled.img(props => ({
  maxHeight: '130%',
  width: props.halfSize ? '90%' : '75%',
  display: 'block',
  margin: 'auto',
  marginTop: '2rem',
  boxShadow: props.isShadow ? '2px 2px 5px 1px rgba(0,0,0,0.1);' : '',
  marginBottom: '2.5rem',
  borderRadius: '2px',
  '@media(min-width: 960px)': {
    width: props.halfSize ? '50%' : '75%'
  }
}))

export const ComputerImage = styled.img(props => ({
  maxHeight: '80%',
  maxWidth: '100%',
  display: 'block',
  margin: 'auto',
  flexBasis: 0,
  position: 'relative',
  zIndex: '10',
  marginTop: '2rem',
  '@media(min-width: 960px)': {
    width: '90%',
    maxHeight: '130%',
  }
}))

export const Link = styled.a(props => ({
  marginTop: '1rem',
  display: props.isInline ? 'inline' : 'block',
  color: props.theme.colorFireEngineRed,
  wordWrap: 'break-word',

  '&::visited': {
    color: props.theme.colorFireEngineRed
  }
}))

export const Section = styled.div(props => ({
  backgroundColor: props.backgroundColor,
  height: '50vh',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'end',
  marginBottom: '2rem',
  '@media(min-width: 960px)': {
    marginBottom: 0,
    flexDirection: 'row',
    height: '35vh'
  }
}))

export const ClientInfo = styled.div(props => ({
  margin: 'auto',
  paddingTop: '2rem',
  width: '80%',
  '@media(min-width: 960px)': {
    width: '100%',
    marginLeft: '8rem'
  }
}))
