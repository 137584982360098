import React from 'react'
import styled from 'styled-components'

export const ProgressLine = props => {
  const { activeSlide, page, slidesLength } = props
  const activeSlideIncremented = activeSlide + 1
  const filledBarLength = 13 / slidesLength
  const barLength =
    activeSlideIncremented === 1
      ? filledBarLength
      : filledBarLength * activeSlideIncremented

  return (
    <LineWrapper page={page}>
      <Line>
        <Progress barLength={barLength} page={page} />
      </Line>
    </LineWrapper>
  )
}

const LineWrapper = styled.div(props => ({
  display: 'block',
  position: 'absolute',
  height: '209px',
  zIndex: 4,
  top: '50%',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  right:
    props.page === '/work' ||
    props.page === '/work/it-operations' ||
    props.page === '/work/design' ||
    props.page === '/work/erp' ||
    props.page === '/work/data-warehouse' ||
    props.page === '/work/software-development' ||
    props.page === '/work/banking'
      ? '4rem'
      : '',
  left:
    props.page === '/team' ||
    props.page === '/team/it-operations' ||
    props.page === '/team/design' ||
    props.page === '/team/erp' ||
    props.page === '/team/data-warehouse' ||
    props.page === '/team/software-development' ||
    props.page === '/team/banking'
      ? 0
      : '',
  '@media (max-width: 1280px)': {
    right:
      props.page === '/work' ||
      props.page === '/work/it-operations' ||
      props.page === '/work/design' ||
      props.page === '/work/erp' ||
      props.page === '/work/data-warehouse' ||
      props.page === '/work/software-development' ||
      props.page === '/work/banking'
        ? '-0.1rem'
        : '',
    left:
      props.page === '/team' ||
      props.page === '/team/it-operations' ||
      props.page === '/team/design' ||
      props.page === '/team/erp' ||
      props.page === '/team/data-warehouse' ||
      props.page === '/team/software-development' ||
      props.page === '/team/banking'
        ? '-4.1rem'
        : '',
  },
}))

const Line = styled.div(props => ({
  display: 'block',
  position: 'relative',
  height: '100%',
  overflow: 'hidden',
  background: props.theme.colorWhite,
  width: '.25rem',
  marginLeft: '4rem',
}))

const Progress = styled.div(props => ({
  display: 'block',
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  background: props.theme.colorBlack,
  height: `${props.barLength}rem`,
  transition: 'height 0.35s ease-in',
}))
