import React from 'react'
import styled from 'styled-components'
import {
  BanKhar,
  // BanKharTravelImage, //not used??
  BanKharTravelShadowImage,
  DigmenthaTravelImage,
  DigmenthaTravelShadowImage,
  FuriaTravelImage,
  FuriaTravelShadowImage,
  I4CTravelImage,
  I4CTravelShadowImage,
  MapTravelImage,
  OctorionTravelImage,
  OctorionTravelShadowImage,
  OphosTravelImage,
  OphosTravelShadowImage
} from '../../assets'
import { Slide } from '../../components'

const ThirdScreen = props => {
  const { title, description } = props

  return (
    <Slide>
      <Container>
        <ContentWrapper>
          <h1>{title}</h1>
          <h2>{description}</h2>
        </ContentWrapper>
        <SectionWrapper>
          <MapTravel/>
        </SectionWrapper>
        <BottomWrapper>
          <SvgWrapper>
            <OphosTravel/>
            <OphosTravelShadow/>
            <BanKharTravel/>
            <BanKharTravelShadow/>
            <OctorionTravel/>
            <OctorionTravelShadow/>
            <I4CTravel/>
            <I4CTravelShadow/>
            <DigmenthaTravel/>
            <DigmenthaTravelShadow/>
            <FuriaTravel/>
            <FuriaTravelShadow/>
          </SvgWrapper>
        </BottomWrapper>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  position: 'relative',
  overflow: 'hidden',
  height: 'calc(100% - 3rem)',
  paddingTop: '3rem',
  '@media (min-width: 960px)': {
    height: '100%',
    paddingTop: 0
  }
}))

const ContentWrapper = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '75%',
  margin: 'auto',
  zIndex: 0,
  h1: {
    padding: '1rem 0',
    color: props.theme.colorBlackPearl,
    lineHeight: '1.2',
    '@media (min-width: 600px)': {
      paddingTop: '4rem'
    },
    '@media (max-width: 1280px)': {
      fontSize: '3rem'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      fontSize: '2rem',
      lineHeight: 1
    }
  },
  h2: {
    fontSize: '2rem'
  }
}))

const SectionWrapper = styled.div(props => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  backgroundImage: props.theme.gradientWhiteLilac,
  opacity: 0.49,
  zIndex: -1
}))

const MapTravel = styled(MapTravelImage)(props => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  margin: '0 auto'
}))

const BottomWrapper = styled.div(props => ({
  position: 'absolute',
  width: '100%',
  height: '40%',
  bottom: 0,
  left: 0,
  right: 0,
  margin: '0 auto',
  backgroundColor: props.theme.colorQuartz,
  zIndex: 1,
  '@media (max-width: 1280px)': {
    bottom: '-50px'
  }
}))

const SvgWrapper = styled.div(props => ({
  position: 'absolute',
  maxWidth: '900px',
  width: '100%',
  height: '100%',
  bottom: 0,
  left: 0,
  right: 0,
  margin: '0 auto'
}))

const OphosTravel = styled(OphosTravelImage)(props => ({
  position: 'absolute',
  top: '-80px',
  left: '20px',
  zIndex: 3,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const OphosTravelShadow = styled(OphosTravelShadowImage)(props => ({
  position: 'absolute',
  top: '20px',
  left: '-100px',
  zIndex: 1,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const BanKharTravel = styled(BanKhar)(props => ({
  position: 'absolute',
  top: '-120px',
  left: '17px',
  zIndex: 2,
  height: '172px',
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const BanKharTravelShadow = styled(BanKharTravelShadowImage)(props => ({
  position: 'absolute',
  top: '20px',
  left: '100px',
  zIndex: 1,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const OctorionTravel = styled(OctorionTravelImage)(props => ({
  position: 'absolute',
  top: '-250px',
  left: 0,
  right: 0,
  margin: '0 auto',
  zIndex: 2,
  '@media (max-width: 900px)': {
    top: '-200px',
    height: '300px',
    bottom: '100px'
  },
  '@media (max-width: 600px)': {
    width: '50%'
  }
}))

const OctorionTravelShadow = styled(OctorionTravelShadowImage)(props => ({
  position: 'absolute',
  top: '70px',
  left: 0,
  right: '15px',
  margin: '0 auto',
  zIndex: 1,
  '@media (max-width: 600px)': {
    top: 0,
    right: '10px'
  }
}))

const I4CTravel = styled(I4CTravelImage)(props => ({
  position: 'absolute',
  top: '-150px',
  right: '190px',
  zIndex: 2,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const I4CTravelShadow = styled(I4CTravelShadowImage)(props => ({
  position: 'absolute',
  top: '54px',
  right: '145px',
  zIndex: 1,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const DigmenthaTravel = styled(DigmenthaTravelImage)(props => ({
  position: 'absolute',
  top: '-130px',
  right: '90px',
  zIndex: 2,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const DigmenthaTravelShadow = styled(DigmenthaTravelShadowImage)(props => ({
  position: 'absolute',
  top: '5px',
  right: '-50px',
  zIndex: 1,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const FuriaTravel = styled(FuriaTravelImage)(props => ({
  position: 'absolute',
  top: '-200px',
  right: '15px',
  zIndex: 2,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

const FuriaTravelShadow = styled(FuriaTravelShadowImage)(props => ({
  position: 'absolute',
  top: '10px',
  right: '35px',
  zIndex: 1,
  '@media (max-width: 900px)': {
    display: 'none'
  }
}))

export default ThirdScreen
