import { Helmet } from 'react-helmet'
import React from 'react'
import { seo } from '../seo'

export const SEO = ({ page }) => {
  const { title, description } = seo[page]

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  )
}
