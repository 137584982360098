import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  LogoImage,
  ShadowsImage,
  GroundImage,
  IndexHeroesImage,
  EqualPayLogo
} from '../../assets'
import { Slide } from '../../components'

const Welcome = props => {
  const { title, team } = props

  return (
    <Slide>
      <Container>
        <HeadingWrapper>
          <Link to="/">
            <Logo />
          </Link>
          <EqualPayLogoWrapper>
            <EqualPaySvg />
          </EqualPayLogoWrapper>
          <h1>{title}</h1>
        </HeadingWrapper>
        <IndexHeroes />
        <Shadows />
        <BottomWrapper>
          <MouseDownButton>
            <Team>{team}</Team>
          </MouseDownButton>
        </BottomWrapper>
      </Container>
    </Slide>
  )
}


const Team = styled.h3(props => ({
  width: '80%',
  textAlign: 'center'
}))

const Container = styled.div(props => ({
  height: '100%',
  position: 'relative',
  background: props.theme.gradientDarkRed,
}))

const HeadingWrapper = styled.div(props => ({
  maxWidth: '1110px',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  h1: {
    marginTop: '10%',
    textAlign: 'center',
    color: props.theme.colorWhite,
    zIndex: 2,
    position: 'relative',
    '@media (max-width: 1280px)': {
      maxWidth: '70%',
      margin: '0 auto',
      paddingTop: '2rem',
    },
  },
}))

const Logo = styled(LogoImage)(props => ({
  margin: '1.5rem',
  'g g': {
    fill: props.theme.colorWhite,
  },
  'g polygon': {
    fill: props.theme.colorWhite,
  },
}))

const EqualPaySvg = styled(EqualPayLogo)(props => ({
}))

const EqualPayLogoWrapper = styled.div(props => ({
  maxWidth: '124px',
  width: '124px',
  margin: '0 1.5rem 1.5rem 1.5rem'
}))

const IndexHeroes = styled(IndexHeroesImage)(props => ({
  width: '90%',
  position: 'absolute',
  bottom: '170px',
  left: 0,
  right: 0,
  margin: '0 auto',
  zIndex: 1,
  '@media (max-width: 1280px)': {
    width: '100%',
    height: '20%',
    bottom: '160px',
  },
}))

const Shadows = styled(ShadowsImage)(props => ({
  width: '100%',
  position: 'absolute',
  bottom: '90px',
  left: 0,
  right: 0,
  margin: '0 auto',
  opacity: 0.5,
  zIndex: 0,
  '@media (max-width: 1280px)': {
    width: '50%',
    bottom: '70px',
  },
}))

const BottomWrapper = styled.div(props => ({
  position: 'absolute',
  width: '100%',
  bottom: '-1px',
  height: '270px',
  backgroundImage: `url(${GroundImage})`,
  backgroundRepeat: 'repeat-x',
  backgroundPosition: 'bottom',
  backgroundSize: '1280px 240px',
}))

const MouseDownButton = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  position: 'absolute',
  bottom: '70px',
  '@media (max-width: 600px)': {
    bottom: '40px',
  },
  h3: {
    marginBottom: '1rem',
    color: props.theme.colorWhite,
    '@media (max-width: 600px)': {
      fontSize: '1rem',
    },
  },
}))

export default Welcome
