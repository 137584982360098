import React from 'react'
import styled from 'styled-components'
import {
  DigmenthaIntroImage,
  DigmenthaAvatarImage,
  DigmenthaBottomImage,
  DigmenthaAbilityImage,
  DigmenthaRaceImage,
  DigmenthaMissionImage,
  DimenthaIntro,
  DimenthaPlanningIcon,
  DimenthaTransformationIcon,
} from '../../assets'
import { Intro, About, Section, ContactMenu } from '../../layout/TeamDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const TeamERP = props => {
  const { data } = useAppContext()

  const assets = [
    { image: DigmenthaAbilityImage },
    { image: DigmenthaMissionImage },
    { image: DigmenthaRaceImage },
  ]

  const icons = [
    { icon: DimenthaTransformationIcon },
    { icon: DimenthaPlanningIcon },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: '-10px',
    backgroundImage: `url(${DigmenthaBottomImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    backgroundSize: 'contain',
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="teamERP" />
      <Intro
        background={DimenthaIntro}
        image={DigmenthaIntroImage}
        title={data.teamPageERPContent.title}
        page={props.location.pathname}
      >
        <BottomRectangle />
      </Intro>
      <About
        avatar={DigmenthaAvatarImage}
        title={data.teamPageERPContent.name}
        description={data.teamPageERPContent.about}
        icons={getMergedContent(data.teamPageERPContent.icons, icons)}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.teamPageERPContent.sections, assets)[0]}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.teamPageERPContent.sections, assets)[1]}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.teamPageERPContent.sections, assets)[2]}
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.teamPageERPContent.footer.title}
        description={data.teamPageERPContent.footer.description}
        contacts={data.teamPageERPContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
