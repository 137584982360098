import React from 'react'
import styled from 'styled-components'
import { WorkPointIcon } from '../../assets'
import { getContainerBgColor, getHeadingColor } from '../../utils'
import { Slide } from '../../components'

const Intro = props => {
  const {
    points,
    title,
    titleMobile,
    background,
    image,
    page,
    children,
  } = props

  const Container = styled.div(props => ({
    height: '100%',
    position: 'relative',
    backgroundImage: `url(${background})`,
    backgroundPosition: page === '/work/design' ? '' : 'center bottom',
    backgroundSize: 'cover',
    backgroundColor: props.getBgColor(props.page, props.theme),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }))

  const HeroDevelopment = styled(image)(props => ({
    position: 'absolute',
    bottom: '50px',
    left: '20%',
    right: 0,
    margin: '0 auto',
    zIndex: 3,
    width: '60%',
    height: '60%',
    '@media (max-width: 1280px)': {
      width: '70%',
      height: '50%',
      bottom: '40px',
    },
    '@media (max-width: 960px)': {
      width: '50%',
      height: '50%',
      bottom: '0',
    },
  }))

  const HeroDesign = styled(image)(props => ({
    position: 'absolute',
    bottom: '140px',
    left: 0,
    right: 0,
    margin: '0 auto',
    zIndex: 3,
    width: '45%',
    height: '45%',
    '@media (max-width: 1280px)': {
      width: '70%',
      height: '50%',
      bottom: '50px',
    },
    '@media (max-width: 960px)': {
      width: '50%',
      height: '50%',
      bottom: '0',
    },
  }))

  const HeroITOperations = styled(image)(props => ({
    position: 'absolute',
    bottom: '-20px',
    left: 0,
    right: 0,
    margin: '0 auto',
    zIndex: 3,
    width: '60%',
    height: '60%',
    '@media (max-width: 1280px)': {
      width: '80%',
      height: '60%',
      bottom: '20px',
    },
    '@media (max-width: 960px)': {
      width: '50%',
      height: '50%',
      bottom: '0',
    },
  }))

  const HeroBanking = styled(image)(props => ({
    position: 'absolute',
    bottom: '90px',
    left: 0,
    right: 0,
    margin: '0 auto',
    zIndex: 3,
    width: '60%',
    height: '60%',
    '@media (max-width: 1280px)': {
      width: '70%',
      height: '50%',
    },
    '@media (max-width: 960px)': {
      width: '50%',
    },
    '@media (max-width: 600px)': {
      width: '35%',
      height: '35%',
      bottom: '50px',
    },
  }))

  const HeroDWH = styled(image)(props => ({
    position: 'absolute',
    bottom: '30px',
    left: '20%',
    right: 0,
    margin: '0 auto',
    zIndex: 3,
    width: '55%',
    height: '55%',
    '@media (max-width: 1280px)': {
      width: '70%',
      height: '50%',
      bottom: '20px',
    },
    '@media (max-width: 960px)': {
      width: '50%',
      height: '50%',
      bottom: '-5px',
    },
  }))

  const HeroERP = styled(image)(props => ({
    position: 'absolute',
    bottom: '80px',
    left: 0,
    right: 0,
    margin: '0 auto',
    zIndex: 3,
    width: '60%',
    height: '60%',
    '@media (max-width: 1280px)': {
      width: '70%',
      height: '50%',
      bottom: '50px',
    },
    '@media (max-width: 960px)': {
      width: '35%',
      height: '35%',
      bottom: '50px',
    },
  }))

  return (
    <Slide>
      <Container
        background={background}
        page={page}
        getBgColor={getContainerBgColor}
      >
        <HeadingWrapper page={page} getHeadingColor={getHeadingColor}>
          <HeadingDesktop
            dangerouslySetInnerHTML={{ __html: title }}
          ></HeadingDesktop>
          <HeadingMobile
            dangerouslySetInnerHTML={{ __html: titleMobile }}
          ></HeadingMobile>
          <PointsWrapper display-if={points}>
            {points.map(item => (
              <Point key={item.text} page={page}>
                <PointSvg page={page} />
                <h4>{item.text}</h4>
              </Point>
            ))}
          </PointsWrapper>
        </HeadingWrapper>
        <HeroDevelopment
          image={image}
          display-if={page === '/work/software-development'}
        />
        <HeroDesign image={image} display-if={page === '/work/design'} />
        <HeroITOperations
          image={image}
          display-if={page === '/work/it-operations'}
        />
        <HeroBanking image={image} display-if={page === '/work/banking'} />
        <HeroDWH image={image} display-if={page === '/work/data-warehouse'} />
        <HeroERP image={image} display-if={page === '/work/erp'} />
        {children}
      </Container>
    </Slide>
  )
}

const HeadingWrapper = styled.div(props => ({
  maxWidth: '1140px',
  width: '100%',
  margin: '0 auto',
  paddingTop: '6rem',
  zIndex: 4,
  '@media (max-width: 1280px)': {
    padding: '3.5rem 3rem 0',
  },
  '@media (max-width: 600px)': {
    padding: '1.5rem',
  },
  h1: {
    maxWidth: '790px',
    width: '100%',
    color: props.getHeadingColor(props.page, props.theme),
    padding: '1rem',
    '@media (max-width: 1280px)': {
      maxWidth: '660px',
      textAlign: 'left',
      padding: '1rem',
      fontSize: '3rem',
    },
    '@media (max-width: 600px)': {
      maxWidth: '300px',
      width: '100%',
      textAlign: 'left',
      padding: '2rem 1rem',
      fontSize: '2rem',
    },
  },
}))

const HeadingDesktop = styled.h1(props => ({
  display: 'block',
  span: {
    color: props.theme.colorFireEngineRed,
  },
  '@media (max-width: 600px)': {
    display: 'none',
  },
}))

const HeadingMobile = styled.h1(props => ({
  display: 'none',
  '@media (max-width: 600px)': {
    display: 'block',
  },
}))

const PointsWrapper = styled.div(props => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexFlow: 'column',
  marginLeft: '4rem',
  '@media (max-width: 1280px)': {
    marginLeft: '2rem',
  },
  '@media (max-width: 960px)': {
    marginRight: '2rem',
  },
  '@media (max-width: 600px)': {
    marginLeft: 0,
  },
}))

const Point = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '.5rem',
  h4: {
    fontSize: '1.125rem',
    maxWidth: '250px',
    width: '100%',
    color:
      props.page === '/work/it-operations'
        ? props.theme.colorWhite
        : props.theme.colorMineShaft,
    paddingTop: '.1rem',
    lineHeight: 1.4,
    '@media (max-width: 600px)': {
      fontSize: '1rem',
    },
  },
}))

const PointSvg = styled(WorkPointIcon)(props => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexFlow: 'column',
  marginRight: '.7rem',
  'circle:nth-child(1)': {
    fill:
      props.page === '/work/it-operations'
        ? props.theme.colorWhite
        : props.theme.colorFireEngineRed,
  },
  'circle:nth-child(2)': {
    stroke:
      props.page === '/work/it-operations'
        ? props.theme.colorWhite
        : props.theme.colorFireEngineRed,
  },
  'circle:nth-child(3)': {
    stroke:
      props.page === '/work/it-operations'
        ? props.theme.colorWhite
        : props.theme.colorFireEngineRed,
  },
  '@media (max-width: 600px)': {
    width: '20px',
    height: '20px',
  },
}))

export default Intro
