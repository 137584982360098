import React from 'react'
import styled from 'styled-components'
import {
  CityDarkLeftImage,
  CityDarkRightImage,
  CityLightLeftImage,
  CityLightRightImage,
  HqBalticImage,
  HumanImage,
  ShadowTriangleImage
} from '../../assets'
import { Slide } from '../../components'
import InfoText from '../../components/InfoText'

const FirstScreen = props => {
  const { title, description, locations, message } = props

  return (
    <Slide>
      <Container>
        <InfoText
          textColor={'black'}
          title={title}
          firstDescription={description}
        />
        <LocationWrapper>
          <Locations/>
          <LocationIcons>
            {locations.map(item => (
              <h2 key={item}>{item}</h2>
            ))}
          </LocationIcons>
        </LocationWrapper>
        <Human/>
        <CityDarkWrapper>
          <CityDarkLeft/>
          <CityDarkRight/>
        </CityDarkWrapper>
        <CityLightWrapper>
          <CityLightLeft/>
          <CityLightRight/>
        </CityLightWrapper>
        <BottomWrapper>
          <DarkOvalBackground/>
          <h2>{message}</h2>
          <ShadowTriangle/>
        </BottomWrapper>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  position: 'relative',
  backgroundImage: props.theme.gradientWhiteSmoke,
  overflow: 'hidden',
  height: 'calc(100% - 3rem)',
  paddingTop: '3rem',
  '@media (min-width: 960px)': {
    height: '100%',
    paddingTop: 0
  }
}))

const LocationWrapper = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '75%',
  margin: 'auto',
  zIndex: 0,
  h1: {
    padding: '1rem 0',
    color: props.theme.colorBlackPearl,
    paddingTop: '4rem',
    lineHeight: '1.2',
    '@media (max-width: 1280px)': {
      fontSize: '3rem'
    },
    '@media (max-width: 600px)': {
      width: '100%',
      fontSize: '2rem',
      lineHeight: 1
    }
  }
}))

const Locations = styled(HqBalticImage)(props => ({
  position: 'relative',
  margin: '0 auto',
  width: '100%',
  marginBottom: '1rem',
  marginTop: '5rem',
  '@media (max-width: 1280px)': {
    height: '130px',
    margin: '0 auto',
    marginTop: '2rem'
  },
  '@media (max-width: 600px)': {
    height: '90px',
    margin: '0 auto'
  }
}))

const LocationIcons = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-around',
  maxWidth: '924px',
  width: '100%',
  margin: '0 auto',
  padding: '1rem 0',
  '@media (max-width: 960px)': {
    justifyContent: 'space-between'
  },
  h2: {
    color: props.theme.colorBlackPearl,
    '@media (max-width: 960px)': {
      fontSize: '2rem'
    }
  }
}))

const CityDarkWrapper = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  position: 'absolute',
  width: '90%',
  height: '200px',
  bottom: '100px',
  left: 0,
  right: 0,
  margin: '0 auto',
  zIndex: 2
}))

const CityDarkLeft = styled(CityDarkLeftImage)(props => ({
  position: 'relative',
  margin: '0 auto'
}))

const CityDarkRight = styled(CityDarkRightImage)(props => ({
  position: 'relative',
  margin: '0 auto'
}))

const CityLightWrapper = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  position: 'absolute',
  width: '90%',
  height: '200px',
  bottom: '100px',
  left: 0,
  right: 0,
  margin: '0 auto',
  zIndex: 0,
  '@media (max-width: 1280px)': {
    width: '80%',
    bottom: '80px'
  },
  '@media (max-width: 600px)': {
    width: '80%',
    bottom: '80px'
  }
}))

const CityLightLeft = styled(CityLightLeftImage)(props => ({
  position: 'relative',
  margin: '0 auto'
}))

const CityLightRight = styled(CityLightRightImage)(props => ({
  position: 'relative',
  margin: '0 auto'
}))

const Human = styled(HumanImage)(props => ({
  position: 'absolute',
  width: '100%',
  height: '250px',
  left: '-5px',
  right: 0,
  bottom: '100px',
  margin: '0 auto',
  zIndex: 3,
  '@media (max-width: 1280px)': {
    width: '100%',
    height: '200px'
  },
  '@media (max-width: 600px)': {
    height: '100px'
  }
}))

const BottomWrapper = styled.div(props => ({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  zIndex: 1,
  height: '250px',
  h2: {
    position: 'absolute',
    bottom: '20px',
    left: '10px',
    right: 0,
    margin: '0 auto',
    textAlign: 'center',
    color: props.theme.colorWhite,
    zIndex: 4
  },
  '@media (max-width: 1280px)': {
    h2: {
      fontSize: '2rem'
    }
  },
  '@media (max-width: 600px)': {
    bottom: '-10px',
    height: '270px',
    h2: {
      fontSize: '1.5rem'
    }
  }
}))

const DarkOvalBackground = styled.div(props => ({
  clipPath: 'ellipse(60% 60% at 50% 95%)',
  backgroundColor: props.theme.colorNightRider,
  height: '100%',
  '@media (max-width: 685px)': {
    clipPath: 'ellipse(80% 60% at 50% 95%)'
  }
}))

const ShadowTriangle = styled(ShadowTriangleImage)(props => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  margin: '0 auto',
  zIndex: 2,
  '@media (max-width: 1280px)': {
    width: '100%',
    height: '140px',
    left: 0
  },
  '@media (max-width: 600px)': {
    bottom: '-10px'
  }
}))

export default FirstScreen
