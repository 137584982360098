import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ArrowBackIcon, LogoImage } from '../assets'
import { ProgressLine } from './'
import { withFilter } from '../utils'
import { useAppContext } from '../context'

const TEAM_ROOT = '/team'
const WORK_ROOT = '/work'
const PATHS = [
  '/erp',
  '/banking',
  '/it-operations',
  '/data-warehouse',
  '/design',
  '/software-development'
]
const mapPathToRoot = root => PATHS.map(i => `${root}${i}`)
const ALL_TEAM_AND_WORK_PATHS = [
  TEAM_ROOT,
  WORK_ROOT,
  ...mapPathToRoot(TEAM_ROOT),
  ...mapPathToRoot(WORK_ROOT)
]
const EXCEPTIONS = [
  '/team',
  '/team/erp',
  '/team/it-operations',
  '/team/data-warehouse',
  '/work/it-operations'
]

export const Navigation = props => {
  const { state } = useAppContext()
  const { slidesLength, activeIdx: activeSlide } = state
  const page = props.location.pathname

  if (page === '/home' || page === '/404' || page === '/summerdays/Riviera'  || page === '/summerdays/riviera') return null

  const lastSlide = activeSlide === slidesLength - 1
  const firstSlide = activeSlide === 0

  return (
    <NavigationWrapper>
      <ProgressLine
        display-if={!lastSlide}
        slidesLength={slidesLength}
        activeSlide={activeSlide}
        page={page}
      />
      <Link to="/">
        <Logo
          page={page}
          activeSlide={activeSlide}
          firstSlide={firstSlide}
          lastSlide={lastSlide}
        />
      </Link>

      <Team page={page} activeSlide={activeSlide} lastSlide={lastSlide}>
        <Link to="/team">T</Link>
        <ArrowBackTeam
          page={page}
          activeSlide={activeSlide}
          lastSlide={lastSlide}
        />
      </Team>

      <Work page={page} activeSlide={activeSlide} lastSlide={lastSlide}>
        <Link to="/work">W</Link>
        <ArrowBackWork
          page={page}
          activeSlide={activeSlide}
          lastSlide={lastSlide}
        />
      </Work>
    </NavigationWrapper>
  )
}

const NavigationWrapper = styled.div(props => ({
  display: 'block'
}))

const Logo = styled(
  withFilter(LogoImage, ['firstSlide', 'lastSlide', 'activeSlide', 'page'])
)(props => ({
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: '1rem',
  zIndex: 10,
  cursor: 'pointer',
  'g g:nth-child(1)': {
    display: isFullLogoVisible(props),
    fill: getFullLogoColor(props)
  },
  'g g:nth-child(2)': {
    display: isFullLogoVisible(props)
  },
  '@media (max-width: 600px)': {
    width: '90px',
    margin: '.5rem',
    'g g:nth-child(1)': {
      display: 'none'
    },
    'g g:nth-child(2)': {
      display: 'none'
    }
  }
}))

const Team = styled.div(props => ({
  display: isButtonVisible(props, 'team'),
  position: 'absolute',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'bebas_neuebold',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  marginLeft: '.4rem',
  color: props.theme.colorFireEngineRed,
  cursor: 'pointer',
  zIndex: 10,
  a: {
    fontSize: '2.25rem',
    textDecoration: 'none',
    lineHeight: '.9',
    padding: '0 1.2rem',
    color: setTeamButtonColor(props)
  },
  '@media (max-width: 600px)': {
    fontSize: '1.5rem',
    marginLeft: '.2rem',
    a: {
      padding: '0 .8rem'
    }
  }
}))

const ArrowBackTeam = styled(
  withFilter(ArrowBackIcon, ['activeSlide', 'page', 'lastSlide'])
)(props => ({
  display: isArrowVisible(props, TEAM_ROOT),
  'g path': {
    stroke: setTeamButtonColor(props)
  },
  'g polygon': {
    fill: setTeamButtonColor(props)
  },
  '@media (max-width: 600px)': {
    width: '30px'
  }
}))

const Work = styled.div(props => ({
  display: isButtonVisible(props, 'work'),
  position: 'absolute',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'bebas_neuebold',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  marginRight: '.4rem',
  cursor: 'pointer',
  zIndex: 10,
  a: {
    fontSize: '2.25rem',
    textDecoration: 'none',
    lineHeight: '.9',
    padding: '0 1.2rem',
    color: setWorkButtonColor(props)
  },
  '@media (max-width: 600px)': {
    fontSize: '1.5rem',
    marginRight: 0,
    a: {
      padding: '0 .8rem'
    }
  }
}))

const ArrowBackWork = styled(
  withFilter(ArrowBackIcon, ['activeSlide', 'page', 'lastSlide'])
)(props => ({
  display: isArrowVisible(props, WORK_ROOT),
  transform: 'rotate(180deg)',
  'g path': {
    stroke: setWorkButtonColor(props)
  },
  'g polygon': {
    fill: setWorkButtonColor(props)
  },
  '@media (max-width: 600px)': {
    width: '30px'
  }
}))

// work/team root and all their sub-paths on first and last slide
const isFullLogoVisible = ({ page, firstSlide, lastSlide }) => {
  return (firstSlide || lastSlide) && ALL_TEAM_AND_WORK_PATHS.includes(page)
    ? 'block'
    : 'none'
}

// last slide or first slide and page exceptions are white
const getFullLogoColor = ({ lastSlide, firstSlide, theme, page }) => {
  return lastSlide || (firstSlide && EXCEPTIONS.includes(page))
    ? theme.colorWhite
    : theme.colorMineShaft
}

// work/team root and all their sub-paths
const isButtonVisible = ({ page, lastSlide }, type) => {
  if ([WORK_ROOT, TEAM_ROOT].includes(page) && lastSlide) return 'none'
  if (
    [...mapPathToRoot(WORK_ROOT)].includes(page) &&
    lastSlide &&
    type === 'team'
  ) {
    return 'none'
  }
  if (
    [...mapPathToRoot(TEAM_ROOT)].includes(page) &&
    lastSlide &&
    type === 'work'
  ) {
    return 'none'
  }
  return 'flex'
}

// All work/team sub paths
const isArrowVisible = ({ page }, rootPage) => {
  return [...mapPathToRoot(rootPage)].includes(page) ? 'block' : 'none'
}

const setTeamButtonColor = ({ page, activeSlide, theme, lastSlide }) => {
  if (
    (page === '/team/erp' && [0, 1].includes(activeSlide)) ||
    (page === '/work' && [2, 3].includes(activeSlide)) ||
    (page === '/work/it-operations' && [0].includes(activeSlide)) ||
    lastSlide
  ) {
    return theme.colorWhite
  } else if (page.includes('/team')) {
    return theme.colorFireEngineRed
  } else {
    return theme.colorMineShaft
  }
}

const setWorkButtonColor = ({ page, activeSlide, theme, lastSlide }) => {
  if (
    (page === '/team' && [0, 1].includes(activeSlide)) ||
    (page === '/team/banking' && [0].includes(activeSlide)) ||
    page === '/team/erp' ||
    page === '/team/data-warehouse' ||
    (page === '/work/it-operations' && [5].includes(activeSlide)) ||
    lastSlide
  ) {
    return theme.colorWhite
  } else if (
    page === '/team/software-development' ||
    page === '/team/design' ||
    (page === '/team/banking' && [1, 2, 3, 4].includes(activeSlide))
  ) {
    return theme.colorMineShaft
  } else if (
    page === '/work' ||
    page === '/work/software-development' ||
    (page === '/work/it-operations' && [0, 1, 2, 3, 4].includes(activeSlide)) ||
    page === '/work/data-warehouse' ||
    page === '/work/banking' ||
    page === '/work/erp' ||
    page === '/work/design' ||
    (page === '/team' && [2, 3].includes(activeSlide))
  ) {
    return theme.colorFireEngineRed
  } else {
    return theme.colorWhite
  }
}
