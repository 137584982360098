import React from 'react'
import styled from 'styled-components'
import { OphosBlueShadow, OphosWorkImage } from '../../assets'
import { Slide } from '../../components'
import InfoText from '../../components/InfoText'

const Avalanchers = props => {
    const { title, description } = props

    return (
        <Slide>
            <Container>
                <InfoText
                    textColor={'white'}
                    title={title}
                    firstDescription={description}
                />
                <OphosWork/>
            </Container>
        </Slide>
    )
}

const Container = styled.div(props => ({
    paddingTop: '3rem',
    height: 'calc(100% - 3rem)',
    backgroundColor: props.theme.colorBlackPearl,
    backgroundImage: `url(${OphosBlueShadow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: 'contain',
    zIndex: '0',
    overflow: 'hidden',
    '@media (min-width: 960px)': {
        paddingTop: 0,
        height: '100%'
    }
}))

const OphosWork = styled(OphosWorkImage)(props => ({
    position: 'absolute',
    width: '100%',
    height: '513px',
    left: 0,
    right: 0,
    bottom: '3%',
    margin: '0 auto',
    zIndex: 1,
    '@media (max-width: 1280px)': {
        width: '90%',
        height: '50%',
        bottom: '12px'
    },
    '@media (max-width: 960px)': {
        width: '80%',
        height: '40%',
        bottom: '12px'
    },
    '@media (max-width: 600px)': {
        width: '70%',
        height: '30%',
        bottom: '20px'
    }
}))

export default Avalanchers
