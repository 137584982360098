import React from 'react'
import styled from 'styled-components'
import {
  OctorionTeamIntro,
  OctorionPageImage,
  OctorionAvatarImage,
  OctorionAbilityImage,
  OctorionRaceImage,
  OctorionMissionImage,
  OctorionMinionsLeft,
  OctorionAnalysisIcon,
  OctorionDevIcon,
  OctorionITIcon,
  OctorionPublicIcon,
  OctorionSystemsIcon,
  OctorionWebAppIcon,
} from '../../assets'
import { Intro, About, Section, ContactMenu } from '../../layout/TeamDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const TeamDevelopment = props => {
  const { data } = useAppContext()

  const assets = [
    { image: OctorionAbilityImage },
    { image: OctorionMissionImage },
    { image: OctorionRaceImage },
  ]

  const icons = [
    { icon: OctorionDevIcon },
    { icon: OctorionITIcon },
    { icon: OctorionAnalysisIcon },
    { icon: OctorionSystemsIcon },
    { icon: OctorionWebAppIcon },
    { icon: OctorionPublicIcon },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '135px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorWhite,
    boxShadow: '0 -3px 18px 0 rgba(0,0,0,0.31)',
  }))

  const BottomMinionsLeft = styled(OctorionMinionsLeft)(props => ({
    display: 'block',
    position: 'absolute',
    bottom: '-20px',
    left: '70px',
    zIndex: 3,
    '@media (max-width: 1280px)': {
      display: 'none',
    },
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="teamDevelopment" />
      <Intro
        background={OctorionTeamIntro}
        image={OctorionPageImage}
        title={data.teamPageDevelopmentContent.title}
        page={props.location.pathname}
      >
        <BottomMinionsLeft />
        <BottomRectangle />
      </Intro>
      <About
        avatar={OctorionAvatarImage}
        title={data.teamPageDevelopmentContent.name}
        description={data.teamPageDevelopmentContent.about}
        icons={getMergedContent(data.teamPageDevelopmentContent.icons, icons)}
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageDevelopmentContent.sections, assets)[0]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageDevelopmentContent.sections, assets)[1]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageDevelopmentContent.sections, assets)[2]
        }
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.teamPageDevelopmentContent.footer.title}
        description={data.teamPageDevelopmentContent.footer.description}
        contacts={data.teamPageDevelopmentContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
