import React from 'react'
import styled from 'styled-components'
import {
  DimenthaPageImage,
  DimenthaApproachImage,
  DimenthaStrategyImage,
  DimenthaWorkIntroImage,
  // OracleNetsuiteIcon,
  Microsoft365Icon,
  // EcoboxIcon,
  // PipelifeIcon,
  // EnstoIcon,
} from '../../assets'
import { Intro, Section, ContactMenu } from '../../layout/WorkDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const WorkERP = props => {
  const { data } = useAppContext()

  const assets = [
    {
      image: DimenthaApproachImage,
      secondIconsLine: [
        // { icon: OracleNetsuiteIcon },
        { icon: Microsoft365Icon },
      ],
    },
    {
      image: DimenthaStrategyImage,
      // secondIconsLine: [
      //   { icon: EcoboxIcon },
      //   { icon: EnstoIcon },
      //   { icon: PipelifeIcon },
      // ],
    },
  ]

  const DimenthaWorkPageBackground = styled.div(props => ({
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${DimenthaWorkIntroImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: '100%',
  }))

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '136px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorFireEngineRed,
    zIndex: 2,
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="workERP" />
      <Intro
        image={DimenthaPageImage}
        page={props.location.pathname}
        title={data.workPageERPContent.title}
        titleMobile={data.workPageERPContent.titleMobile}
        points={data.workPageERPContent.points}
      >
        <DimenthaWorkPageBackground />
        <BottomRectangle />
      </Intro>
      <Section
        content={getMergedContent(data.workPageERPContent.sections, assets)[0]}
        page={props.location.pathname}
        isSectionButton={true}
      />
      <Section
        content={getMergedContent(data.workPageERPContent.sections, assets)[1]}
        page={props.location.pathname}
        isSectionButton={true}
      />
      <ContactMenu
        title={data.workPageERPContent.footer.title}
        description={data.workPageERPContent.footer.description}
        contacts={data.workPageERPContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
