import React from 'react'
import { SEO, ReactSwiperIdSlider } from '../../components'
import {
  Members,
  Deliver,
  Avalanchers,
  Assemble,
  ContactMenu,
} from '../../layout/Work'
import { useAppContext } from '../../context'

export const Work = props => {
  const { data } = useAppContext()

  return (
    <ReactSwiperIdSlider>
      <SEO page="work" />
      <Members
        title={data.workPageContent.title}
        description={data.workPageContent.description}
        cards={data.workPageContent.cards}
        page={props.location.pathname}
      />
      <Deliver
        title={data.workPageContent.about.title}
        description={data.workPageContent.about.description}
      />
      <Avalanchers
          title={data.workPageContent.neverGiveUp.title}
          description={data.workPageContent.neverGiveUp.description}/>
      <Assemble
        title={data.workPageContent.team.title}
        description={data.workPageContent.team.description}
      />
      <ContactMenu
        title={data.workPageContent.footer.title}
        description={data.workPageContent.footer.description}
        contacts={data.workPageContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
