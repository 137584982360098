import React from 'react'
import { ReactSwiperIdSlider, SEO } from '../../components'
import { Contacts, Menu, Welcome } from '../../layout/Home'
import { useAppContext } from '../../context'

export const Home = props => {
  const { data } = useAppContext()

  return (
    <ReactSwiperIdSlider>
      <SEO page="home"/>
      <Welcome
        title={data.homePageContent.title}
        team={data.homePageContent.team}
      />
      <Menu/>
      <Contacts
        title={data.homePageContent.footer.title}
        description={data.homePageContent.footer.description}
        contacts={data.homePageContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
