import React from 'react'
import { ContentWrapper, HeaderInWrapper, Image, Link, SubHeader, Text } from '../CommonComponents'
import styled from 'styled-components'
import { digitalTransformationContent } from '../../../resources/data'
import SavingsPanel from '../../SavingsPanel'

const TextLogoSection = styled.div(props => ({
  flexWrap: 'wrap',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '0 10%'
}))

const TextWithLogo = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  margin: '1rem',
  flex: '1 0 40%',
  '@media (min-width: 960px)': {
    flex: '1 0 18%'
  }
}))

const RedList = styled.ul(props => ({
  listStyle: 'none',
  marginBottom: '2.5rem',
  '& li::before': {
    content: '"\\25A0"',
    color: props.theme.colorFireEngineRed,
    verticalAlign: '10%',
    display: 'inline-block',
    width: '1em',
    marginLeft: '-1em'
  }
}))

const TextWithLink = styled.div(props => ({
  marginBottom: '2.5rem'
}))


export const DigitalTransformation = () => {
  return (
    <ContentWrapper>
      <HeaderInWrapper>{digitalTransformationContent.header}</HeaderInWrapper>
      {digitalTransformationContent.sections.map(section =>
        <React.Fragment key={section.header}>
          {section.hasOwnProperty('header') && <SubHeader>{section.header}</SubHeader>}
          {section.hasOwnProperty('text') && <Text>{section.text}</Text>}
          {section.hasOwnProperty('image') && <Image src={section.image} alt={section.image} halfSize/>}
          {section.hasOwnProperty('link') && <Link href={section.link.href}>{section.link.text}</Link>}
          {section.hasOwnProperty('saving') && <SavingsPanel saving={section.saving}/>}
          {section.hasOwnProperty('textWithLink') &&
          <TextWithLink>
            <Text isInline>{section.textWithLink.text}</Text>
            <Link href={section.textWithLink.linkHref} isInline>{section.textWithLink.linkText}</Link>
          </TextWithLink>}
          {section.hasOwnProperty('list') &&
          <RedList>
            {section.list.map(text => {
              return (
                <li>{text}</li>
              )
            })}
          </RedList>
          }
          {section.hasOwnProperty('illustratedText') &&
          <TextLogoSection>
            {section.illustratedText.map(textWithImage => {
              return (
                <TextWithLogo>
                  <img src={textWithImage.image} alt=''/>
                  <p>{textWithImage.text}</p>
                </TextWithLogo>
              )
            })}
          </TextLogoSection>
          }
        </React.Fragment>
      )}
    </ContentWrapper>
  )
}
