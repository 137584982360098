import React from 'react'
import styled from 'styled-components'
import { getAboutBgColor, getTextColor } from '../../utils'
import { Slide } from '../../components'

const About = props => {
  const { title, avatar, description, icons, page } = props

  const Avatar = styled(avatar)(props => ({
    position: 'relative',
    '@media (max-width: 1280px)': {
      height: '190px',
    },
    '@media (max-width: 960px)': {
      height: '180px',
    },
    '@media (max-width: 600px)': {
      height: '140px',
    },
  }))

  return (
    <Slide>
      <Container getAboutBgColor={getAboutBgColor} page={page}>
        <HeadingWrapper getTextColor={getTextColor} page={page}>
          <Avatar />
          <h1>{title}</h1>
          <Line />
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </HeadingWrapper>
        <IconsWrapper>
          {icons.map(item => (
            <Icon key={item.name} getTextColor={getTextColor} page={page}>
              <img src={item.icon} alt={item.name} />
              <h3>{item.name}</h3>
            </Icon>
          ))}
        </IconsWrapper>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  backgroundColor: props.getAboutBgColor(props.page, props.theme),
  overflow: 'hidden',
}))

const HeadingWrapper = styled.div(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '1110px',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  h1: {
    margin: '2rem 0',
  },
  'h1, p': {
    color: props.getTextColor(props.page, props.theme),
  },
  'p i': {
    color: props.theme.colorFireEngineRed,
  },
  'p i.white': {
    color: props.theme.colorWhite,
  },
  '@media (max-width: 1280px)': {
    h1: {
      fontSize: '3rem',
    },
  },
  '@media (max-width: 960px)': {
    h1: {
      fontSize: '2.25rem',
      margin: '0 0 1.3rem',
    },
    p: {
      width: '70%',
      textAlign: 'center',
    },
  },
  '@media (max-width: 600px)': {
    h1: {
      fontSize: '2rem',
    },
  },
}))

const Line = styled.div(props => ({
  width: '400px',
  height: '1px',
  backgroundColor: props.theme.colorNobel,
  '@media (max-width: 960px)': {
    width: '70%',
  },
}))

const IconsWrapper = styled.div(props => ({
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'row wrap',
  paddingBottom: '5rem',
  margin: '0 auto',
  '@media (max-width: 1280px)': {
    paddingBottom: '4rem',
  },
  '@media (max-width: 600px)': {
    maxWidth: '300px',
  },
}))

const Icon = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  width: '200px',
  margin: '1rem',
  h3: {
    color: props.getTextColor(props.page, props.theme),
    textAlign: 'center',
    paddingTop: '1rem',
  },
  img: {
    height: '50px',
  },
  '@media (max-width: 1280px)': {
    margin: '.5rem',
    width: '150px',
    h3: {
      fontSize: '1rem',
      paddingTop: '.5rem',
    },
    img: {
      height: '40px',
    },
  },
  '@media (max-width: 960px)': {
    h3: {
      fontSize: '1rem',
    },
    img: {
      height: '30px',
    },
  },
  '@media (max-width: 600px)': {
    width: '80px',
    h3: {
      fontSize: '.8rem',
    },
  },
}))

export default About
