import React from 'react'

export const DataContext = React.createContext()
export const DataContextProvider = DataContext.Provider

export const useAppContext = () => {
  return React.useContext(DataContext)
}

export function useSetState(initialState) {
  return React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState,
  )
}
