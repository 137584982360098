import React from 'react'
import styled from 'styled-components'
import { Slide } from '../../components'
import { TeamPanel } from "../../components/TeamPanel";
import {WorkPanel} from "../../components/WorkPanel";

const Menu = () => (
  <Slide>
    <Container>
      <TeamPanel/>
      <WorkPanel/>
    </Container>
  </Slide>
)

const Container = styled.div`
  display: flex;
  height: 100%;
  position: relative;`

export default Menu
