import styled from 'styled-components'

export const PolygonGrey = styled.div(props => ({
  position: 'absolute',
  bottom: '-1px',
  width: '101%',
  height: '50%',
  backgroundColor: props.theme.colorMineShaft,
  clipPath: 'polygon(0 0, 100% 23%, 100% 100%, 0% 100%)',
  zIndex: 1,
}))

export const PolygonRed = styled.div(props => ({
  position: 'absolute',
  bottom: '-1px',
  width: '101%',
  height: '50%',
  backgroundColor: props.theme.colorFireEngineRed,
  clipPath: 'polygon(0 0, 100% 23%, 100% 100%, 0% 100%)',
  zIndex: 1,
}))
