import React from 'react'
import styled from 'styled-components'
import {
  I4CPageImage,
  I4CProviderImage,
  I4CSolutionsImage,
  I4CStrategiesImage,
  I4CProductImage,
  I4CFutureImage,
  EleringIcon,
  OmnivaIcon,
  LuminorIcon,
  GraanulIcon,
  NortalIcon,
  VerticaIcon,
  OracleIcon,
  MicrosoftIcon,
  SapIcon,
  SybaseIcon,
  EEnergiaIcon,
  QlikIcon,
  OlympicIcon,
  PentahoIcon,
  CoopIcon,
  TalTechIcon,
  MicrosoftSQLIcon,
  PowerBiIcon,
  SapbusinessIcon,
  SybaseIQIcon,
  TableauIcon,
  TibcoJasperIcon,
} from '../../assets'
import { Intro, Section, ContactMenu } from '../../layout/WorkDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const WorkDWH = props => {
  const { data } = useAppContext()

  const assets = [
    {
      image: I4CProviderImage,
      firstIconsLine: [
        { icon: VerticaIcon },
        { icon: OracleIcon },
        { icon: MicrosoftIcon },
        { icon: SapIcon },
        { icon: SybaseIcon },
      ],
      secondIconsLine: [
        { icon: PentahoIcon },
        { icon: VerticaIcon },
        { icon: QlikIcon },
        { icon: OracleIcon },
        { icon: TibcoJasperIcon },
        { icon: PowerBiIcon },
        { icon: SapbusinessIcon },
        { icon: SybaseIQIcon },
        { icon: TableauIcon },
        { icon: MicrosoftSQLIcon },
      ],
    },
    {
      image: I4CSolutionsImage,
      firstIconsLine: [
        { icon: EleringIcon },
        { icon: OmnivaIcon },
        { icon: GraanulIcon },
        { icon: LuminorIcon },
        { icon: NortalIcon },
        { icon: EEnergiaIcon },
        { icon: OlympicIcon },
      ],
    },
    {
      image: I4CStrategiesImage,
      secondIconsLine: [
        { icon: OmnivaIcon },
        { icon: GraanulIcon },
        { icon: CoopIcon },
        { icon: TalTechIcon },
      ],
    },
    {
      image: I4CProductImage,
    },
    {
      image: I4CFutureImage,
    },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '136px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorWhite,
    zIndex: 2,
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="workDWH" />
      <Intro
        image={I4CPageImage}
        page={props.location.pathname}
        title={data.workPageDWHContent.title}
        titleMobile={data.workPageDWHContent.titleMobile}
        points={data.workPageDWHContent.points}
      >
        <BottomRectangle />
      </Intro>
      <Section
        content={getMergedContent(data.workPageDWHContent.sections, assets)[0]}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.workPageDWHContent.sections, assets)[1]}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.workPageDWHContent.sections, assets)[2]}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.workPageDWHContent.sections, assets)[3]}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.workPageDWHContent.sections, assets)[4]}
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.workPageDWHContent.footer.title}
        description={data.workPageDWHContent.footer.description}
        contacts={data.workPageDWHContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
