import React from 'react'
import styled from 'styled-components'
import { Slide, TeamSlider } from '../../components'
import InfoText from '../../components/InfoText'

const Members = props => {
  const { title, description, cards, page } = props

  return (
    <Slide>
      <Container>
        <InfoText
          textColor={'black'}
          title={title}
          firstDescription={description}
        />
        <TeamSliderWrapper>
          <TeamSlider slides={cards} page={page}/>
        </TeamSliderWrapper>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  height: '100%',
  overflow: 'hidden',
  paddingTop: '2rem',
  background: props.theme.colorSolitude,
  '@media (min-width: 960px)': {
    paddingTop: 0
  }
}))

const TeamSliderWrapper = styled.div(props => ({
  width: '1190px',
  margin: '0 auto',
  marginTop: '3rem',
  zIndex: '1',
  overflow: 'hidden',
  a: {
    textDecoration: 'none'
  },
  '@media (max-width: 1280px)': {
    width: '100%'
  }
}))

export default Members
