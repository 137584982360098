import React from 'react'
import styled from 'styled-components'
import { PolygonGrey, PolygonRed } from '../../components/Styled'
import { IconsList, ParagraphList, PointsList, ShowButton, Slide } from '../../components'
import { getCardBgColor, getContainerBgColor, getImageBgColor, getTextColor } from '../../utils'
import { useAppContext } from '../../context'

const Section = props => {
  const { state, actions } = useAppContext()
  const { content, page, map, isSectionButton } = props

  const Map = styled.div(props => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundImage: `url(${map})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }))

  const toggleOverlay = () => {
    actions.setOverlayContent(content.projects)
    actions.setOverlayOpen(!state.isOverlayOpen)
  }

  return (
    <Slide>
      <Container page={page} getBgColor={getContainerBgColor}>
        <ContentWrapper>
          <ContentCard page={page} getBgColor={getCardBgColor}>
            <ContentBox page={page} getTextColor={getTextColor}>
              <h1>{content.heading}</h1>
              <p>{content.paragraph}</p>
              <ParagraphList
                display-if={content.paragraphList}
                content={content}
              />
              <p display-if={content.paragraphSecond}>
                {content.paragraphSecond}
              </p>
              <p display-if={content.paragraphThird}>
                {content.paragraphThird}
              </p>
              <p display-if={content.subParagraph}>{content.subParagraph}</p>
              {isSectionButton && (
                <div onClick={() => toggleOverlay()}>
                  <ShowButton/>
                </div>
              )}
              <PointsList
                display-if={content.activities}
                content={content}
                page={page}
                subHeading={'firstHeadingLine'}
              />
              <IconsList
                display-if={content.firstIconsLine}
                content={content}
                page={page}
                iconsSet={'firstIconsLine'}
                subHeading={'firstHeadingLine'}
              />
              <IconsList
                display-if={content.secondIconsLine}
                content={content}
                page={page}
                iconsSet={'secondIconsLine'}
                subHeading={'secondHeadingLine'}
              />
            </ContentBox>
          </ContentCard>
        </ContentWrapper>
        <ImageWrapper>
          <ImageCard
            page={page}
            getImageBgColor={getImageBgColor}
            title={content.title}
          >
            <Image src={content.image} page={page} alt={content.title}/>
            <Map display-if={content.polygon && content.polygon === 'map'}/>
            <PolygonGrey
              display-if={content.polygon && content.polygon === 'grey'}
            />
            <PolygonRed
              display-if={content.polygon && content.polygon === 'red'}
            />
          </ImageCard>
        </ImageWrapper>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  display: 'flex',
  flexFlow: 'row wrap',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: props.getBgColor(props.page, props.theme)
}))

const ImageWrapper = styled.div(props => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 2,
  zIndex: 1,
  '@media (max-width: 1280px)': {
    display: 'none'
  }
}))

const ImageCard = styled.div(props => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: props.theme.colorLinkWater
}))

const Image = styled.img(props => ({
  width:
    (props.alt === 'Addition' && props.page === '/work/software-development') ||
    (props.alt === 'Software' && props.page === '/work/it-operations')
      ? '100%'
      : '',
  position: 'absolute',
  margin:
    (props.alt === 'Technologies' &&
      props.page === '/work/software-development') ||
    (props.alt === 'Addition' && props.page === '/work/software-development') ||
    (props.alt === 'Reporting' && props.page === '/work/banking') ||
    (props.alt === 'Software' && props.page === '/work/it-operations')
      ? '0'
      : '0 auto',
  left:
    props.alt === 'Reporting' && props.page === '/work/banking' ? '-150px' : 0,
  right: 0,
  bottom:
    props.alt === 'Software' && props.page === '/work/banking' ? '10px' : 0,
  top: props.alt === 'Software' && props.page === '/work/banking' ? '' : '50%',
  transform:
    props.alt === 'Software' && props.page === '/work/banking'
      ? ''
      : 'translateY(-50%)',
  zIndex: 2
}))

const ContentWrapper = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  zIndex: 2,
  '@media (max-width: 1280px)': {
    flexBasis: '100%'
  }
}))

const ContentCard = styled.div(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: props.getBgColor(props.page, props.theme),
  boxShadow: '-1px 0 15px 0 rgba(0,0,0,0.27)'
}))

const ContentBox = styled.div(props => ({
  width: '800px',
  padding: '2rem',
  'h1, p': {
    maxWidth: '600px',
    color: props.getTextColor(props.page, props.theme)
  },
  button: {
    marginBottom: '2rem',
    '@media (max-width: 600px)': {
      marginBottom: '1rem'
    }
  },
  h1: {
    marginBottom: '1.5rem',
    '@media (max-width: 1280px)': {
      fontSize: '2.25rem',
      marginBottom: '.8rem'
    },
    '@media (max-width: 600px)': {
      fontSize: '1.5rem',
      marginBottom: '.8rem'
    }
  },
  p: {
    marginBottom: '2rem',
    '@media (max-width: 1280px)': {
      fontSize: '.8rem',
      marginBottom: '1rem'
    },
    '@media (max-width: 320px)': {
      fontSize: '.6rem'
    }
  },
  '@media (max-width: 960px)': {
    width: '100%',
    padding: '0 4rem'
  },
  '@media (max-width: 600px)': {
    width: '100%',
    padding: '0 2.4rem'
  }
}))

export default Section
