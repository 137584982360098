import React from 'react'
import findMotorsLogo from '../../../resources/projects/Design/FindMotors/findmotors_logo.svg'
import findMotorsImage from '../../../resources/projects/Design/FindMotors/findmotors.png'
import fmCircle from '../../../resources/projects/Design/FindMotors/fm_circle.svg'
import { ClientInfo, ComputerImage, ContentWrapper, Header, Section, SubHeader, Text, Image, Link } from '../CommonComponents'
import styled from 'styled-components'
import { findMotorsContent } from '../../../resources/data'

const Circle1 = styled.img.attrs({ src: fmCircle })(props => ({
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  width: '4rem'
}))

const Circle2 = styled.img.attrs({ src: fmCircle })(props => ({
  position: 'absolute',
  top: '2rem',
  left: '15rem',
  width: '18%',
  transform: 'rotate(90deg)'
}))

const Circle3 = styled.img.attrs({ src: fmCircle })(props => ({
  position: 'absolute',
  top: '1rem',
  right: '2rem',
  width: '4rem',
  transform: 'rotate(-90deg)'
}))

const FindMotorsLogo = styled.img(props => ({
  marginBottom: '1.5rem',
  position: 'relative',
  zIndex: '10'
}))

const WhiteText = styled.h1(props => ({
  color: props.theme.colorWhite,
  fontFamily: 'poppins_semibold',
  fontSize: '2rem',
  position: 'relative',
  zIndex: '10'
}))

const BlueText = styled.h1(props => ({
  color: props.theme.colorFindMotorsBlue,
  fontFamily: 'poppins_semibold',
  fontSize: '2rem',
  position: 'relative',
  zIndex: '10'
}))

export const FindMotors = () => {
  const isWide = window.matchMedia('(min-width: 960px)').matches

  return (
    <div>
      <Header>{findMotorsContent.header}</Header>
      <Section backgroundColor={'#343d47'}>
        <ClientInfo>
          {isWide && <Circle1/>}
          {isWide && <Circle2/>}
          {isWide && <Circle3/>}
          <FindMotorsLogo src={findMotorsLogo} alt={'Find motors logo'}/>
          <WhiteText>Revolutionary</WhiteText>
          <BlueText>vehicle hub</BlueText>
        </ClientInfo>
        <ComputerImage src={findMotorsImage} alt={'Find motors view'}/>
      </Section>
      <ContentWrapper>
        {findMotorsContent.sections.map(section =>
            <React.Fragment key={section.header}>
              <SubHeader>{section.header}</SubHeader>
              <Text>{section.text}</Text>
              {section.hasOwnProperty('image') &&
              <Image src={section.image} alt={section.image} isShadow/>}
              {section.hasOwnProperty('link') &&
              <Link href={section.link.href}>{section.link.text}</Link>}
            </React.Fragment>
        )}
      </ContentWrapper>
    </div>
  )
}
