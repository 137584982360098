import React from 'react'
import styled from 'styled-components'

export const PointsList = props => {
  const { content, page, subHeading } = props

  return (
    <PointsWrapper>
      <SubHeading page={page}>{content[subHeading]}</SubHeading>
      <PointsTextList>
        {content.activities.map(item => (
          <ListItem key={item.activity}>
            <BlackRect />
            <h4>{item.activity}</h4>
          </ListItem>
        ))}
      </PointsTextList>
    </PointsWrapper>
  )
}

const PointsWrapper = styled.div(props => ({
  marginBottom: '2rem',
  '@media (max-width: 1280px)': {
    marginBottom: '.5rem',
  },
}))

const PointsTextList = styled.div(props => ({
  display: 'flex',
  flexFlow: 'row wrap',
}))

const SubHeading = styled.h3(props => ({
  color:
    props.page === '/work/design' || props.page === '/work/erp'
      ? props.theme.colorMineShift
      : props.theme.colorFireEngineRed,
  paddingBottom: '2rem',
  '@media (max-width: 1280px)': {
    padding: '.8rem 0',
    fontSize: '1.25rem',
  },
  '@media (max-width: 600px)': {
    padding: '.5rem 0',
    fontSize: '1rem',
  },
}))

const ListItem = styled.div(props => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'stretch',
  flexFlow: 'row wrap',
  marginRight: '.7rem',
  h4: {
    paddingLeft: '.5rem',
    lineHeight: 1.4,
    '@media (max-width: 1280px)': {
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    '@media (max-width: 600px)': {
      fontSize: '.8rem',
      lineHeight: 1.2,
    },
  },
}))

const BlackRect = styled.div(props => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '-4px',
  width: '4px',
  height: '4px',
  backgroundColor: '#000',
  '@media (max-width: 600px)': {
    top: '40%',
  },
}))
