import { Projects as DesignProjects } from '../components/Projects/Design/index'
import { Projects as ErpProjects } from '../components/Projects/ERP/index'
import personalOnlineGarageImage from './projects/Design/FindMotors/personal_online_garage.png'
import vehicleMarketImage from './projects/Design/FindMotors/vehicle_market.png'
import serviceStationImage from './projects/Design/FindMotors/local_service_stations.png'
import monumentsImage from './projects/Design/Muinas/malestised.png'
import proposalImage from './projects/Design/Muinas/malestise_ettepanek.png'
import procedureImage from './projects/Design/Muinas/menetlused.png'
import commentImage from './projects/Design/Muinas/kommentaarid.png'
import vehiclesAndTicketsImage from './projects/Design/Teetasu/soidukid_ja_piletid.png'
import ticketSelectionImage from './projects/Design/Teetasu/piletite_valimine.png'
import shoppingCartImage from './projects/Design/Teetasu/ostukorv.png'
import {
  CloudIcon,
  ErpCircle,
  IncreaseIcon,
  MonitorIcon,
  PresentationIcon,
  SignalIcon,
  SyncIcon,
  ToolsIcon
} from '../assets'

export const homePageContent = {
  title: 'Info technology services from 0 to 100',
  team: 'Our heroes can save you from the trouble of getting your technological needs from different vendors',
  menu: {
    teamText: 'Do you want to see what our heroes ideology is and how it is to work beside them and potentially become one?',
    workText: 'Do you want to see what our heroes have created in the different fields of it?'
  },
  footer: {
    title: 'Meet us and feel free to say <span>hello</span>',
    description:
        'or send us an <span>e-mail</span>, we\'d love to hear from you',
    contacts: [
      {
        title: 'Toompuiestee 33a, tallinn, 10149',
        path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
        button: 'calculate route'
      },
      {
        title: 'Zalgirio g. 94, Vilnius, 08216',
        path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
        button: 'calculate route'
      },
      {
        title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
        path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
        button: 'calculate route'
      },
      {
        title: 'info@avalanchelabs.com',
        path: 'mailto:info@avalanchelabs.com',
        button: 'drop us a line'
      },
      {
        title: 'Available jobs',
        path: 'https://apply.workable.com/avalanche-labs/',
        button: 'choose your challenge'
      }
    ]
  }
}

export const teamPageContent = {
  title: 'Our international team',
  description: 'We are currently situated in three countries - Estonia, Latvia and Lithuania. Our team is divided into 6 divisions that specialize in different areas in IT. Divisions are working together to form smaller teams to be able to successfully complete every mission handed to us.',
  cards: [
    {
      title: 'Banking',
      content: 'The analyst who takes banking systems to the next level',
      link: 'click for more',
      hero: 'BanKhar',
      path: '/team/banking'
    },
    {
      title: 'Data warehouse',
      content: 'The data giant who only makes data-driven decisions',
      link: 'click for more',
      hero: 'I4C',
      path: '/team/data-warehouse'
    },
    {
      title: 'UX/UI Design',
      content: 'The designer who is a problem solver',
      link: 'click for more',
      hero: 'Furia',
      path: '/team/design'
    },
    {
      title: 'IT support',
      content: 'The operative who maintains technological structures',
      link: 'click for more',
      hero: 'Ophos',
      path: '/team/it-operations'
    },
    {
      title: 'Software development',
      content: 'The development mastermind who is always on its feet',
      link: 'click for more',
      hero: 'Octorion',
      path: '/team/software-development'
    },
    {
      title: 'ERP',
      content: 'The guide who’ll keep you safe through digital transformation',
      link: 'click for more',
      hero: 'Digmentha',
      path: '/team/erp'
    }
  ],
  about: 'Created by Mr A',
  firstScreen: {
    title: 'Currently headquartered in the Baltic States',
    description: 'This is just the start. We move like an avalanche, or as said, the snowball effect. The world is big and even the limits of Earth will not stop us. There is a huge undiscovered galaxy around us. Next time we may be on Mars, so stay tuned with our conquers.',
    locations: ['Tallinn', 'Riga', 'Vilnius'],
    message: 'Where next? Stay tuned'
  },
  secondScreen: {
    title: 'We have fun together',
    firstDescription: 'Costume parties, spring days, summer days, autumn hike and big christmas party!',
    secondDescription: 'Oh yeah, and not to mention we celebrate birthdays every month, go to cinema together, play boardgames and participate in any sporting tournament that an employee takes initiative in.'
  },
  thirdScreen: {
    title: 'Do you want to know more about us and work here?',
    description: 'Drop us a line below'
  },
  footer: {
    title: 'Interested in our team?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'info@avalanchelabs.com',
          path: 'mailto:info@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const teamPageDesignContent = {
  title: '<span>Creativity</span> is a way to solve problems',
  name: 'Furia',
  about: 'The <i>designer</i> who is a problem solver',
  icons: [
    {
      name: 'Web and App solutions'
    },
    {
      name: 'Identity'
    },
    {
      name: 'UX analysis'
    },
    {
      name: 'UI design'
    },
    {
      name: 'Your fantasy'
    }
  ],
  sections: [
    {
      title: 'Ability',
      subTitle: 'Rebellious solutioner',
      paragraphFirst:
          'Despite her young age and lack of long term experience, Furia is a force to be reckoned with. Do not let her small and rebellious looks fool you because she might be the key solution to a next big problem.',
      paragraphSecond:
          'Furia forms a bubble around her so she can combine given information into a visual form. She needs this creative space to produce the wanted solution.'
    },
    {
      title: 'Mission',
      subTitle: 'Eliminate obstacles',
      paragraphFirst:
          'Furias mission is to crack easy and difficult everyday puzzles to make her and others lives easier. She basically has a never ending goal to make the experience of anything as smooth, intuitive as possible and at the same time, be calming to the eye.',
      paragraphSecond:
          'Eliminating obsticles on the way is her way to achieve the ultimate bliss of experience and she waits for others to follow her.'
    },
    {
      title: 'Race',
      subTitle: 'Human',
      paragraphFirst:
          'The humans are humanoid lifeforms living on Earth. Other species see them weak, because humans do not shine in anything special, being a jack of all trades. But that has been wrong for them in many cases.',
      subTitleSecond: 'Story',
      paragraphSecond:
          'After her parents death in a tragic accident caused by the undiscovered race, The Lechors, Furia was taken with them and they raised her. The Lechors taught her their own ways of life by showing her the wonderful world in the bubble. Now she is back on Earth.'
    }
  ],
  footer: {
    title: 'Interested in our design team?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'tuuli.trei@avalanchelabs.com',
          path: 'mailto:tuuli.trei@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const teamPageBankingContent = {
  title: 'Comprehensive partnership for <span>financial institutions</span>',
  name: 'Ban Khar',
  about: 'The<i> analyst</i> who takes banking systems to the next level',
  icons: [
    {
      name: 'Regulatory Reporting'
    },
    {
      name: 'Business Analysis'
    },
    {
      name: 'Cost-Effiency'
    },
    {
      name: 'Banking and Contracts Migration'
    }
  ],
  sections: [
    {
      title: 'Ability',
      subTitle: 'Insane profit master',
      paragraphFirst:
          'With the ability to adapt to any environment and crawl into the tightest places, Ban Khar can access any important information that is needed and with hard-trained ability to sense everything analytically, generate the best solution.',
      paragraphSecond:
          'Ban can make himself invisible by camouflaging himself to the color of the environment.'
    },
    {
      title: 'Mission',
      subTitle: 'Future of banking',
      paragraphFirst:
          'Ban Khars mission is to advance and secure banking systems so the people of the world will feel safe with their finances.',
      paragraphSecond:
          'Capability to do greatly enhanced analysis has led Ban to see the future of banking very clearly, with big trends being the mobilization of almost everything and the outburst of artificial intelligence.'
    },
    {
      title: 'Race',
      subTitle: 'Reptilian',
      paragraphFirst:
          'The reptilians are humanoid species with the characteristics of reptiles. Even if they look scary, the reptile is actually a very altruistic species that only wants to give back to the society and mostly in the financial area. They are a unique sight on Earth.',
      subTitleSecond: 'Story',
      paragraphSecond:
          'Ban Khar was born in the dangerous swamps of Lumeria. It is said that no species other than reptilions can survive there. Reptilions use it as a secret elite training camp for future leaders, as was Ban supposed to be. Ban was trained hard as they saw big potential in it, but as soon as hitting adulthood, Ban left Lumeria. It is still unclear why it left, as Ban always avoids the topic.'
    }
  ],
  footer: {
    title: 'Interested in our banking team?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'rain.kirjanen@avalanchelabs.com',
          path: 'mailto:rain.kirjanen@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const teamPageITOperationsContent = {
  title: 'IT operations <span>is something we take seriously</span>',
  name: 'Ophos',
  about:
      'The <i class="white">operative</i> who maintains technological structures',
  icons: [
    {
      name: 'Network and Hardware Solutions'
    },
    {
      name: 'Web Hosting'
    },
    {
      name: 'IT Support'
    },
    {
      name: '24/7 Helpdesk and Monitoring'
    }
  ],
  sections: [
    {
      title: 'Ability',
      subTitle: 'Underground protection',
      paragraphFirst:
          'As the leader of the pack of vermins, he has an army he can assemble when needed. They live underground and are ready to do anything for their leader, even act as a front shield, if needed.',
      paragraphSecond:
          'Being highly advanced in technology, Ophos has an arsenal of unique gadgets to help him or his pack to make things happen.'
    },
    {
      title: 'Mission',
      subTitle: 'Getting the job done',
      paragraphFirst:
          'Ophos’ mission is to simply get the job done. Being quick, accurate and many in numbers, Ophos and his pack can do wonders.',
      paragraphSecond:
          'He takes his words seriously and makes sacrifices to keep his word. He does things his way because he knows that he is the best in his field. You can count on Ophos, he will get it done!'
    },
    {
      title: 'Race',
      subTitle: 'Vermin',
      paragraphFirst:
          'The vermins have the ability to multiply and act as a front shield. They grow very fast in numbers and that helps distract the enemies from entering and attacking the most valuable spots.',
      subTitleSecond: 'Story',
      paragraphSecond:
          'Ophos’ life has been all about competing. He had to fight within his family, with brothers and sisters to the hundrets, to be the potential one. While his family was in the lower class, he had to fight even harder to get a spot in the NVA (National Vermin Academy). And then after finishing the NVA, getting his own unit. But still, he is not in peace.'
    }
  ],
  footer: {
    title: 'Interested in our it operations team?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'rando-juku.sirkas@avalanchelabs.com',
          path: 'mailto:rando-juku.sirkas@avalanchelabs.com',
          button: 'drop us a line'
        },
        // {
        //   title: '+372 56667231',
        //   path: 'callto:+37256667231',
        //   button: 'give us a call'
        // }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const teamPageDWHContent = {
  title: '<span>Everything is possible with</span> solid data',
  name: 'I4C',
  about:
      'The <i class="white">data giant</i> who only makes data-driven decisions',
  icons: [
    {
      name: 'Business intelligence',
    },
    {
      name: 'Data warehouse product',
    },
  ],
  sections: [
    {
      title: 'Ability',
      subTitle:
          'Analysing enormous amounts of data in the speed of light',
      paragraphFirst:
          'I4C is collecting information literally all the time from anywhere and anything with its highly developed scanning apparatus. To storage the enormous amounts of data, it has multiple brain-like globes.',
      paragraphSecond:
          'I4C changes and uses the globes according to analysing needs. Uniting the required data it can analyse the wanted outcome in milliseconds.',
    },
    {
      title: 'Mission',
      subTitle: 'With great data comes great responsibility',
      paragraphFirst:
          'I4C’s mission is to gather huge amounts of data and use that to analyse in ways which lead to aiding the good. All of it is useful for I4C, because you will never know what connections you may find from unexpected sources.',
      paragraphSecond:
          'The cyborg knows to use it responsibly, because harm can be caused when the right information falls into the wrong hands.',
    },
    {
      title: 'Race',
      subTitle: 'Cyborg',
      paragraphFirst:
          'Cyborgs are mainly built by and the companions of Datraxians. Datraxians do not have a home planet, so they live on starships, with some of them living on other planets. There are Cyborgs of many kind, from trashbots to military aids.',
      subTitleSecond: 'Story',
      paragraphSecond:
          'I4C was built with the idea to help speed up technological development for Earth, and its inhabitants, the Humans. I4C was doing well with the task, until it was kidnapped by an evil organization. That organization tried to use I4C’s abilities for world conquer. That never happened though, because I4C was able to analyse the organizations destruction and get its own freedom.',
    },
  ],
  footer: {
    title: 'Interested in our Data warehouse team?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route',
        },
        {
          title: 'igors.stankunovs@avalanchelabs.com',
          path: 'mailto:igors.stankunovs@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route',
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route',
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const teamPageERPContent = {
  title: '<span>Transformation</span> is a require for <span>success</span>',
  name: 'Digmentha',
  about:
      'The <i class="white">guide</i> who’ll keep you safe through digital transformation',
  icons: [
    {
      name: 'Digital transformation'
    },
    {
      name: 'Enterprise resource planning'
    }
  ],
  sections: [
    {
      title: 'Ability',
      subTitle: 'Knowledge exchange',
      paragraphFirst:
          'With the boundless knowledge of the Etherians, Digmentha has the ability to avoid the mistakes that are made in the past.',
      paragraphSecond:
          'Her insights are so sophisticated that on Earth it seems like she can even foresee the future.'
    },
    {
      title: 'Mission',
      subTitle: 'Transformation',
      paragraphFirst:
          'Digmentha\'s mission is to transfer as much knowledge to less developed worlds as possible and to shape their technologies to a whole new level.',
      paragraphSecond:
          'She has a big burden to carry on her shoulders, as she suspects she is the last person to hold and share this ancient knowledge.'
    },
    {
      title: 'Race',
      subTitle: 'Etherian',
      paragraphFirst:
          'The Etherians are an ancient and most intelligent race in the universe. The race is suspected to become extinct. Nothing has been heard of them in centuries.',
      subTitleSecond: 'Story',
      paragraphSecond:
          'Digmenthia is the last known survivor of the ancient Etherians race. She was sent to Earth on a spaceship when she was just a baby. Digmenthia was raised by ship’s board computer called  “They” and for a very long time she didn’t leave the ship.'
    }
  ],
  footer: {
    title: 'Interested in our digital transformation team?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'digitransformation@avalanchelabs.com',
          path: 'mailto:digitransformation@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const teamPageDevelopmentContent = {
  title: '<span>Creating</span> solutions is what we are <span>best</span> at',
  name: 'Octorion',
  about: 'The <i>development mastermind</i> who is always on its feet',
  icons: [
    {
      name: 'Software development'
    },
    {
      name: 'IT architecture'
    },
    {
      name: 'Business / System analysis'
    },
    {
      name: 'Information systems'
    },
    {
      name: 'Web and App solutions'
    },
    {
      name: 'Public procurements'
    }
  ],
  sections: [
    {
      title: 'Ability',
      subTitle: 'Vigurous adaptability',
      paragraphFirst:
          'Octorion is big and not that fast, but here’s where its minions come to play. As a parent it can produce as many minions as needed and implement them wherever they are needed.',
      paragraphSecond:
          'Every minion has great adaptability, learning power and are linked together in a huge network together with Octorion. They can be trusted with the most complex missions.'
    },
    {
      title: 'Mission',
      subTitle: 'Grow and learn',
      paragraphFirst:
          'Octorion\'s mission is to be the best version of itself and use that to aid those in need. It will never stop growing, learning, adapting, because it was made with that mindset.',
      paragraphSecond:
          'With every successfully completed mission, it physically expands even bigger and the more missions it completes the more minions it can produce.'
    },
    {
      title: 'Race',
      subTitle: 'Underwater mutant',
      paragraphFirst:
          'Mutants are man-made scientific creatures with effects of mutation. The mutation can be physical or mental, with some having unique helpful abilities. A few mutants are known to be in existence, as they are still an experiment.',
      subTitleSecond: 'Story',
      paragraphSecond:
          'Octorion was born in an underwater laboratory on Earth, created by a marine scientist who was sent there by Mr. A. The whole purpose was to create the most powerful tool which would take over the software development world.'
    }
  ],
  footer: {
    title: 'Interested in our software development team?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'igors.stankunovs@avalanchelabs.com',
          path: 'mailto:igors.stankunovs@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const workPageContent = {
  title: 'Our work from around the globe',
  description:
      'Does not matter where you are situated at - we can reach you no matter what and provide the service you need.',
  cards: [
    {
      title: 'Banking',
      content:
          'We manage projects and products in financial landscape. Also very strong in business development, business analysis and system analysis',
      link: 'click for more',
      hero: 'BanKhar',
      path: '/work/banking'
    },
    {
      title: 'Data warehouse',
      content:
          'If there is a BI strategy or any business development, analysis or system analysis you need, we are here for you',
      link: 'click for more',
      hero: 'I4C',
      path: '/work/data-warehouse'
    },
    {
      title: 'UX/UI Design',
      content:
          'We provide creative and design solutions to your any need. Biggest focus on UX/UI but can create a brand from scratch any day',
      link: 'click for more',
      hero: 'Furia',
      path: '/work/design'
    },
    {
      title: 'IT support',
      content:
          'Full cycle support operations for our customers to provide better services',
      link: 'click for more',
      hero: 'Ophos',
      path: '/work/it-operations'
    },
    {
      title: 'Software development',
      content:
          'We are strong in many development languages and can help you with any problem starting from legacy systems ending to developing a new project from scratch',
      link: 'click for more',
      hero: 'Octorion',
      path: '/work/software-development'
    },
    {
      title: 'ERP',
      content:
          'We do complete enterprise digital transformation solutions, audits and strategy',
      link: 'click for more',
      hero: 'Digmentha',
      path: '/work/erp'
    }
  ],
  about: {
    title: 'Our minds are set to deliver',
    description:
        'We are not called heroes without a reason. We go through intense brain training and durability testing. Our heroes take every task very seriously and because of that they can accomplish more than an ordinary person in every mission they face.'
  },
  neverGiveUp: {
    title: 'The Avalanchers never give up',
    description:
        'People count on the Avalanchers, because they know that we don’t give up, we fight to the end. Doesn’t matter how south it may go or how hard it may be, we stay stiff and make sure that our missions are always completed.'
  },
  power: 'The Avalanchers never give up',
  team: {
    title: 'Team assemble',
    description: 'Mr. A is the backbone of every mission. He is a mastermind in strategically thinking and builds tactics which are beyond the skill levels of even the greatest leaders. He knows how to assemble the winning team from different divisions and leads them to certain success.'
  },
  footer: {
    title: 'Interested in our work?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'info@avalanchelabs.com',
          path: 'mailto:info@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const workPageDevelopmentContent = {
  title: 'Why customers value our <span>software development</span> competence',
  titleMobile: 'Software development',
  points: [
    {
      text: 'We are fast learners and adaptive to customers needs'
    },
    {
      text: 'Big masterminds of creating whatever software that is needed'
    },
    {
      text: 'Wide range of development expertise'
    }
  ],
  sections: [
    {
      title: 'Technologies',
      heading: 'We say yes to your development needs',
      paragraph:
          'We react fast to your inquires and deal with them in most comprehensive manner – if we don’t have something, we get it, if we don’t know something, we learn it. Our technology stack is growing with every single day and we are proud to meet as many customer expectations as possible.',
      firstHeadingLine: 'Our main to go teams',
      languages: [
        { name: 'Golang team' },
        { name: 'Ruby team' },
        { name: 'Javascript team' },
        { name: 'Java team' },
        { name: 'PHP team' }
      ]
    },
    {
      title: 'Infosystems',
      heading: 'Building complex infosystems',
      paragraph:
          'Building complex infosystems by creating their architecture, conducting business and system analysis, writing code and testing their readiness for happy customer usage. It covers a wide variety of options: big infosystems, integration solutions,  microservice based architecture.',
      firstHeadingLine: 'Technologies used',
      secondHeadingLine: 'Happy customers'
    },
    {
      title: 'Legacy systems',
      heading: 'Supporting legacy systems',
      paragraph:
          'Our customers also have existing solutions that need expanding and refactoring to extend their operating life, add new functionalities, arrange for better usage, maintain good usability until new systems are put into live etc. – we understand it is of great value to our customers and building a new solution is not always an option and/or makes things better.',
      firstHeadingLine: 'Technologies used',
      secondHeadingLine: 'Happy customers'
    },
    {
      title: 'Addition',
      heading: 'We can be an addition to your it department',
      paragraph:
          'Whenever our customers are lacking a team member with a specific skill or a fully equipped team to take on a concrete project – we got it covered. For a short period or long-term, ASAP or planned ahead, full-time or part-time – all options are available. Your goals are our goals!',
      firstHeadingLine: 'Happy partners'
    }
  ],
  footer: {
    title: 'Interested in our work?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'juhan.uus@avalanchelabs.com',
          path: 'mailto:juhan.uus@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const workPageDesignContent = {
  title: 'Why customers value our <span>design</span> competence',
  titleMobile: 'Design',
  points: [
    {
      text: 'Ability to create design that is pleasing to the eye'
    },
    {
      text: 'Concentrated analysis to fight for end-users better life'
    },
    {
      text: 'Furia creates an extra layer to make everything more intuitive'
    }
  ],
  sections: [
    {
      title: 'Design',
      heading: 'User interface design',
      paragraph:
          'Design should not be overlooked when we are talking about web and products. Did you know that after Amazon changed to position and the color of their primary buttons, their started selling off the charts? A small change but a big leap thanks to design thinking. When website or app is well designed, it also gives a feeling that it’s more trustful and convenient to use. Every color and every position of objects are very important to the outcome. So if you have a vision and a goal - design will be extremely helpful to you.',
      firstHeadingLine: 'Main activities',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'Creating visuals for wireframes' },
        { activity: 'Clickable prototype' },
        { activity: 'User testing' },
        { activity: 'UI analysis' }
      ],
      projects: {
        title: 'Projects',
        tabs: [
          {
            title: 'Find motors',
            ProjectContent: DesignProjects.FindMotors
          },
          {
            title: 'Road Administration',
            ProjectContent: DesignProjects.RoadAdministration
          },
          {
            title: 'National Heritage Board',
            ProjectContent: DesignProjects.NationalHeritageBoard
          }
        ]
      },
    },
    {
      title: 'Wireframing',
      heading: 'Wireframing and Prototyping',
      paragraph:
          'When we talk about design - wireframing and prototyping is essential to this process. Very simplified sketches to your projects help you visualise and strategize your next steps. You get a basic feel and sense of the outcome you are about to have and will make your development process much cheaper and faster.',
      firstHeadingLine: 'Main activities',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'User research' },
        { activity: 'User flows' },
        { activity: 'UX analysis' },
        { activity: 'Wireframing' },
        { activity: 'Clickable prototype' },
        { activity: 'User testing' }
      ]
    },
    {
      title: '(Re)design',
      heading: 'E-commerce (re)design',
      paragraph:
          'Your online shop has to do so much more than a physical one - sell products without the option to touch, smell, see or communicate. Good photos and polished descriptions won’t do the work all by themselves. You also need a user friendly website, that helps the customer to basically fly through the checkout. We will design a good experience that gives better results, while being easy on the eye. Score!',
      firstHeadingLine: 'Main activities',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'Creating visuals for wireframes' },
        { activity: 'Clickable prototype' },
        { activity: 'User testing' },
        { activity: 'UI analysis' },
        { activity: 'Anaysing client feedback' }
      ]
    },
    {
      title: 'Identity',
      heading: 'Visual identity',
      paragraph:
          'People make their first impressions with first seconds they see your website, logo, leaflet or office - and you definitely want it to be good when your profits depend on it. We can offer a visual identity design that tells a story about your values, and bring together functionality with beautiful visual language.',
      firstHeadingLine: 'Main activities',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'Understanding customer needs ' },
        { activity: 'Creating mood boards' },
        { activity: 'Different concept creations' },
        { activity: 'Fine tuning final concept' }
      ]
    }
  ],
  footer: {
    title: 'Interested in our work?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'liisi.raim@avalanchelabs.com',
          path: 'mailto:liisi.raim@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const workPageITOperationsContent = {
  title:
      '<span>Why customers value our </span>it operations <span> competence</span>',
  titleMobile: 'IT Operations',
  points: [
    {
      text: 'Creating unique solutions based on clients needs'
    },
    {
      text: 'Flexible helpdesk accordance to your business hours'
    },
    {
      text: 'Solving IT related incidents as fast as possible'
    }
  ],
  sections: [
    {
      title: 'Tech Wise',
      heading: 'We help your business tech wise',
      paragraph:
          'Our supportive helpdesk keeps your work going with resolving all IT related questions and we always check that the IT equipment is up to date. If you have doubts about what tools to use, worry not - we provide the necessary software for any occasion. For guaranteed performance our server and network administrators are the background force who keep everything else running smooth.',
      firstHeadingLine: 'Services include',
      activities: [
        { activity: 'Helpdesk' },
        { activity: 'Resolving questions' },
        { activity: 'Software licences' },
        { activity: 'Network administration' }
      ]
    },
    {
      title: 'Hardware',
      heading: 'Buy or rent it hardware',
      paragraph:
          'Our experienced specialists will help choose the right hardware for your company. From keyboards to servers we can help you determine your actual hardware needs.  We help you create the best video and audio solutions for conference rooms. Depending on the company’s policy we can offer hardware for rent or to buy.',
      firstHeadingLine: 'Most popular packages',
      activities: [
        { activity: 'IT support for office workstations' },
        { activity: 'Server administration' },
        { activity: 'IT manager service' },
        { activity: '24/7 helpdesk' },
        { activity: '24/7 office network monitoring' }
      ],
    },
    {
      title: 'Servers',
      heading: 'Network & hardware solution/server & cloud application',
      paragraph:
          'As we know every company is different and has their own vision when it comes to servers and cloud applications. We determine the customer’s needs and offer the most optimal solution. So that is why we consult with you individually to provide what is most suitable to your needs.',
      firstHeadingLine: 'Solutions',
      activities: [
        { activity: 'Virtual servers' },
        { activity: 'Dedicated server' },
        { activity: 'Web hosting' },
        { activity: 'E-mail hosting' },
        { activity: 'VPN' },
        { activity: 'Mac cloud server' },
        { activity: 'Backup solutions' }
      ],
      secondHeadingLine: 'Happy customers'
    },
    {
      title: 'Monitoring',
      heading: 'Monitoring 24/7',
      paragraph:
          'Our client’s business is very important to us, we monitor all the IT systems possible and we do it 24/7. Hardware, services, network traffic etc. This way we can prevent any incidents and solve them before they can impact our client’s business. This feature keeps systems up and running.'
    },
    {
      title: 'Software',
      heading: 'Office software',
      paragraph:
          'Nowadays office software means not only Microsoft Office products, but whole package of applications that can make work easier and more productive. Our software selection covers all the company needs:',
      paragraphList: [
        {
          text:
              'Intranet solution is completely customizable. From simple day to day information sharing platform to fully functional system that covers all the company’s needs.'
        },
        {
          text:
              'Cloud solution, which works very easily and is available for all operating systems.'
        },
        { text: 'Selection of all the popular antivirus software.' },
        { text: 'Traditional Microsoft Office programs.' }
      ],
      paragraphSecond:
          'All these services can be integrated with each other to create a fully working office solution, which makes daily tasks much more effective.',
      firstHeadingLine: 'Main solutions',
      activities: [
        { activity: 'Intranet' },
        { activity: 'Cloud solutions' },
        { activity: 'Microsoft office programs' },
        { activity: 'Antivirus software' }
      ]
    }
  ],
  footer: {
    title: 'Interested in our work?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'rando-juku.sirkas@avalanchelabs.com',
          path: 'mailto:rando-juku.sirkas@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const workPageBankingContent = {
  title: 'Why customers value our <span>banking</span> competence',
  titleMobile: 'Banking',
  points: [
    {
      text: 'Combining deep domain expertise with technical know-how'
    },
    {
      text: 'Mind targeted for financial institutions'
    },
    {
      text: 'Hands-on approach'
    }
  ],
  sections: [
    {
      title: 'Reporting',
      heading: 'Regulatory reporting',
      paragraph:
          'Being part of one of the most regulated areas, we have faced and driven numerous regulations and legal changes through the years. Our business analysts and project managers are proficient in interpreting regulatory requirements into practice, negotiating legislation in Banking unions and with central banks based on the experience of Consumer Credit Directive, Mortgage Credit Directive, AnaCredit, IFRS9,16, AQR, Pillar 3, Basel III and Responsible Lending principles. We have practice in organizing projects with strict legal and business deadlines, prioritising minimum viable products and devising clear contingency plans.',
      firstHeadingLine: 'Main activities',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'Reporting system implementation, integration' },
        { activity: 'Regulatory reporting and requirements' },
        { activity: 'Governance, Risk and Compliance' },
        { activity: 'Capital Management – Basel II/III' },
        { activity: 'Capital Adequacy and Stress Testing (AQR)' },
        { activity: 'International Financial Reporting Standards (IFRS)' },
        { activity: 'Data Governance' }
      ]
    },
    {
      title: 'Software',
      heading: 'Banking software design and development',
      paragraph:
          'Our architects and developers will help you with the challenge you face – whether it’s migration to cloud, refactoring the current Core or building internet banking.',
      paragraphSecond:
          'Among the many business development projects, our team members have led and participated in the full digitalisation of consumer loans – from a fully paper-based process to 100% digital solution where the customer can sign a consumer loan contract via mobile in minutes, change its conditions and repay it without ever stepping foot in the bank. As a result, the vast majority of the bank’s consumer loans are possible to issue or adjust via electronic channels without intervention from bank employees.',
      firstHeadingLine: 'We help you with',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'Banking IT architecture' },
        { activity: 'Internet banking' },
        { activity: 'Core systems' },
        { activity: 'Banking process automation and digitalization' },
        { activity: 'Customer credit scoring'},
        {
          activity:
              'Precalculated credit limits for retail and corporate customers'
        }
      ]
    },
    {
      title: 'Data',
      heading: 'Banking migration and data transformation',
      paragraph:
          'The project managers, business and system analysts and developers in our Banking team have taken part in various large-scale portfolio mergers and IT carveouts (AM Credit, Hipoteku Banka, Danske Bank to Swedbank, Nordea, DNB to Luminor). They have experience with data obfuscation, alignment of customer data, time-critical data migrations without any service downtime to customers, negotiations with lawyers, financial supervisory authorities and banking unions, managing communication to customers and merging of regulatory reporting.',
      firstHeadingLine: 'Main activities',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'Banking portfolio mergers and acquisitions' },
        { activity: 'Contracts migrations' },
        { activity: 'Systems carve out, integrations' }
      ]
    },
    {
      title: 'Analysis',
      heading: 'Business analysis and product ownership',
      paragraph:
          'We have worked on migrating, carving out and replacing outdated services with new modern ones (Lending, Factoring management system, Overdue management system, Regulatory reporting), improving the stability, performance and cost-efficiency of business critical systems and closed down IT services, the functionality of which has been either rewritten to other areas and systems or which are no longer needed.',
      firstHeadingLine: 'Main activities',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'Banking IT architecture' },
        { activity: 'Banking process automation and digitalization' },
        { activity: 'Customer credit scoring' },
        {
          activity:
              'Precalculated credit limits for retail and corporate customers'
        },
        { activity: 'Banking portfolio mergers and acquisitions' },
        { activity: 'Systems carve out, integrations' }
      ]
    }
  ],
  footer: {
    title: 'Interested in our work?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'tarmo.renter@avalanchelabs.com',
          path: 'mailto:tarmo.renter@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const workPageDWHContent = {
  title:
      'Why customers value our <span>data warehouse</span> competence',
  titleMobile: 'Data Warehouse',
  points: [
    {
      text: 'We find the best solution for your custom needs',
    },
    {
      text: 'Team becomes every day partner to customer',
    },
    {
      text: 'Collection of worlds leading data warehouse platforms',
    },
  ],
  sections: [
    {
      title: 'Provider',
      heading: 'We are independent bi solutions provider',
      paragraph:
          'We strongly believe in a power of choice and by that Avalanche is technology agnostic. Every design and architecture proposal contain different options to pick from. By that Customer can align BI solution with Company strategy, existing landscape, business experience, long-term roadmap and have flexibility in pricing for implementation and operations. Without being limited by only 1 vendor and technology, Avalanche Labs delivers tailored Data Warehousing experience to fit precise Customer requirements.',
      firstHeadingLine: 'We provide consultation in following',
      secondHeadingLine: 'Technologies used',
    },
    {
      title: 'Solutions',
      heading: 'We build bi solutions',
      paragraph:
          'Amount of data produced by business today is growing exponentially. Not utilizing that in a proper way creates situation, when decisions are made based on a gut-feeling or being completely blind. We help to understand company analytical needs, find and cleanse required data, consolidate it and provide reporting solutions to find hidden knowledge in data. Solution is just a supporting tool to make decisions based on past experience, to visualize trends and to get insight, what might happen in future. By automation of data gathering, reports creation time drastically shortens. Employees involved in analysis can really do analytical work, not to focus on manual data collecting form different sources.',
      firstHeadingLine: 'Happy customers',
    },
    {
      title: 'Strategies',
      heading: 'We help develop bi strategies',
      paragraph:
          'Orientation in BI world is a complex task, because of variety of tools and approaches. By doing Company health-check and BI maturity control, we understand what is a current state and propose several options, to meet desired data-driven Business model. Strategy describes what technologies are most efficient, suggest hardware setup, timeline for a full solution implementation, what team is needed to deliver. Strategy is self-sufficient document, by using that Customer can order solution form any vendor and receive best result.',
      firstHeadingLine: 'Main activities/outputs',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'As is analysis' },
        { activity: 'To be alternatives' },
        { activity: 'BI roadmap' },
        { activity: 'Tech stack alternatives' },
        { activity: 'Business process optimation' },
      ],
    },
    {
      title: 'Product',
      heading: 'We have a data warehouse product for small companies',
      paragraph:
          'Small companies cannot to purchase and don’t need heavy duty DWH solution to make data-driven decisions. We have predefined data models, data entry forms and reporting to support majority of a company needs without implementing solution form a scratch. Only thing needed is to integrate operational systems, load data and enjoy automatically refreshing reports given insight on critical business data. There is no need to do analysis in Excel to combine data from different sources, for example accounting and sales systems.',
    },
    {
      title: 'Future',
      heading: 'Data science is the future',
      paragraph:
          'Data science incorporates tools from multiple disciplines to gather a data set, process, and derive insights from the data set, extract meaningful data from the set, and interpret it for decision-making purposes. The disciplinary areas that make up the data science field include mining, statistics, machine learning, analytics, and programming.',
      paragraphSecond:
          'Companies are applying data science to everyday activities to bring value to consumers.',
      paragraphThird:
          'Several projects at Avalanche are also connected to data science. For example, there was a project of predicting hourly consumption for particular customers. As an initial data there were used two-year’s hourly consumption data and hourly temperature data from five main weather stations across Estonia. The target was to predict hourly consumption. During the analysis all the customers were split into five groups according to closest weather station to enable temperature fluctuations be minimal. A linear regression was used to create a prediction model for last year (with known data). Temperature data was separated into months in order to consider seasonality. At the end the model was tested with fresh data. Currently the model is at user acceptance phase and waiting to be deployed into production.',
    },
  ],
  footer: {
    title: 'Interested in our work?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route',
        },
        {
          title: 'igors.stankunovs@avalanchelabs.com',
          path: 'mailto:igors.stankunovs@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route',
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route',
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  },
}

export const workPageERPContent = {
  title:
      'Why customers value our <span>digital transformation & erp</span> competence',
  titleMobile: 'ERP',
  points: [
    {
      text:
          'Our religion is technology agnosticism and IT benefits our customers'
    },
    {
      text:
          'We create business and technology symbiosis throughout digital transformation'
    },
    {
      text: 'We are reliable and knowledgeable'
    }
  ],
  sections: [
    {
      title: 'Approach',
      heading: 'Agnostic approach to erp implementation',
      paragraph:
          'Our ERP team has spent time researching ERP future, new technology trends and ERP constraints. According to research results we’ve chosen technology agnostic approach as our main ERP strategy.',
      paragraphSecond:
      // 'Growing variety of solutions from different vendors allow us to find and implement ERP that will fit business like a glove. It will also serve as technological enabler to future business needs and support your company growth. This is why provide multiple solutions from different ERP vendors.',
          'Growing variety of solutions from different vendors allow us to find and implement ERP that will fit business like a glove. It will also serve as technological enabler to future business needs and support your company growth.',
      firstHeadingLine: 'Our expertise',
      secondHeadingLine: 'Our competence',
      activities: [
        { activity: 'Independent ERP consultant' },
        // { activity: 'Solution provider for Oracle netsuite' },
        { activity: 'Official Microsoft partner' },
        // { activity: 'Dynamics 365 business central and f&0' },
        { activity: 'Dynamics 365 business central' },
        // { activity: 'SAP business by design' },
        // { activity: 'SAP' }
      ],
      projects: {
        title: 'Services',
        tabs: [
          {
            title: 'Microsoft dynamics 365',
            ProjectContent: ErpProjects.MicrosoftDynamics
          }
        ]
      },
    },
    {
      title: 'Strategy',
      heading: 'Digitransformation audit and IT strategy',
      paragraph:
          'We help our customer to place technology, business and people onto one picture. We find technology enablers they need. Understanding the future is the key of digital transformation process. Avalanche team has created methodology for digital diagnostics.  We diagnose current situation, define possibilities and find technological enablers. We also help to calculate technology budgets, measure ROI and finalize decisions on digital roadmap.',
      paragraphSecond:
          'As a result of diagnostics, our customer gets all needed calculations for educated choice of digital strategy for next 5-7 years.',
      firstHeadingLine: 'Our expertise',
      secondHeadingLine: 'Happy customers',
      activities: [
        { activity: 'Digital audit & IT strategy' },
        // { activity: 'EAS grants' },
        // { activity: 'Industry 4.0' },
        // { activity: 'Advanced robotics & cobotics' },
        // { activity: 'Big data & business intelligents' },
        // { activity: 'Cognitive computing' },
        // { activity: 'IOT' },
        // { activity: '3D printing' },
        // { activity: 'Machine2Machine' },
        // { activity: 'RFID technologies' },
        // { activity: 'Cloud computing' },
        // { activity: 'Mobile technologies' }
      ],
      projects: {
        title: 'Services',
        tabs: [
          {
            title: 'Digital transformation',
            ProjectContent: ErpProjects.DigitalTransformation
          }
        ]
      },
    }
  ],
  footer: {
    title: 'Interested in our work?',
    description: 'Come by and let’s have a chat or send us an e-mail',
    contacts: [
      [
        {
          title: 'Toompuiestee 33a, Tallinn, 10149',
          path: 'https://goo.gl/maps/z1gTCtHoGByTMn7n9',
          button: 'calculate route'
        },
        {
          title: 'digitransformation@avalanchelabs.com',
          path: 'mailto:digitransformation@avalanchelabs.com',
          button: 'drop us a line'
        }
      ],
      [
        {
          title: 'Zalgirio g. 94, Vilnius, 08216',
          path: 'https://goo.gl/maps/WP8NhnCWe9KffgSh9',
          button: 'calculate route'
        },
        {
          title: 'info.lt@avalanchelabs.com',
          path: 'mailto:info.lt@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
      [
        {
          title: 'Valnu iela 5, Centra rajons, Riga, LV-1050',
          path: 'https://goo.gl/maps/XDkXKnLLwDWxpshv6',
          button: 'calculate route'
        },
        {
          title: 'info.lv@avalanchelabs.com',
          path: 'mailto:info.lv@avalanchelabs.com',
          button: 'drop us a line',
        },
      ],
    ],
  }
}

export const findMotorsContent = {
  header: 'Find motors prototype',
  sections: [
    {
      header: 'The project',
      text: 'Estonia. The users are not happy with the way the things are right now, something needed to change. So it\n' +
          '          began\n' +
          '          with a bunch of ideas on how to improve on what exists and the goal was to combine the best ideas and make it\n' +
          '          a\n' +
          '          user friendly and visually appealing clickable prototype. It grew out to be the ultimate hub for a car owner,\n' +
          '          including a personal online garage, a vehicle market and auctions, a list of local service stations, and a\n' +
          '          market for spare parts.'
    },
    {
      header: 'My part',
      text: 'I had a handful roles: I did UX Research, UX/UI Design and also created the brand, so Visual Design. I was\n' +
          '          doing the project with two shareholders, one of them had the smarts of the vehicle market world. We had weekly\n' +
          '          or more frequent meetings, where I presented what I had researched or designed and then we brainstormed on\n' +
          '          some\n' +
          '          ideas. Below are some screens.'
    },
    {
      header: 'Personal online garage',
      text: 'Manage all your vehicles from one place. Automatically add vehicles from your country’s database, add reminders and book appointments according to need. All previous actions can be seen under vehicle history.',
      image: personalOnlineGarageImage
    },
    {
      header: 'Vehicle market and auction',
      text: 'Option to sell or auction your vehicle. Advanced token-based search and filtering system, find a car based\n' +
          '          on anything.',
      image: vehicleMarketImage
    },
    {
      header: 'List of local service stations',
      text: 'Find service stations based on required services, find local ones on the map, get a quote, rate them after\n' +
          '          the service.',
      image: serviceStationImage
    },
    {
      header: 'Full case study',
      text: 'The case study continues on Behance, go and check it out here:',
      link: {
        href: 'https://www.behance.net/gallery/84439359/FindMotors-the-revolutionary-vehicle-hub',
        text: 'https://www.behance.net/gallery/84439359/FindMotors-the-revolutionary-vehicle-hub'
      }
    }
  ]
}

export const nationalHeritageBoardContent = {
  header: 'National registry of cultural monuments website rework',
  sections: [
    {
      header: 'The project',
      text: 'This is an ongoing project to fix a problem the users of the Cultural Monuments website have been having.\n' +
          '          They were complaining that it’s really hard to find the information they need and the existing e-services are\n' +
          '          unusable due to their complexity and inconvenience. The website needed a complete overhaul. So that’s what we\n' +
          '          did: we worked on missing functions, on streamline the systems and to improve the overall usability of the\n' +
          '          registry website. Also, one of the main tasks was to fully digitalize the memorial recognition\n' +
          '          procedure.'
    },
    {
      header: 'My part',
      text: 'A team of a Project Manager, a Product Owner (PO)/Business Analyst, a Product Designer (yes, me!), and\n' +
          '          three\n' +
          '          Developers, was born to fix the site, following the Scrum software development model. I started working\n' +
          '          closely\n' +
          '          with the Product Owner. She was the main link between the client and the rest of the team. We worked out a\n' +
          '          flow:\n' +
          '          the PO would give me what needs to be on the page, I would design the page . I was often creating multiple\n' +
          '          different versions – for the clients goodwill and to find the best solution with less development time. Then,\n' +
          '          the Product Owner, or sometimes me, would present the solution to the client, get feedback, based on what I’d\n' +
          '          make changes to the prototype, and so on until the client approves. Below are some sreens.'
    },
    {
      header: 'Monuments',
      text: 'Full list of the monuments. Added search for easy monument find and with saved filters it is simple to make regular searches.',
      image: monumentsImage
    },
    {
      header: 'The memorial detail view',
      text: 'The detail view was made more modern, easier to read and understand. About the monument is under the left\n' +
          '          column, everything connected and done with the monument under the right column. Map added for simple\n' +
          '          knowledge.',
      image: proposalImage
    },
    {
      header: 'The Procedures',
      text: 'All procedures connected to a monument are now fully digitalised.',
      image: procedureImage
    },
    {
      header: 'Comments',
      text: 'One of the added features was the possibility to comment on every memorial procedure step by the officials.',
      image: commentImage
    }
  ]
}


export const roadAdministrationContent = {
  header: 'Road toll self-service portal',
  sections: [
    {
      header: 'The project',
      text: 'The problem arised that the Estonian Road Administration’s road toll website didn’t have options for\n' +
          '          managing vehicles, buying tickets in bulk and a pre-pay account. It was asked by the bigger logistics\n' +
          '          companies\n' +
          '          having many trucks to manage, and an easier way to buy tickets would make their lives easier. A solution was\n' +
          '          proposed to the problem: a self-service portal with simple vehicle management with X-Road functions, like\n' +
          '          importing user accounts from the Estonian Business Register and vehicles from the Estonian Traffic Register,\n' +
          '          and\n' +
          '          with a possibility to authorize others to buy tickets.'
    },
    {
      header: 'My part',
      text: 'I was a UX/UI Designer, part of an Agile development team, which included a Project Manager, Business\n' +
          '          Analyst and 3 Developers. I was mostly working with the analyst side-by-side to craft the most intuitive user\n' +
          '          experience. To validate the problem, we had interviews, which led to finding out that the main reason the user\n' +
          '          would use this is a pre-pay account. We made that the priority. An important part was to consultate with the\n' +
          '          developers to design in the limits of the framework that was used. Below are some screens.'
    },
    {
      header: 'Vehicles and tickets',
      text: 'A clear overview of the status of the vehicles and their tickets under one table.',
      image: vehiclesAndTicketsImage
    },
    {
      header: 'The ticket buying flow',
      text: 'The flow begins on the last screen, with choosing to which vehicles the user wants to buy tickets for.\n' +
          '          Then\n' +
          '          under here the user chooses the parameters for the tickets. They may change often.',
      image: ticketSelectionImage
    },
    {
      header: 'Cart',
      text: 'The ticket cart to have an overview what the user is about to buy. There is a possibility to get a notice\n' +
          '          on\n' +
          '          ticket status.',
      image: shoppingCartImage
    },
    {
      header: 'See it live',
      text: 'The self-service is developed and live, but you need to be an Estonian resident to log in. Go see it\n' +
          '          here:',
      link: {
        href: 'https://www.teetasu.ee',
        text: 'https://www.teetasu.ee'
      }
    }
  ]
}

export const digitalTransformationContent = {
  header: 'Digital transformation is not just a buzzword anymore but a real key to the future of business',
  sections: [
    {
      text: 'The fourth industrial revolution is driving the world in a more digital, cloud-based direction, and it is up to\n' +
          '        the businesses to either adapt and change or fall behind. Worldwide, many companies have already successfully\n' +
          '        undertaken the journey of digital transformation and implemented digital solutions that have had a huge impact\n' +
          '        on\n' +
          '        their profit margins.'
    },
    {
      text: 'One good example is Bosch company. They have combined Industrial Internet of Things and Big Data to get manufacturing processes in their Chinese factory to the next level:'
    },
    {
      illustratedText: [
        {
          text: 'Embedded sensors monitor machine condition and cycle time',
          image: MonitorIcon
        },
        {
          text: 'Real-time data processing and bottleneck alert system',
          image: SyncIcon
        },
        {
          text: 'Equipment failure prediction enables pre-emptive maintenance',
          image: ToolsIcon
        },
        {
          text: '10 % output increase and improved delivery',
          image: IncreaseIcon
        }
      ]
    },
    {
      text: 'Or Disney company that invested over a billion dollars in a RFID wristband system. And that enables Disney parks\n' +
          '        to offer their customers a better user experience and also to increase ROI with targeted marketing.'
    },
    {
      illustratedText: [
        {
          text: 'One RFID wristband instead of tickets, hotel keys, credit cards, etc.',
          image: SignalIcon
        },
        {
          text: 'Real-time data collection and analysis enables personalised experience',
          image: CloudIcon
        },
        {
          text: 'Efficiency and process improvements, target marketing',
          image: PresentationIcon
        },
        {
          text: '20 % increase in profits by the end of that year',
          image: IncreaseIcon
        }
      ]
    },
    {
      text: 'Businesses in Estonia, however, are not keeping up with the global digital developments. In the annual DESI\n' +
          '        index\n' +
          '        from all European countries Estonia is ranked only 16th on integration of digital technology in business. The\n' +
          '        gap\n' +
          '        is even deeper in the manufacturing industry where the value-added productivity per employee is only 54 % of the\n' +
          '        EU average, meaning that a business is in general producing less with the same number of employees as an EU\n' +
          '        average business.'
    },
    {
      text: 'Superheroes as we are, we would never agree with an unfortunate situation like this. Avalanche digital transformation team has one mission and one mission only: we use digital transformation to help companies’ success and growth. \n' +
          'Every digital transformation starts with a vision, a clear strategy of where you want your business to be in the upcoming years. We are willing to take that vision and turn it into an even better reality. We understand that each company faces a set of unique challenges that require personalised digital solutions, and through a strong collaboration between our team of digital transformation experts and your company, we can identify and implement the best solution.'
    },
    {
      text: 'First step to a successful digital transformation is a digital diagnostic. Avalanche experts from different domains of knowledge will hold meetings with key persons of your company over the course of several weeks to gain full insight into your business processes. We will identify areas of business, processes, and functions that are not yet enabled by technology. We will think what value it will produce if we fill these gaps with different technological solutions. As a result, we will work through a detailed programme of improvements and enablers, and will propose a budget for possible solutions.\n' +
          'In the last phase of the diagnostics project, we will help calculate return of investments (ROI) and together with your team we will create the digital transformation roadmap for your business. \n'
    },
    {
      text: 'When digital diagnostics is finished, your digital strategy for the next 3-5-year period will be ready. Management board will have all the needed calculations for making an educated choice in the complex domain of technology.'
    },
    {
      header: 'Opening meeting',
      list: [
        'General information about company',
        'Meeting with company key persons'
      ]
    },
    {
      header: 'Interviews',
      list: [
        'A comprehensive questionnaire based on our own methodology to map the current state of the company and find preliminary areas in need of improvement'
      ]
    },
    {
      header: 'Workshops',
      list: [
        'Discussions about the current strategy and business model',
        'Supply chain mapping',
        'Analysis of key areas in need of improvement and finding solutions'
      ]
    },
    {
      header: 'Discussions',
      list: [
        'Going through the results and proposed solutions with company key persons',
        'Quoting, budgeting, and ROI analysis for proposed solutions'
      ]
    },
    {
      header: 'Final report',
      list: [
        'A detailed overview of key improvements with estimated cost',
        'A roadmap of proposed solutions for a 3-5 year period'
      ]
    },
    {
      text: '        And now it is time to implement your strategy. You need to remember that digital transformation is not a\n' +
          '        one-time\n' +
          '        event but a constantly repeating cycle of solution strategy evaluation.'
    },
    {
      image: ErpCircle
    },
    {
      text: '        Avalanche team offers you the full support and expertise and the endless possibilities of our professionals\n' +
          '        representing various fields of IT. Whether your company needs a full-fledged digital strategy or just\n' +
          '        adjustments\n' +
          '        to your existing IT strategy, we will keep you up-to-date with the latest models and technology and always a\n' +
          '        step\n' +
          '        ahead of the competition.'
    },
    {
      text: 'To illustrate the impact digital transformation can have on your business, we present selected examples from our previous clients below.'
    },
    {
      header: 'Company of ≤ 20 workers',
      saving: {
        digitalisation: {
          method: 'Integration with client & supplier information systems, automated invoicing',
          timeSaved: 'Time spent now 15 min instead of 60 min',
          moneySaved: 'Ca 6000€/y'
        },
        automation: {
          method: 'Automating the feeding and collecting system\'s of die-cutting and creasing machine\'s',
          timeSaved: '25% saved production hours = 5 595 h/y',
          moneySaved: '44 760 €/y'
        }
      }
    },
    {
      header: 'Company of ≤ 100 workers',
      saving: {
        digitalisation: {
          method: 'SAP (or in general ERP?), EDI, MES implementations and RFID technology',
          timeSaved: '1.5 FTE (full-time equivalent) aka 1.5 worker’s wages and time saved',
          moneySaved: 'Ca 45 000 €/y'
        },
        automation: {
          method: 'Production line automation and cobot integration',
          timeSaved: '11 FTE saved',
          moneySaved: 'Ca 330 000 €/y'
        }
      }
    },
    {
      header: 'Company of ≥100 (or ≤ 400) workers',
      saving: {
        digitalisation: {
          method: 'IFS/ERP additional modules’ integration and WMS-IFS integration',
          timeSaved: '942 h/y\n' +
              '\n' +
              'Saving on paper\n' +
              '\n' +
              '2 FTE saved on WMS integration\n' +
              '\n',
          moneySaved: '9 532 €/y\n' +
              '\n' +
              '7 272 €/y\n' +
              '\n' +
              '40 884 €/y\n' +
              '\n'
        },
        automation: {
          method: 'Cobot for production, AGVs for transport',
          timeSaved: '1 600 h/y\n' +
              '\n' +
              '2 FTE\n' +
              '\n',
          moneySaved: '16 192 €/y\n' +
              '\n' +
              '40 844 €/y\n' +
              '\n'
        }
      }
    },
    {
      text: 'Send us an e-mail or come by our office and let us take care of your digital strategy. Let’s transform your future together!'
    },
    // {
    //   header: 'EAS grants for digital transformation projects',
    //   text: 'Avalanche offers help managing the financial aspects of the digital transformation. \n' +
    //     'Enterprise Estonia (EAS) has opened a grant to support the digital transformation process of local businesses to help them stay competitive on a global level. This can cover up to 70 % of the cost of digital diagnostics. Currently, the grant targets manufacturing, quarrying, and mining businesses. Grants are available to businesses with an average revenue of at least 200 000 € in the last two financial years. The maximum amount possible to be granted is divided into three groups based on the revenue of the last financial year:',
    //   list: [
    //     '200 000 € - 1 million €, max 5 000 €',
    //     '1 million € – 5 million €, max 10 000 €',
    //     'Over 5 million €, max 15 000 €'
    //   ]
    // },
    // {
    //   textWithLink:
    //     {
    //       text: 'Further information regarding the grant can be found on the EAS website',
    //       linkText: ' here',
    //       linkHref: ''
    //     }
    // },
    // {
    //   textWithLink:
    //     {
    //       text: 'NB! To be considered eligible for the grant, the tax or payment arrears, inclusive of interests, shall not exceed 100 euros, or these shall be deferred and the deferred tax arrears shall be paid in accordance to the schedule. Also, the de minimis aid granted to the applicant for three consecutive financial years, with the grant, shall not exceed 200 000 €. To check your de minimis aid balance, go',
    //       linkText: ' here or contact us!',
    //       linkHref: ''
    //     }
    // }
  ]
}

export const microsoftDynamicsContent = {
  header: 'How Microsoft Dynamics 365 Business Central saved us from going hungry',
  sections: [
    {
      text: ' At Avalanche, we have always enjoyed eating good food and many of our important team events are also\n' +
          '        accompanied by excellent food. As we take our team and gourmet experiences very seriously, we have many\n' +
          '        favourite\n' +
          '        restaurants, but one street food chain, in particular, has become especially dear to our hearts: their burgers,\n' +
          '        homemade tortillas, and oven-baked sweet potato never fail to make our mouths water!\n' +
          '        However, one day we noticed that the restaurant chain’s expansion was causing problems with both the\n' +
          '        availability\n' +
          '        of ingredients and the food quality. The restaurant ran out of burger buns, salad material as well as sweet\n' +
          '        potatoes at lunch hour on several occasions. We were disappointed, but did not despair. Why? Because we are\n' +
          '        superheroes! Instead of finding another place to eat, we rushed to the aid of the owners and together we looked\n' +
          '        closer at the issue.'
    },
    {
      header: 'Enterprise resource planning software that grows with the business',
      text: '       The heart of the problem became evident during the primary analysis: the restaurant had a variety of software\n' +
          '        responsible for different processes. At first, this solution had managed to fulfil its purpose. But as the\n' +
          '        restaurant chain continued to grow and expand, this software solution could not cope with the increasing demand\n' +
          '        whilst still maintaining the same quality of food and service. The planning of different processes had become\n' +
          '        overly complex, hindering decision making and failing to give management a complete overview of how the company\n' +
          '        was doing.\n' +
          '        Together with the management we decided that several separate software solutions were failing to fulfil their\n' +
          '        purpose anymore. They needed to be replaced and all processes regarding purchases, sales and warehouse\n' +
          '        management\n' +
          '        should reside on the same platform. Microsoft Dynamics 365 Business Central (previously Microsoft Dynamics NAV)\n' +
          '        turned out to be the correct platform for this and of course we were happy to give our favourite restaurant a\n' +
          '        helping hand!'
    },
    {
      header: 'Rich functionality that is easy to customise according to the company’s needs',
      text: ' The implementation process began with a choice – whether to use the on-premise version that is located on a\n' +
          '        local server or to prefer the cloud version and its minimal initial investment. The restaurant chain management\n' +
          '        did not have time to deal with technical issues nor wished to invest in server space and therefore chose the\n' +
          '        cloud\n' +
          '        solution. With a few clicks, the restaurant chain could adjust the disk space and RAM as needed depending on the\n' +
          '        season and business growth. Our client liked Business Central especially because it is available across various\n' +
          '        devices and is easy to interface. And its good interface allowed them to continue using some of their beloved\n' +
          '        and\n' +
          '        more specific applications, including their familiar and well-established checkout system. This also made the\n' +
          '        workers happy, because it required less retraining and the old system was extremely convenient.\n' +
          '        Next, we took a look at the module in Business Central that could be set up to meet the restaurant’s exact\n' +
          '        needs.\n' +
          '        The availability of fresh ingredients and the possibility to order them straight from the kitchen is paramount\n' +
          '        in\n' +
          '        the food industry. The role-based approach of Business Central with various built-in users responsible for\n' +
          '        specific processes met the needs of the client completely. The integration of purchase and sales modules helped\n' +
          '        solve the ingredient problem and at the same time rendered the previously used solution obsolete. From now on\n' +
          '        all\n' +
          '        invoicing could be done (electronically) in Business Central and the management of goods and prices would be\n' +
          '        simple and controlled centrally.\n' +
          '        Sustainable food preparation is the key philosophy of our client, and to avoid food wastage we implemented the\n' +
          '        warehouse management module. This helped track the best before date and ensured that all the ingredients were\n' +
          '        used\n' +
          '        at their peak freshness. It was also this freshness that made us their loyal customers in the first place.'
    },
    {
      header: 'Software that supports intelligent leadership',
      text: '        After the purchase, sales and warehouse modules were set up, we introduced our client to the Microsoft\n' +
          '        Dynamics 365 Business Central’s reporting and business analysis capability that is Power BI. This unique tool\n' +
          '        collects, organises, and visualises data and can share it with employees based on their user roles. Power BI\n' +
          '        also\n' +
          '        analyses order intake, purchases and sales and makes forecasts for upcoming time periods. For example, the\n' +
          '        kitchen\n' +
          '        crew could keep track of the number of burgers or other popular food items sold during the week and then would\n' +
          '        be\n' +
          '        able to estimate the amount of burger buns and patties required for next week thanks to the functionality of\n' +
          '        Power\n' +
          '        BI. At the same time, restaurant management could view the total sales of a particular time period, unpaid\n' +
          '        bills,\n' +
          '        or the performance of individual restaurants by day or time.\n' +
          '        Enterprise resource planning software Microsoft Dynamics 365 Business Central forms an organic whole with other\n' +
          '        members of the Microsoft family such as Word, Excel, Outlook, etc. This enables formulating quotes, invoices and\n' +
          '        purchases directly in Outlook. And if more functionality is needed, there are hundreds of applications and\n' +
          '        add-ins\n' +
          '        available for download at Microsoft Store.\n' +
          '\n' +
          '        Today, the restaurant chain is still expanding and the quality of food is the best it has ever been, management\n' +
          '        is\n' +
          '        happy, the employees are happy, and we are certainly happy about working IT solutions, especially, when they are\n' +
          '        related to delicious food experiences that save us from going hungry.'
    }
  ]
}

export const data = {
  homePageContent,
  teamPageContent,
  teamPageBankingContent,
  teamPageDesignContent,
  teamPageDevelopmentContent,
  teamPageDWHContent,
  teamPageITOperationsContent,
  teamPageERPContent,
  workPageContent,
  workPageDevelopmentContent,
  workPageDesignContent,
  workPageITOperationsContent,
  workPageBankingContent,
  workPageDWHContent,
  workPageERPContent,
  findMotorsContent,
  nationalHeritageBoardContent,
  roadAdministrationContent,
  digitalTransformationContent,
  microsoftDynamicsContent
}
