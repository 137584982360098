import React from 'react'
import styled from 'styled-components'
import {
  BanKharIntroImage,
  BanKharImage,
  BanKharAvatarImage,
  BanKharAbilityImage,
  BanKharRaceImage,
  BanKharMissionImage,
  BanKharAnalysisIcon,
  BanKharContractsIcon,
  BanKharEffiencyIcon,
  BanKharReportingIcon,
} from '../../assets'
import { Intro, About, Section, ContactMenu } from '../../layout/TeamDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const TeamBanking = props => {
  const { data } = useAppContext()

  const assets = [
    { image: BanKharAbilityImage },
    { image: BanKharMissionImage },
    { image: BanKharRaceImage },
  ]

  const icons = [
    { icon: BanKharReportingIcon },
    { icon: BanKharAnalysisIcon },
    { icon: BanKharEffiencyIcon },
    { icon: BanKharContractsIcon },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '136px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorMineShaft,
    boxShadow: '0 -3px 18px 0 rgba(0,0,0,0.31)',
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="teamBanking" />
      <Intro
        background={BanKharIntroImage}
        image={BanKharImage}
        title={data.teamPageBankingContent.title}
        page={props.location.pathname}
      >
        <BottomRectangle />
      </Intro>
      <About
        avatar={BanKharAvatarImage}
        title={data.teamPageBankingContent.name}
        description={data.teamPageBankingContent.about}
        icons={getMergedContent(data.teamPageBankingContent.icons, icons)}
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageBankingContent.sections, assets)[0]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageBankingContent.sections, assets)[1]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageBankingContent.sections, assets)[2]
        }
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.teamPageBankingContent.footer.title}
        description={data.teamPageBankingContent.footer.description}
        contacts={data.teamPageBankingContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
