import React from 'react'
import {
  ClientInfo,
  ComputerImage,
  ContentWrapper,
  Header,
  Image,
  Link,
  Section,
  SubHeader,
  Text
} from '../CommonComponents'
import roadTaxImage from '../../../resources/projects/Design/Teetasu/teetasu.png'
import logo from '../../../resources/projects/Design/Teetasu/maanteeamet_logo.svg'
import styled from 'styled-components'
import { roadAdministrationContent } from '../../../resources/data'

const Logo = styled.img(props => ({
  marginBottom: '1.5rem',
  position: 'relative',
  zIndex: '10'
}))

const Title = styled.h1(props => ({
  fontFamily: 'open_sanslight',
  fontSize: '2rem',
  position: 'relative',
  zIndex: '10'
}))


export const RoadAdministration = () => {
  return (
    <div>
      <Header>{roadAdministrationContent.header}</Header>
      <Section backgroundColor={'#FFFFFF'}>
        <ClientInfo>
          <Logo src={logo} alt={'logo'}/>
          <Title>Teetasu</Title>
          <Title>iseteenindus</Title>
        </ClientInfo>
        <ComputerImage src={roadTaxImage} alt={'Product image'}/>
      </Section>
      <ContentWrapper>
        {roadAdministrationContent.sections.map(section =>
          <React.Fragment key={section.header}>
            <SubHeader>{section.header}</SubHeader>
            <Text>{section.text}</Text>
            {section.hasOwnProperty('image') &&
            <Image src={section.image} alt={section.image} isShadow/>}
            {section.hasOwnProperty('link') &&
            <Link href={section.link.href}>{section.link.text}</Link>}
          </React.Fragment>
        )}
      </ContentWrapper>
    </div>
  )
}
