import React from 'react'
import styled from 'styled-components'
import { DigmenthaDeliverImage, WorkCastleImage } from '../../assets'
import { Slide } from '../../components'
import InfoText from '../../components/InfoText'

const Deliver = props => {
    const { title, description } = props

    return (
        <Slide>
            <Container>
                <InfoText
                    textColor={'black'}
                    title={title}
                    firstDescription={description}
                />
                <DigmenthaImage/>
                <WorkCastle/>
            </Container>
        </Slide>
    )
}

const Container = styled.div(props => ({
    position: 'relative',
    backgroundColor: props.theme.colorSolitude,
    height: 'calc(100% - 3rem)',
    paddingTop: '3rem',
    '@media (min-width: 960px)': {
        height: '100%',
        paddingTop: 0,
    }
}))

const WorkCastle = styled.div(props => ({
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: '-1px',
    backgroundImage: `url(${WorkCastleImage})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
    zIndex: 1
}))

const DigmenthaImage = styled(DigmenthaDeliverImage)(props => ({
    position: 'absolute',
    width: '100%',
    height: '513px',
    left: 0,
    right: 0,
    bottom: 0,
    margin: '0 auto',
    zIndex: 2,
    '@media (max-width: 960px)': {
        width: '60%',
        height: '60%'
    }
}))

export default Deliver
