import React from 'react'
import { SEO, ReactSwiperIdSlider } from '../../components'
import {
  Members,
  SecondScreen,
  FirstScreen,
  ThirdScreen,
  ContactMenu,
} from '../../layout/Team'
import { useAppContext } from '../../context'

export const Team = props => {
  const { data } = useAppContext()

  return (
    <ReactSwiperIdSlider>
      <SEO page="team" />
      <Members
        cards={data.teamPageContent.cards}
        title={data.teamPageContent.title}
        description={data.teamPageContent.description}
        page={props.location.pathname}
      />
      <FirstScreen title={data.teamPageContent.firstScreen.title} description={data.teamPageContent.firstScreen.description} locations={data.teamPageContent.firstScreen.locations} message={data.teamPageContent.secondScreen.message}/>
      <SecondScreen title={data.teamPageContent.secondScreen.title} firstDescription={data.teamPageContent.secondScreen.firstDescription} secondDescription={data.teamPageContent.secondScreen.secondDescription} />
      <ThirdScreen title={data.teamPageContent.thirdScreen.title} description={data.teamPageContent.thirdScreen.description}/>
      <ContactMenu
        contacts={data.teamPageContent.footer.contacts}
        title={data.teamPageContent.footer.title}
        description={data.teamPageContent.footer.description}
      />
    </ReactSwiperIdSlider>
  )
}
