import React, { useState } from 'react'
import styled from 'styled-components'
import { CloseIcon, HamburgerIcon } from '../assets'

const ProjectMenu = props => {
  const { title, tabs, activeTab, closeOverlay, isMobile } = props
  const [isHamburgerOpen, setHamburgerOpen] = useState(!isMobile)

  const navigateTo = (tab) => {
    props.navigateTo(tab)

    if (isMobile) {
      setHamburgerOpen(false)
    }
  }

  return (
    <Tabs>
      <NavHeader>
        {isMobile && <HamburgerIcon onClick={() => setHamburgerOpen(!isHamburgerOpen)}/>}
        <NavTitle>{title}</NavTitle>
        {isMobile && <CloseIconBtn onClick={closeOverlay}/>}
      </NavHeader>
      {isHamburgerOpen &&
      <TabsList>
        {tabs.map(tab => (
          <NavigationButton
            key={tab.title} onClick={() => navigateTo(tab)}
            isActive={activeTab === tab}
          >
            {tab.title}
          </NavigationButton>
        ))}
      </TabsList>
      }
    </Tabs>
  )
}

const Tabs = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    zIndex: 2,
    h2: {
      marginBottom: '1rem'
    },
    '@media(min-width: 960px)': {
      flex: '20% 0 0',
      paddingTop: '3rem',
      paddingLeft: '2rem'
    },
    '@media(max-width: 960px)': {
      boxShadow: '0px -5px 10px 10px rgba(0,0,0,0.1)'
    }
  }
))

const TabsList = styled.div(props => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  marginTop: '2rem',
  span: {
    fontFamily: 'bebas_neuebold',
    fontSize: '1.25rem',
    cursor: 'pointer'
  },
  '@media(min-width: 960px)': {
    flex: '100% 0 0'
  }
}))

const NavigationButton = styled.span(props => ({
  width: 'fit-content',
  lineHeight: 1.5,
  marginBottom: props.isActive ? 'calc(1rem - 5px)' : '1rem',
  color: props.isActive ? props.theme.colorFireEngineRed : props.theme.colorBlackPearl,
  borderBottom: props.isActive ? `5px solid ${props.theme.colorFireEngineRed}` : 0
}))

const CloseIconBtn = styled(CloseIcon)(props => ({
  display: 'block',
  position: 'absolute',
  right: '30px',
  cursor: 'pointer',
  width: '2rem',
  '@media(min-width: 960px)': {
    width: '4rem',
    marginTop: '2.5rem'
  }
}))

const NavTitle = styled.h2(props => ({
  '@media(max-width: 960px)': {
    marginBottom: '0 !important',
    marginLeft: '1rem'
  }
}))

const NavHeader = styled.div(props => ({
  display: 'flex',
  alignItems: 'center'
}))

export default ProjectMenu
