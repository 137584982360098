import React from 'react'
import styled from 'styled-components'
import {
  FuriaPageImage,
  FuriaWorkImage,
  FuriaInterfaceImage,
  FuriaWireframingImage,
  FuriaEcommerceImage,
  FuriaIdentityImage,
  OmnivaIcon,
  LuminorIcon,
  ERGovermentIcon,
  TimelessOakIcon,
  AvalancheIcon,
  SyndicateIcon,
} from '../../assets'
import { Intro, Section, ContactMenu } from '../../layout/WorkDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const WorkDesign = props => {
  const { data } = useAppContext()

  const assets = [
    {
      image: FuriaInterfaceImage,
      secondIconsLine: [
        { icon: OmnivaIcon },
        { icon: LuminorIcon },
        { icon: ERGovermentIcon },
      ],
    },
    {
      image: FuriaWireframingImage,
      secondIconsLine: [
        { icon: OmnivaIcon },
        { icon: LuminorIcon },
        { icon: ERGovermentIcon },
      ],
    },
    {
      image: FuriaEcommerceImage,
      secondIconsLine: [{ icon: TimelessOakIcon }],
    },
    {
      image: FuriaIdentityImage,
      secondIconsLine: [
        { icon: AvalancheIcon },
        { icon: TimelessOakIcon },
        { icon: SyndicateIcon },
      ],
    },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '136px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorMineShaft,
    boxShadow: '0 -3px 18px 0 rgba(0,0,0,0.31)',
    zIndex: 2,
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="workDesign" />
      <Intro
        background={FuriaWorkImage}
        image={FuriaPageImage}
        page={props.location.pathname}
        title={data.workPageDesignContent.title}
        titleMobile={data.workPageDesignContent.titleMobile}
        points={data.workPageDesignContent.points}
      >
        <BottomRectangle />
      </Intro>
      <Section
        content={
          getMergedContent(data.workPageDesignContent.sections, assets)[0]
        }
        page={props.location.pathname}
        isSectionButton={true}
      />
      <Section
        content={
          getMergedContent(data.workPageDesignContent.sections, assets)[1]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageDesignContent.sections, assets)[2]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageDesignContent.sections, assets)[3]
        }
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.workPageDesignContent.footer.title}
        description={data.workPageDesignContent.footer.description}
        contacts={data.workPageDesignContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
