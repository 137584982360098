import React from 'react'
import styled from 'styled-components'
import {
  OphosIntroImage,
  OphosImage,
  OphosAvatarImage,
  OphosAbilityImage,
  OphosRaceImage,
  OphosMissionImage,
  OphosHelpdeskIcon,
  OphosHostingIcon,
  OphosSolutionsIcon,
  OphosSupportIcon,
} from '../../assets'
import { Intro, About, Section, ContactMenu } from '../../layout/TeamDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const TeamITOperations = props => {
  const { data } = useAppContext()

  const assets = [
    { image: OphosAbilityImage },
    { image: OphosMissionImage },
    { image: OphosRaceImage },
  ]

  const icons = [
    { icon: OphosSolutionsIcon },
    { icon: OphosHostingIcon },
    { icon: OphosSupportIcon },
    { icon: OphosHelpdeskIcon },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '190px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorNero,
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="teamITOperations" />
      <Intro
        background={OphosIntroImage}
        image={OphosImage}
        title={data.teamPageITOperationsContent.title}
        page={props.location.pathname}
      >
        <BottomRectangle />
      </Intro>
      <About
        avatar={OphosAvatarImage}
        title={data.teamPageITOperationsContent.name}
        description={data.teamPageITOperationsContent.about}
        icons={getMergedContent(data.teamPageITOperationsContent.icons, icons)}
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageITOperationsContent.sections, assets)[0]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageITOperationsContent.sections, assets)[1]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageITOperationsContent.sections, assets)[2]
        }
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.teamPageITOperationsContent.footer.title}
        description={data.teamPageITOperationsContent.footer.description}
        contacts={data.teamPageITOperationsContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
