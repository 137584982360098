import React from 'react'
import styled from 'styled-components'
import { MouseDownIcon } from '../assets'
import { withFilter } from '../utils'

export const ScrollDown = props => {
  const page = props.location.pathname
  if (page === '/404' || page === '/summerdays/Riviera' || page === '/summerdays/riviera') return null

  return (
    <MouseDownButton>
      <MouseLink page={page}>
        <h5>Scroll</h5>
        <h5>Down</h5>
      </MouseLink>
      <MouseDown page={page} />
    </MouseDownButton>
  )
}

const MouseDownButton = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  position: 'fixed',
  bottom: 0,
  zIndex: '2',
  paddingBottom: '1rem',
  '@media (max-width: 600px)': {
    transform: 'scale(0.7)',
    bottom: '-15px',
  },
}))

const MouseLink = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'absolute',
  bottom: '10px',
  h5: {
    padding: '.6rem',
    color:
      props.page === '/work' ||
      props.page === '/work/data-warehouse' ||
      props.page === '/team/software-development'
        ? props.theme.colorMineShift
        : props.theme.colorWhite,
    margin: 0,
  },
}))

const MouseDown = styled(withFilter(MouseDownIcon, ['page']))(props => ({
  marginRight: '.2rem',
  g: {
    fill:
      props.page === '/work' ||
      props.page === '/work/data-warehouse' ||
      props.page === '/team/software-development'
        ? '#373D40'
        : '#FFFFFF',
  },
}))
