import React from 'react'
import styled from 'styled-components'
import {
  OctorionPageImage,
  OctorionWorkIntro,
  OctorionLaptopImage,
  OctorionCodeImage,
  OctorionPCImage,
  OctorionAddLaptopImage,
  GoLangIcon,
  JavaIcon,
  JavascriptIcon,
  PhpIcon,
  RubyIcon,
  AngularJSIcon,
  ApacheIcon,
  ApiIcon,
  GrailsIcon,
  GroovyIcon,
  HibernateIcon,
  MariaDBIcon,
  MySQLIcon,
  NuxtIcon,
  OracleIcon,
  PostgreIcon,
  RailsIcon,
  SpringIcon,
  TomcatIcon,
  VuejsIcon,
  XRoadIcon,
  CoopIcon,
  EleringIcon,
  ERHeritageIcon,
  ErikIcon,
  ERInformationIcon,
  ERTaxIcon,
  InbankIcon,
  KemitIcon,
  LuminorIcon,
  OmnivaIcon,
  SmitIcon,
  GraanulIcon,
  MooncascadeIcon,
  NortalIcon,
} from '../../assets'
import { Intro, Section, ContactMenu } from '../../layout/WorkDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const WorkDevelopment = props => {
  const { data } = useAppContext()

  const assets = [
    {
      image: OctorionLaptopImage,
      firstIconsLine: [
        { icon: GoLangIcon },
        { icon: RubyIcon },
        { icon: JavascriptIcon },
        { icon: JavaIcon },
        { icon: PhpIcon },
      ],
    },
    {
      image: OctorionCodeImage,
      firstIconsLine: [
        { icon: JavaIcon },
        { icon: PhpIcon },
        { icon: AngularJSIcon },
        { icon: TomcatIcon },
        { icon: PostgreIcon },
        { icon: JavascriptIcon },
        { icon: RubyIcon },
        { icon: MySQLIcon },
        { icon: ApacheIcon },
        { icon: VuejsIcon },
        { icon: GoLangIcon },
        { icon: SpringIcon },
        { icon: NuxtIcon },
        { icon: RailsIcon },
        { icon: MariaDBIcon },
        { icon: ApiIcon },
        { icon: GrailsIcon },
        { icon: GroovyIcon },
        { icon: HibernateIcon },
        { icon: OracleIcon },
        { icon: XRoadIcon },
      ],
      secondIconsLine: [
        { icon: CoopIcon },
        { icon: LuminorIcon },
        { icon: InbankIcon },
        { icon: SmitIcon },
        { icon: EleringIcon },
        { icon: OmnivaIcon },
        { icon: KemitIcon },
        { icon: ERTaxIcon },
        { icon: ERHeritageIcon },
        { icon: ERInformationIcon },
        { icon: ErikIcon },
      ],
    },
    {
      image: OctorionPCImage,
      firstIconsLine: [
        { icon: JavaIcon },
        { icon: PhpIcon },
        { icon: AngularJSIcon },
        { icon: TomcatIcon },
        { icon: PostgreIcon },
        { icon: JavascriptIcon },
        { icon: RubyIcon },
        { icon: MySQLIcon },
        { icon: ApacheIcon },
        { icon: VuejsIcon },
        { icon: GoLangIcon },
        { icon: SpringIcon },
        { icon: NuxtIcon },
        { icon: RailsIcon },
        { icon: MariaDBIcon },
        { icon: ApiIcon },
        { icon: GrailsIcon },
        { icon: GroovyIcon },
        { icon: HibernateIcon },
        { icon: OracleIcon },
        { icon: XRoadIcon },
      ],
      secondIconsLine: [
        { icon: CoopIcon },
        { icon: InbankIcon },
        { icon: SmitIcon },
        { icon: ErikIcon },
        { icon: ERHeritageIcon },
        { icon: KemitIcon },
        { icon: EleringIcon },
      ],
    },
    {
      image: OctorionAddLaptopImage,
      firstIconsLine: [
        { icon: InbankIcon },
        { icon: CoopIcon },
        { icon: SmitIcon },
        { icon: LuminorIcon },
        { icon: GraanulIcon },
        { icon: MooncascadeIcon },
        { icon: NortalIcon },
      ],
    },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '136px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorMineShaft,
    boxShadow: '0 -3px 18px 0 rgba(0,0,0,0.31)',
    zIndex: 2,
  }))

  const BottomWaves = styled.span(props => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: '100px',
    backgroundImage: `url(${OctorionWorkIntro})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
    zIndex: 1,
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="workDevelopment" />
      <Intro
        image={OctorionPageImage}
        page={props.location.pathname}
        title={data.workPageDevelopmentContent.title}
        titleMobile={data.workPageDevelopmentContent.titleMobile}
        points={data.workPageDevelopmentContent.points}
      >
        <BottomRectangle />
        <BottomWaves />
      </Intro>
      <Section
        content={
          getMergedContent(data.workPageDevelopmentContent.sections, assets)[0]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageDevelopmentContent.sections, assets)[1]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageDevelopmentContent.sections, assets)[2]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageDevelopmentContent.sections, assets)[3]
        }
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.workPageDevelopmentContent.footer.title}
        description={data.workPageDevelopmentContent.footer.description}
        contacts={data.workPageDevelopmentContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
