import React from 'react'
import {
  FuriaIntroImage,
  FuriaImage,
  FuriaAvatarImage,
  FuriaAbilityImage,
  FuriaRaceImage,
  FuriaMissionImage,
  FuriaDesignIcon,
  FuriaFantasyIcon,
  FuriaIDIcon,
  FuriaUXIcon,
  FuriaWebIcon,
} from '../../assets'
import { Intro, About, Section, ContactMenu } from '../../layout/TeamDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const TeamDesign = props => {
  const { data } = useAppContext()

  const assets = [
    { image: FuriaAbilityImage },
    { image: FuriaMissionImage },
    { image: FuriaRaceImage },
  ]

  const icons = [
    { icon: FuriaWebIcon },
    { icon: FuriaIDIcon },
    { icon: FuriaUXIcon },
    { icon: FuriaDesignIcon },
    { icon: FuriaFantasyIcon },
  ]

  return (
    <ReactSwiperIdSlider>
      <SEO page="teamDesign" />
      <Intro
        background={FuriaIntroImage}
        image={FuriaImage}
        title={data.teamPageDesignContent.title}
        page={props.location.pathname}
      />
      <About
        avatar={FuriaAvatarImage}
        title={data.teamPageDesignContent.name}
        description={data.teamPageDesignContent.about}
        icons={getMergedContent(data.teamPageDesignContent.icons, icons)}
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageDesignContent.sections, assets)[0]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageDesignContent.sections, assets)[1]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.teamPageDesignContent.sections, assets)[2]
        }
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.teamPageDesignContent.footer.title}
        description={data.teamPageDesignContent.footer.description}
        contacts={data.teamPageDesignContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
