import { omit, zipWith } from 'ramda'
import React from 'react'

export const getContainerBgColor = (page, theme) => {
  switch (page) {
    case '/team/design':
      return theme.colorWhite
    case '/team/banking':
      return theme.colorGhostWhite
    case '/team/it-operations':
      return theme.colorMineShaft
    case '/team/data-warehouse':
      return theme.colorMineShaft
    case '/team/erp':
      return theme.colorMineShaft
    case '/team/software-development':
      return theme.colorGhostWhite
    case '/work/banking':
      return theme.colorGhostWhite
    case '/work/software-development':
      return theme.colorSolitude
    case '/work/it-operations':
      return theme.colorMineShaft
    case '/work/data-warehouse':
      return theme.colorSolitude
    case '/work/erp':
      return theme.colorSolitude
    default:
      return theme.colorWhite
  }
}

export const getAboutBgColor = (page, theme) => {
  switch (page) {
    case '/team/design':
      return theme.colorGhostWhite
    case '/team/banking':
      return theme.colorGhostWhite
    case '/team/it-operations':
      return theme.colorMineShaft
    case '/team/data-warehouse':
      return theme.colorMineShaft
    case '/team/erp':
      return theme.colorFireEngineRed
    case '/team/software-development':
      return theme.colorGhostWhite
    default:
      return theme.colorWhite
  }
}

export const getCardBgColor = (page, theme) => {
  switch (page) {
    case '/team/design':
      return theme.colorSolitude
    case '/team/banking':
      return theme.colorSolitude
    case '/team/it-operations':
      return theme.colorMineShaft
    case '/team/data-warehouse':
      return theme.colorMineShaft
    case '/team/erp':
      return theme.colorFireEngineRed
    case '/team/software-development':
      return theme.colorSolitude
    case '/work/software-development':
      return theme.colorSolitude
    case '/work/design':
      return theme.colorSolitude
    case '/work/it-operations':
      return theme.colorSolitude
    case '/work/banking':
      return theme.colorSolitude
    case '/work/data-warehouse':
      return theme.colorSolitude
    case '/work/erp':
      return theme.colorSolitude
    default:
      return theme.colorWhite
  }
}

export const getImageBgColor = (page, theme) => {
  switch (page) {
    case '/work/software-development':
      return theme.colorMineShaft
    case '/work/design':
      return theme.colorSolitude
    case '/work/it-operations':
      return theme.colorSolitude
    case '/work/banking':
      return theme.colorSolitude
    case '/work/data-warehouse':
      return theme.colorSolitude
    case '/work/erp':
      return theme.colorSolitude
    default:
      return theme.colorWhite
  }
}

export const getTextColor = (page, theme) => {
  switch (page) {
    case '/team/design':
      return theme.colorBlackPearl
    case '/team/banking':
      return theme.colorBlackPearl
    case '/team/it-operations':
      return theme.colorWhite
    case '/team/data-warehouse':
      return theme.colorWhite
    case '/team/erp':
      return theme.colorWhite
    case '/team/software-development':
      return theme.colorBlackPearl
    case '/work/software-development':
      return theme.colorMineShaft
    case '/work/design':
      return theme.colorMineShaft
    case '/work/it-operations':
      return theme.colorMineShaft
    case '/work/banking':
      return theme.colorMineShaft
    case '/work/data-warehouse':
      return theme.colorMineShaft
    case '/work/erp':
      return theme.colorMineShaft
    default:
      return theme.colorWhite
  }
}

export const getHeadingColor = (page, theme) => {
  switch (page) {
    case '/work/software-development':
      return theme.colorMineShaft
    case '/work/design':
      return theme.colorMineShaft
    case '/work/banking':
      return theme.colorMineShaft
    case '/work/it-operations':
      return theme.colorWhite
    case '/work/data-warehouse':
      return theme.colorMineShaft
    case '/work/erp':
      return theme.colorMineShaft
    default:
      return theme.colorWhite
  }
}

export const getMergedContent = zipWith((content, assets) => ({
  ...content,
  ...assets,
}))

export const withFilter = (Tag, filter = []) => {
  return props => <Tag {...omit(filter, props)} />
}

export const getProjectsContent = (content, path, idx) => {
  switch (path) {
    case '/work/banking':
      return content.workPageBankingContent.projects
    case '/work/software-development':
      return content.workPageDevelopmentContent.projects
    case '/work/design':
      return content.workPageDesignContent.projects
    case '/work/erp':
      return content.workPageERPContent.projects
    default:
      return null
  }
}
