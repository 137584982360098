export const seo = {
  home: {
    title: 'Avalanche Laboratory',
    description: 'Do you need a superhero by your side?',
  },
  error: {
    title: '404',
    description: '404',
  },
  team: {
    title: 'Avalanche Team',
    description:
      'Our team is divided into 6 divisions that specialize in different areas in IT',
  },
  teamBanking: {
    title: 'Avalanche Banking Team',
    description: 'Comprehensive partnership for financial institutions',
  },
  teamDesign: {
    title: 'Avalanche Design Team',
    description: 'Creativity is a way to solve problems',
  },
  teamDevelopment: {
    title: 'Avalanche Software Development Team',
    description: 'Creating solutions is what we are best at',
  },
  teamDWH: {
    title: 'Avalanche Data Warehouse Team',
    description: 'Everything is possible with solid data',
  },
  teamERP: {
    title: 'Avalanche ERP Team',
    description: 'Transformation is a require for success',
  },
  teamITOperations: {
    title: 'Avalanche IT Operations Team',
    description: 'IT operations is something we take seriously',
  },
  work: {
    title: 'Avalanche Work',
    description:
      'Does not matter where you are situated at - we can reach you no matter what and provide the service you need',
  },
  workBanking: {
    title: 'Avalanche Banking division',
    description: 'Сustomers value our Banking competence',
  },
  workDesign: {
    title: 'Avalanche Design division',
    description: 'Customers value our Design competence',
  },
  workDevelopment: {
    title: 'Avalanche Software Development division',
    description: 'Customers value our Software development competence',
  },
  workDWH: {
    title: 'Avalanche Data Warehouse division',
    description: 'Customers value our Data warehouse competence',
  },
  workERP: {
    title: 'Avalanche ERP division',
    description: 'Customers value our digital transformation & ERP competence',
  },
  workITOperations: {
    title: 'Avalanche IT Operations division',
    description: 'Customers value our IT operations competence',
  },
}
