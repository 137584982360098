import React from 'react'
import styled from 'styled-components'
import {
  LocationIcon,
  TinyArrowIcon,
  MapImage,
  FacebookIcon,
  LinkedinIcon,
  InstagramIcon,
} from '../../assets'
import { Slide } from '../../components'

const Contacts = props => {
  const { title, description, contacts } = props

  const socials = [
    {
      title: 'facebook',
      path: 'https://www.facebook.com/avalanchelabs/',
    },
    {
      title: 'linkedin',
      path: 'https://www.linkedin.com/company/avalanche-laboratory/',
    },
    {
      title: 'instagram',
      path: 'https://www.instagram.com/avalanche.labs/',
    },
  ]

  return (
    <Slide>
      <Container>
        <RedBorder>
          <LocationIconSVG />
          <CardWrapper>
            <Card>
              <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
              <h3 dangerouslySetInnerHTML={{ __html: description }}></h3>
              {contacts.map(i => (
                <CardContent key={i.title}>
                  <h3>{i.title}</h3>
                  <a href={i.path} target="blank">
                    <ArrowButton>
                      <ArrowButtonTitle>{i.button}</ArrowButtonTitle>
                      <TinyArrow />
                    </ArrowButton>
                  </a>
                </CardContent>
              ))}
              <Socials>
                {socials.map(item => (
                  <a
                    href={item.path}
                    key={item.title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIconWrapper
                      display-if={item.title === 'facebook'}
                    />
                    <LinkedinIconWrapper
                      display-if={item.title === 'linkedin'}
                    />
                    <InstagramIconWrapper
                      display-if={item.title === 'instagram'}
                    />
                  </a>
                ))}
              </Socials>
            </Card>
          </CardWrapper>
        </RedBorder>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${MapImage})`,
  backgroundSize: 'cover',
}))

const RedBorder = styled.div(props => ({
  position: 'relative',
  maxWidth: '700px',
  width: '100%',
  border: `4px solid #BB2025`,
  margin: '1rem',
}))

const LocationIconSVG = styled(LocationIcon)(props => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: '-80px',
  '@media (max-width: 1280px)': {
    width: '13%',
    height: '13%',
    top: '-40px',
  },
}))

const CardWrapper = styled.div(props => ({
  margin: '.7rem',
  padding: '1rem',
  background: 'white',
  boxShadow: '0 2px 16px 0 rgba(0,0,0,0.24)',
}))

const Card = styled.div(props => ({
  margin: '4rem 3rem 2rem',
  textAlign: 'center',
  h3: {
    margin: '1.5rem 0',
  },
  'h2, h3': {
    span: {
      color: props.theme.colorFireEngineRed,
    },
  },
  '@media (max-width: 600px)': {
    margin: '1.5rem 1rem 1rem',
    h2: {
      fontSize: '2rem',
      lineHeight: 1,
    },
    h3: {
      fontSize: '1.1rem',
    },
  },
}))

const CardContent = styled.div(props => ({
  marginBottom: '1.5rem',
  h3: {
    marginBottom: '0.5rem',
  },
  a: {
    textDecoration: 'none',
  },
}))

const ArrowButton = styled.div(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '0.25rem',
  cursor: 'pointer',
}))

const ArrowButtonTitle = styled.p(props => ({
  color: props.theme.colorFireEngineRed,
  margin: 0,
}))

const TinyArrow = styled(TinyArrowIcon)(props => ({
  marginTop: '0.3rem',
  marginLeft: '.6rem',
  '@media (max-width: 600px)': {
    height: '10px',
    marginTop: '.35rem',
    marginLeft: '.1rem',
  },
}))

const Socials = styled.div(props => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  paddingTop: '.5rem',
  a: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
  },
}))

const FacebookIconWrapper = styled(FacebookIcon)(props => ({
  height: '20px',
  path: {
    stroke: 'black',
  },
  ':hover path': {
    stroke: '#BB2025',
    transition: 'all 0.3s ease',
  },
}))

const LinkedinIconWrapper = styled(LinkedinIcon)(props => ({
  height: '20px',
  path: {
    stroke: 'black',
  },
  ':hover path': {
    stroke: '#BB2025',
    transition: 'all 0.3s ease',
  },
}))

const InstagramIconWrapper = styled(InstagramIcon)(props => ({
  height: '20px',
  g: {
    stroke: 'black',
  },
  ':hover g': {
    stroke: '#BB2025',
    transition: 'all 0.3s ease',
  },
}))

export default Contacts
