import React, { useRef, useState, useEffect } from 'react'
import 'react-id-swiper/lib/styles/css/swiper.css'
import Swiper from 'react-id-swiper'
import '../main.scss'
import WheelIndicator from 'wheel-indicator'
import Bowser from 'bowser'
import { useAppContext } from '../context'

export const ReactSwiperIdSlider = ({ children }) => {
  const { actions } = useAppContext()
  const { setActiveIdx, setSlidesLength } = actions
  const sliderWrapperRef = useRef(null)
  const [swiper, updateSwiper] = useState(null)
  const parser = Bowser.getParser(window.navigator.userAgent)
  const isDesktop = parser.is('desktop')

  const params = {
    direction: 'vertical',
    slidesPerView: 1,
    autoHeight: true,
    allowTouchMove: !isDesktop,
    draggable: !isDesktop,
  }

  useEffect(() => {
    const indicator = new WheelIndicator({
      elem: sliderWrapperRef.current,
      callback: e => {
        e.direction === 'down' && swiper !== null
          ? swiper.slideNext()
          : swiper.slidePrev()
      },
    })

    let swiperInstance = swiper
    if (swiper) {
      setSlidesLength(swiper.slides.length)
      setActiveIdx(0)
    }
    swiper &&
      swiper.on('slideChange', () => {
        setActiveIdx(swiperInstance.realIndex)
      })
    return () => indicator.destroy()
  }, [swiper, setActiveIdx, setSlidesLength])

  return (
    <div ref={sliderWrapperRef} className="slider-wrapper">
      <Swiper getSwiper={updateSwiper} {...params}>
        {React.Children.toArray(children)}
      </Swiper>
    </div>
  )
}
