import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'
import { animated, useSpring } from 'react-spring'
import {
  ArrowDropDownIcon,
  FacebookIcon,
  FlagEEImage,
  FlagLTImage,
  FlagLVImage,
  InstagramIcon,
  LinkedinIcon,
  TinyArrowIcon
} from '../assets'

export const ContactForm = props => {
  const { contacts, title, description } = props

  const socials = [
    {
      title: 'facebook',
      path: 'https://www.facebook.com/avalanchelabs/'
    },
    {
      title: 'linkedin',
      path: 'https://www.linkedin.com/company/avalanche-laboratory/'
    },
    {
      title: 'instagram',
      path: 'https://www.instagram.com/avalanche.labs/'
    }
  ]

  return (
    <Form>
      <h1>{title}</h1>
      <h3>{description}</h3>
      <Dropdown contacts={contacts}/>
      <Socials>
        {socials.map(item => (
          <a
            href={item.path}
            key={item.title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIconWrapper display-if={item.title === 'facebook'}/>
            <LinkedinIconWrapper display-if={item.title === 'linkedin'}/>
            <InstagramIconWrapper display-if={item.title === 'instagram'}/>
          </a>
        ))}
      </Socials>
    </Form>
  )
}

const Socials = styled.div(props => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  paddingTop: '1rem',
  a: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem'
  },
  '@media (max-width: 600px)': {
    justifyContent: 'center'
  }
}))

const FacebookIconWrapper = styled(FacebookIcon)(props => ({
  height: '20px',
  path: {
    stroke: 'white'
  },
  ':hover path': {
    fill: '#BB2025',
    transition: 'all 0.3s ease'
  }
}))

const LinkedinIconWrapper = styled(LinkedinIcon)(props => ({
  height: '20px',
  path: {
    stroke: 'white'
  },
  ':hover path': {
    fill: '#BB2025',
    transition: 'all 0.3s ease'
  }
}))

const InstagramIconWrapper = styled(InstagramIcon)(props => ({
  height: '20px',
  g: {
    stroke: 'white'
  },
  ':hover g': {
    fill: '#BB2025',
    transition: 'all 0.3s ease'
  }
}))

const Dropdown = props => {
  const dropdownRef = useRef(null)
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [activeIdx, setActiveIdx] = useState(0)
  const { contacts } = props
  const style = useSpring({
    opacity: dropDownOpen ? 1 : 0
  })

  useOnClickOutside(dropdownRef, () => {
    dropDownOpen && setDropDownOpen(!dropDownOpen)
  })

  const menuItems = ['Estonian headquarters', 'Lithuanian headquarters', 'Latvian headquarters']

  return (
    <DropDownWrapper>
      <Select ref={dropdownRef} onClick={() => setDropDownOpen(!dropDownOpen)}>
        <Label>{menuItems[activeIdx]}</Label>
        <FlagEE display-if={activeIdx === 0}/>
        <FlagLT display-if={activeIdx === 1}/>
        <FlagLV display-if={activeIdx === 2}/>
        <ArrowDropDown open={dropDownOpen}/>
      </Select>
      <List
        style={{
          ...style,
          visibility: style.opacity.interpolate(opacity =>
            opacity === 0 ? 'hidden' : 'visible'
          )
        }}
      >
        {menuItems.map((listItem, idx) => (
          <ListItem key={listItem} onClick={() => setActiveIdx(idx)}>
            {listItem}
          </ListItem>
        ))}
      </List>
      {contacts[activeIdx].map(i => (
        <Card key={i.title}>
          <h3>{i.title}</h3>
          <a href={i.path} target="blank">
            <ArrowButton>
              <ArrowButtonTitle>{i.button}</ArrowButtonTitle>
              <TinyArrow/>
            </ArrowButton>
          </a>
        </Card>
      ))}
    </DropDownWrapper>
  )
}

const Form = styled.div(props => ({
  textAlign: 'left',
  maxWidth: '90%',
  '@media (min-width: 960px)': {
    maxWidth: '80%'
  },
  '> h1, h3': {
    color: props.theme.colorWhite
  },
  '> h1': {
    marginBottom: '1rem',
    '@media (max-width: 1280px)': {
      maxWidth: '500px',
      lineHeight: 1.1,
      fontSize: '2.5rem'
    },
    '@media (max-width: 600px)': {
      lineHeight: 1,
      fontSize: '1.8rem',
      padding: '0 1.5rem'
    }
  },
  '> h3': {
    maxWidth: '230px',
    lineHeight: '1.4',
    marginBottom: '2.5rem',
    '@media (max-width: 1280px)': {
      lineHeight: 1.2,
      fontSize: '1.25rem'
    },
    '@media (max-width: 600px)': {
      fontSize: '1rem',
      padding: '0 1.5rem'
    }
  }
}))

const DropDownWrapper = styled.div(props => ({
  position: 'relative',
  '@media (max-width: 600px)': {
    padding: '0 1.5rem'
  }
}))

const Select = styled.div(props => ({
  maxWidth: '395px',
  width: '100%',
  height: '30px',
  position: 'relative',
  borderBottom: '1px solid white',
  margin: '2rem 0',
  cursor: 'pointer'
}))

const List = styled(animated.div)(props => ({
  maxWidth: '395px',
  width: '85%',
  position: 'absolute',
  top: '35px',
  paddingTop: '.5rem',
  backgroundColor: props.theme.colorBlackPearl
}))

const ListItem = styled.div(props => ({
  color: props.theme.colorWhite,
  lineHeight: '1.4',
  paddingLeft: '.8rem',
  paddingBottom: '.5rem',
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    fontSize: '.8rem',
    lineHeight: '1.2',
    paddingLeft: '.5rem'
  }
}))

const Label = styled.p(props => ({
  position: 'relative',
  color: props.theme.colorWhite,
  paddingLeft: '2rem',
  margin: 0
}))

const FlagEE = styled(FlagEEImage)(props => ({
  position: 'absolute',
  left: '5px',
  top: '6px',
  '@media (max-width: 600px)': {
    top: '3.5px'
  }
}))

const FlagLT = styled(FlagLTImage)(props => ({
  position: 'absolute',
  left: '5px',
  top: '6px',
  '@media (max-width: 600px)': {
    top: '3.5px'
  }
}))

const FlagLV = styled(FlagLVImage)(props => ({
  position: 'absolute',
  left: '5px',
  top: '6px',
  '@media (max-width: 600px)': {
    top: '3.5px'
  }
}))

const ArrowDropDown = styled(ArrowDropDownIcon)(props => ({
  position: 'absolute',
  right: '5px',
  bottom: '10px',
  transform: props.open && 'rotate(180deg)',
  '@media (max-width: 600px)': {
    width: '10px',
    bottom: '15px'
  }
}))

const Card = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginBottom: '1.5rem',
  '> h3': {
    marginBottom: '0.5rem',
    color: props.theme.colorWhite,
    '@media (max-width: 1280px)': {
      lineHeight: 1.1,
      fontSize: '1.25rem'
    },
    '@media (max-width: 600px)': {
      wordBreak: 'break-all',
      fontSize: '1rem'
    }
  },
  a: {
    textDecoration: 'none'
  }
}))

const ArrowButton = styled.div(props => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'left',
  marginTop: '0.25rem',
  cursor: 'pointer'
}))

const ArrowButtonTitle = styled.p(props => ({
  color: props.theme.colorFireEngineRed,
  margin: 0
}))

const TinyArrow = styled(TinyArrowIcon)(props => ({
  marginTop: '.3rem',
  marginLeft: '.6rem',
  '@media (max-width: 600px)': {
    height: '10px',
    marginTop: '.35rem',
    marginLeft: '.1rem'
  }
}))
