import React from 'react'
import styled from 'styled-components'
import { CashIcon, TimeIcon } from '../assets'

const SavingsPanel = props => {
  const { saving } = props

  return (
    <Container>
      <Panel isDigitalisation>
        <Header>{'Digitalisation'}</Header>
        <p>{saving.digitalisation.method}</p>
        <Flex>
          <TextWithLogo>
            <img src={TimeIcon} alt=''/>
            <p>{saving.digitalisation.timeSaved}</p>
          </TextWithLogo>
          <TextWithLogo>
            <img src={CashIcon} alt=''/>
            <p>{saving.digitalisation.moneySaved}</p>
          </TextWithLogo>
        </Flex>
      </Panel>

      <Panel>
        <Header>{'Automation'}</Header>
        <p>{saving.automation.method}</p>
        <Flex>
          <TextWithLogo>
            <img src={TimeIcon} alt=''/>
            <p>{saving.automation.timeSaved}</p>
          </TextWithLogo>
          <TextWithLogo>
            <img src={CashIcon} alt=''/>
            <p>{saving.automation.moneySaved}</p>
          </TextWithLogo>
        </Flex>
      </Panel>
    </Container>
  )
}

const Container = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2.5rem',
  '@media(min-width: 960px)': {
    flexDirection: 'row'
  }
}))


const Panel = styled.div(props => ({
  backgroundColor: props.isDigitalisation && props.theme.colorLavender,
  border: !props.isDigitalisation && '1px solid ' + props.theme.colorLavender,
  flexGrow: 1,
  flexBasis: 0,
  padding: '1rem'
}))

const Header = styled.h1(props => ({
  color: props.theme.colorFireEngineRed,
  fontSize: '1.5rem'
}))

const TextWithLogo = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
  flexBasis: 0,
  padding: '0 10%',
  textAlign: 'center',
  '& p': {
    whiteSpace: 'pre-line'
  }
}))

const Flex = styled.div(props => ({
  display: 'flex'
}))

export default SavingsPanel
