import React from 'react'
import styled from 'styled-components'
import {
  OphosHardwareImage,
  OphosImage,
  OphosMonitoringImage,
  OphosServerImage,
  OphosSoftwareImage,
  OphosTechImage,
  OphosWorkLeftBg,
  OphosWorkRightBg,
} from '../../assets'
import { ContactMenu, Intro, Section } from '../../layout/WorkDivisions'
import { ReactSwiperIdSlider, SEO } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const WorkITOperations = props => {
  const { data } = useAppContext()

  const assets = [
    {
      image: OphosTechImage,
    },
    {
      image: OphosHardwareImage,
    },
    {
      image: OphosServerImage,
    },
    {
      image: OphosMonitoringImage,
    },
    {
      image: OphosSoftwareImage,
    },
  ]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '136px',
    position: 'absolute',
    bottom: 0,
    backgroundColor: props.theme.colorBlackPearl,
    boxShadow: '0 -3px 18px 0 rgba(0,0,0,0.31)',
    zIndex: 2,
  }))

  const LeftBgElements = styled(OphosWorkLeftBg)(props => ({
    position: 'absolute',
    left: '-30px',
    top: '-8px',
    zIndex: 1,
    '@media (max-width: 600px)': {
      width: '50%',
    },
  }))

  const RightBgElements = styled(OphosWorkRightBg)(props => ({
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 1,
    '@media (max-width: 600px)': {
      width: '50%',
    },
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="workITOperations" />
      <Intro
        image={OphosImage}
        page={props.location.pathname}
        title={data.workPageITOperationsContent.title}
        titleMobile={data.workPageITOperationsContent.titleMobile}
        points={data.workPageITOperationsContent.points}
      >
        <BottomRectangle />
        <LeftBgElements />
        <RightBgElements />
      </Intro>
      <Section
        content={
          getMergedContent(data.workPageITOperationsContent.sections, assets)[0]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageITOperationsContent.sections, assets)[1]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageITOperationsContent.sections, assets)[2]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageITOperationsContent.sections, assets)[3]
        }
        page={props.location.pathname}
      />
      <Section
        content={
          getMergedContent(data.workPageITOperationsContent.sections, assets)[4]
        }
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.workPageITOperationsContent.footer.title}
        description={data.workPageITOperationsContent.footer.description}
        contacts={data.workPageITOperationsContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
