import React from 'react'
import styled from 'styled-components'
import {
  I4CIntroImage,
  I4CLampsImage,
  I4CAvatarImage,
  I4CBottomImage,
  I4CAbilityImage,
  I4CRaceImage,
  I4CMissionImage,
  I4CBusinessIcon,
  I4CProductIcon,
} from '../../assets'
import { Intro, About, Section, ContactMenu } from '../../layout/TeamDivisions'
import { SEO, ReactSwiperIdSlider } from '../../components'
import { getMergedContent } from '../../utils'
import { useAppContext } from '../../context'

export const TeamDWH = props => {
  const { data } = useAppContext()

  const assets = [
    { image: I4CAbilityImage },
    { image: I4CMissionImage },
    { image: I4CRaceImage },
  ]

  const icons = [{ icon: I4CBusinessIcon }, { icon: I4CProductIcon }]

  const BottomRectangle = styled.span(props => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    bottom: '-10px',
    backgroundImage: `url(${I4CBottomImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    backgroundSize: 'contain',
    '@media (max-width: 960px)': {
      backgroundSize: 'initial',
    },
  }))

  const I4CLamps = styled(I4CLampsImage)(props => ({
    width: '100%',
    position: 'absolute',
    top: '10px',
    right: '150px',
  }))

  return (
    <ReactSwiperIdSlider>
      <SEO page="teamDWH" />
      <Intro
        image={I4CIntroImage}
        title={data.teamPageDWHContent.title}
        page={props.location.pathname}
      >
        <BottomRectangle />
        <I4CLamps />
      </Intro>
      <About
        avatar={I4CAvatarImage}
        title={data.teamPageDWHContent.name}
        description={data.teamPageDWHContent.about}
        icons={getMergedContent(data.teamPageDWHContent.icons, icons)}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.teamPageDWHContent.sections, assets)[0]}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.teamPageDWHContent.sections, assets)[1]}
        page={props.location.pathname}
      />
      <Section
        content={getMergedContent(data.teamPageDWHContent.sections, assets)[2]}
        page={props.location.pathname}
      />
      <ContactMenu
        title={data.teamPageDWHContent.footer.title}
        description={data.teamPageDWHContent.footer.description}
        contacts={data.teamPageDWHContent.footer.contacts}
      />
    </ReactSwiperIdSlider>
  )
}
