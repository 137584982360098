import React from 'react'
import styled from 'styled-components'
import { TriangleImage } from '../../assets'
import { TeamSlider, Slide } from '../../components'
import InfoText from '../../components/InfoText'

const Members = props => {
  const { title, description, cards, page } = props

  return (
    <Slide>
      <Container>
        <HeadingWrapper>
          <InfoText
              textColor={'white'}
              title={title}
              firstDescription={description}
          />
        </HeadingWrapper>
        <TeamSliderWrapper>
          <TeamSlider slides={cards} page={page} />
        </TeamSliderWrapper>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  height: '100%',
  position: 'relative',
  backgroundColor: props.theme.colorMineShaft,
  backgroundImage: `url(${TriangleImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center bottom',
  backgroundSize: 'contain',
  zIndex: 0,
  overflow: 'hidden',
  '@media (max-width: 600px)': {
    paddingTop: '2rem',
  },
}))

const HeadingWrapper = styled.div(props => ({
  width: '100%',
  margin: '0 auto',
}))

const TeamSliderWrapper = styled.div(props => ({
  width: '1190px',
  margin: '0 auto',
  marginTop: '3rem',
  zIndex: '1',
  a: {
    textDecoration: 'none',
  },
  '@media (max-width: 1280px)': {
    width: '100%',
  },
}))

export default Members
