import React from 'react'
import styled from 'styled-components'
import { ContactForm, Slide } from '../../components'
import { WorkPanel } from '../../components/WorkPanel'

const ContactMenu = props => {
  const { contacts, title, description } = props

  return (
    <Slide>
      <Container>
        <LeftSideWrapper>
          <ContactForm
            title={title}
            description={description}
            contacts={contacts}
          />
        </LeftSideWrapper>
        <WorkPanel/>
      </Container>
    </Slide>
  )
}

const Container = styled.div(props => ({
  display: 'flex',
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
  '@media (max-width: 960px)': {
    flexDirection: 'column',
  },
}))

const LeftSideWrapper = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  flexBasis: 0,
  backgroundColor: props.theme.colorBlackPearl,
  padding: '1rem',
  '@media (max-width: 960px)': {
    flexBasis: 'auto'
  }
}))

export default ContactMenu
